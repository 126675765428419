teleskop.
  factory('Order', ['$http', function ($http) {
    return {
      dashboard: function (data) {
        return $http.post('api/dashboard', data, {ignoreLoadingBar: true});
      },
      calendar: function (data) {
        return $http.post('api/calendar', data);
      },
      dashboardOffice: function (data) {
        return $http.post('api/dashboard-office', data);
      },
      workers: function (e) {
        return $http.get('api/dashboard-workers', { params: e, ignoreLoadingBar: true });
      },
      order: function (id) {
        return $http.get('api/order/' + id);
      },
      newOrderData: function () {
        return $http.get('api/new-order');
      },
      newOrder: function (e) {
        return $http.post('api/new-order', e);
      },
      orders: function (data) {
        return $http.post('api/orders', data);
      },
      notifications: function (data) {
        return $http.post('api/notifications', data);
      },
      sendNote: function (data) {
        return $http.post('api/send-note', data);
      },
      sendSms: function (data) {
        return $http.post('api/send-sms-customer', data);
      },
      sendCallRecord: function (data) {
        return $http.post('api/send-call-record', data);
      },
      refreshOrders: function () {
        return $http.get('api/refresh-orders');
      },
      updateOrder: function (data) {
        return $http.post('api/update-order', data);
      },
      tour: function (data) {
        return $http.post('api/tour', data);
      },
      getAddressFromCoord: function (url) {
        return $http.jsonp(url);
      },
      assignWorker: function (data) {
        return $http.post('api/assign-worker', data);
      },
      loadRoute: function (id) {
        return $http.get('api/get-route/' + id);
      },
      sendComment: function (data) {
        return $http.post('api/send-comment-order', data);
      },
      changeWorkStatus: function (data) {
        return $http.post('api/change-work-status', data);
      },
      changeArrived: function (data) {
        return $http.post('api/change-work-arrived', data);
      },
      changeWorkStatusOffice: function (data) {
        return $http.post('api/change-work-status', data, { ignoreLoadingBar: true });
      },
      deleteWork: function (data) {
        return $http.post('api/delete-work', data);
      },
      deleteTask: function (data) {
        return $http.post('api/delete-task', data);
      },
      donwloadSampleExcel: function () {
        return $http.get('api/download-sample-order-excel');
      },
      remainingTime: function (id, loading) {
        if (typeof loading === 'undefined') {
          return $http.get('api/get-remaining-time/' + id);
        } else {
          return $http.get('api/get-remaining-time/' + id, {
            ignoreLoadingBar: true
          });
        }
      },
      generateTotalOrdersReportInZip: function (data) {
        return $http.post('api/download-total-orders-reports-in-zip', data);
      },
      removeDownloadFile: function (data) {
        return $http.post('api/remove-download-file', data);
      },
      generateExcelReport: function (data) {
        return $http.post('api/download-orders-reports-in-excel', data);
      },
      generateExcelReportNotification: function (data) {
        return $http.post('api/download-notification-reports-in-excel', data);
      },
      deleteOrders: function (data) {
        return $http.post('api/delete-orders', data);
      },
      getWorkersForOptimizing: function () {
        return $http.get('api/get-available-workers-for-optimization', {
          ignoreLoadingBar: true
        });
      },
      routeOptimization: function (data) {
        return $http.post('api/route-optimization', data, { ignoreLoadingBar: false });
      },
      getLatAndLngOfWorkerOrders: function (data) {
        return $http.post('api/get-lat-and-lng-of-worker-orders', data, { ignoreLoadingBar: true });
      },
      updateChecklist: function (data) {
        return $http.post('api/update-checklist', data);
      },
      updatePaymentData: function (data) {
        return $http.post('api/update-payment', data);
      },
      sendOffer: function (data) {
        return $http.post('api/send-offer', data);
      },
      assignOrderToRoute: function (data) {
        return $http.post('api/assign-order-to-route', data);
      },
      exportProof: function (id) {
        return $http.get('api/export-proof/' + id);
      },
      assignOrdersToWorker: function (data) {
        return $http.post('api/assign-orders-worker', data);
      },
      changeStatus: function (data) {
        return $http.post('api/change-order-status', data);
      },
      changePackageType: function (data) {
        return $http.post('api/change-package-type', data);
      },
      makeArrivedWorker: function (data) {
        return $http.post('api/make-arrived-worker', data);
      },

    };
  }]);