teleskop.controller('CalendarController', function ($scope, moment, calendarConfig, Order) {


	$scope.calendarView = 'month';
	$scope.viewDate = moment();
	$scope.cellIsOpen = false;
	moment.locale('en', {
		week: {
			dow: 1 // Monday is the first day of the week
		}
	});

	$scope.filterData = {
		customers: [],
		workers: [],
		workSteps: [],
		scheduled_time: {
			startDate: moment($scope.viewDate).startOf('month'),
			endDate: moment($scope.viewDate).endOf('month')
		}
	}

	$scope.changeDate = function () {
		$scope.cellIsOpen = false;
		$scope.filterData.scheduled_time = {
			startDate: moment($scope.viewDate).startOf('month'),
			endDate: moment($scope.viewDate).endOf('month')
		};
	};

	$scope.eventClicked = function (event) {
		$scope.getOrderDetails(event.id);
	};

	$scope.eventEdited = function (event) {
		alert('Edited', event);
	};

	$scope.eventTimesChanged = function (event) {
		alert('Dropped or resized', event);
	};

	$scope.toggle = function ($event, field, event) {
		$event.preventDefault();
		$event.stopPropagation();
		event[field] = !event[field];
	};

	$scope.timespanClicked = function (date, cell) {

		if ($scope.calendarView === 'month') {
			if (($scope.cellIsOpen && moment(date).startOf('day').isSame(moment($scope.viewDate).startOf('day'))) || cell.events.length === 0 || !cell.inMonth) {
				$scope.cellIsOpen = false;
			} else {
				$scope.cellIsOpen = true;
				$scope.viewDate = date;
			}
		} else if ($scope.calendarView === 'year') {
			if (($scope.cellIsOpen && moment(date).startOf('month').isSame(moment($scope.viewDate).startOf('month'))) || cell.events.length === 0) {
				$scope.cellIsOpen = false;
			} else {
				$scope.cellIsOpen = true;
				$scope.viewDate = date;
			}
		}

	};

	$scope.clearFilter = function (page) {
		$scope.filterData = {
			customers: [],
			workers: [],
			workSteps: [],
			scheduled_time: {
				startDate: moment($scope.viewDate).startOf('month'),
				endDate: moment($scope.viewDate).endOf('month')
			}
		}
	}

	$scope.$on('refresh_data', function () {
		console.log('refreshing calendar...')
		$scope.cellIsOpen = false;
		load();
	}, true);

	$scope.$watch('filterData', function () {
		$scope.cellIsOpen = false;
		load();
	}, true);

	function load() {
		$scope.events = [];
		Order.calendar($scope.filterData).success(function (data, status, headers, config) {
			$scope.data = data;
			data.orders.forEach(function (element, key) {
				var color = element.status ? element.status.color : '#00cbff';
				var name = element.worker ? element.worker.full_name : '';
				$scope.events.push({
					id: element.id,
					title: element.name + ' - (' + name + ')',
					color: {
						primary: color,
						secondary: color
					},
					startsAt: new Date(element.scheduled_time),
					draggable: true,
					resizable: true
				});
				var d = new Date(element.end_time);
				if (d != "Invalid Date") {
					$scope.events[key]['endsAt'] = new Date(element.end_time);
				}
			}, this);
		});
	}

	$scope.changeCalendarView = function (type) {
		$scope.cellIsOpen = false;
		$scope.calendarView = type
	}

	var actions = [{
		label: '<i class=\'fa fa-pencil\'></i>',
		onClick: function (args) {
			$scope.getOrderDetails(args.id);
		}
	}, {
		label: '<i class=\'fa fa-remove\'></i>',
		onClick: function (args) {
			alert('Deleted', args.calendarEvent);
		}
	}];

});