teleskop.controller('UserController', ['$scope', 'UserManager', '$location', '$filter', '$routeParams',
  function ($scope, UserManager, $location, $filter, $routeParams) {
    $scope.deleted = [];

    $scope.init = function () {
      UserManager.init().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.show = function () {
      UserManager.show($routeParams.id).success(function (data, status, headers, config) {
        $scope.user = data.user;
        $scope.data = {
          'teams': data.teams
        };
      });
    }

    $scope.newUser = function () {
      UserManager.newUser().success(function (data, status, headers, config) {
        // $scope.data = data;
        $scope.user = {
          teams: [],
          authorization: {
            maps: true,
            office: true,
            customers: true,
            users: true,
            reports: true,
            settings: true
          }
        }
      });
    }

    $scope.$on('new_data', function () {
      $scope.newUser();
      $('#userModal').modal('show');
    }, true);

    $scope.postForm = function (user) {
      if ($scope.user.id) {
        $scope.updateUser();
      } else {
        $scope.saveUser();
      }
    }

    $scope.saveUser = function () {
      UserManager.saveUser($scope.user).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $('#userModal').modal('hide');
        $scope.init();
      }).error(function (data) {
        Messenger().post({ message: data.message, type: "error" });
      });
    }

    $scope.updateUser = function () {
      UserManager.updateUser($scope.user).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $('#userModal').modal('hide');
        $scope.init();
      }).error(function (data) {
        Messenger().post({ message: data.message, type: "error" });
      })
    }

    $scope.openDetail = function (item) {
      UserManager.show(item.id).success(function (data, status, headers, config) {
        $('#userModal').modal('show');
        $scope.user = data.user;
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        UserManager.deleteUser($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.users.data.indexOf($filter('filter')($scope.data.users.data, { id: $scope.deleted[i] })[0]);
            if (index > -1)
              $scope.data.users.data.splice(index, 1);
          };
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({ message: data.message, type: "error" });
        })
      }
    }

    $scope.getNumber = function (num) {
      return new Array(num);
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      }
      else {
        $scope.deleted.push(id);
      }
    }

  }
]);
