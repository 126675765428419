teleskop.controller('SalesPaymentController', ['$scope', 'Sales', '$location', '$http',
    function ($scope, Sales, $location, $http) {

        $scope.filterProp = [];

        $scope.filterData = {
            export: false,
            term: '',
            page: 1,
            paginate: 25,
            date: {
                startDate: null,
                endDate: null,
            },
        }

        $scope.selectedItems = [];

        $scope.clearFilter = function (page) {
            $scope.filterData = {
                export: false,
                term: '',
                page: 1,
                paginate: 25,
                date: {
                    startDate: null,
                    endDate: null,
                },
            }
            $scope.filterProp = [];
            $scope.init();
        }

        $scope.$on('refresh_data', function () {
            $scope.init();
        }, true);


        $scope.payments = function () {
            $scope.filterData.paginatePage = true;
            Sales.payments($scope.filterData).success(function (data, status, headers, config) {
                $scope.data = data;
                if (data.payments.data.length === 0) {
                    $scope.isEmpty = true;
                } else {
                    $scope.isEmpty = false;
                }
            });
        };

        $scope.filter = function (type) {
            if (type == 'clear') {
                $scope.filterData = {
                    date: {
                        startDate: null,
                        endDate: null,
                    },
                }
            }
            $scope.init();
        }

        $scope.getPage = function (page) {
            $scope.filterData.page = page;
            $scope.init();
        }


        $scope.sort = function (field, sort) {
            $scope.filterData.field = field;
            $scope.filterData.sort = sort;
            $scope.init();
        }

    }
]);