teleskop.controller('MainControllerMerchantReal',
    ['$scope', '$location', '$localStorage', '$rootScope', 'User', '$routeParams', '$timeout', '$window', '$filter', 'socket', 'Order', 'FileUploader', '$http', 'Worker', 'Customer', 'tourConfig', 'WizardHandler', 'AclService', 'Lightbox', 'Stock', 'StockStore', 'Dayend', 'Account', 'RouteList', 'ngAudio', 'CustomerOrder',
        function ($scope, $location, $localStorage, $rootScope, User, $routeParams, $timeout, $window, $filter, socket, Order, FileUploader, $http, Worker, Customer, tourConfig, WizardHandler, AclService, Lightbox, Stock, StockStore, Dayend, Account, RouteList, ngAudio, CustomerOrder) {

            var lastType;
            var notLoad = ['/'];
            $scope.beepSound = ngAudio.load("sounds/beep.mp3");

            $scope.optimizedWorkers = [];
            $scope.optimizedWorkersID = [];
            $scope.optimizationSpinner = false;

            $rootScope.message = {
                'status': false,
                'text': '',
                'type': 'error'
            };

            $scope.pageSizes = [
                25,
                50,
                100
            ];

            var customerAuthRoutes = [
                'customer-new-order',
                'customer-new-order',
                'customer-orders',
                'customer-reports',
                'customer-account',
                'customer-settings',
                'customer-integrations',
                'customer-integrations#_',
                'customer-printer',
                'customer-password',
                'customer-tracking',
                'customer-accounting',
                'customer-billing',
                'customer-feedback',
                'customer-check-orders',
                'satin-al',
                'uber-menu',
                'live-orders',
                'live-test',
            ];
            var salesAuthRoutes = [
                'sales-settings',
                'sales-members',
                'sales-tree',
                'sales-payments',
                'sales-feedback',
            ];
            var guest = ['/login', '/register', '/merchant/register', '/sales/register', '/forgot', '/reset-password'];
            $scope.pageViewLogin = guest.indexOf($location.path()) > -1 ? true : false;

            $rootScope.$on('loading:progress', function () {
                if (notLoad.indexOf(window.location.pathname) == -1) {
                    $scope.loadingEffect = true;
                }
            });

            $rootScope.$on('loading:finish', function () {
                if (notLoad.indexOf(window.location.pathname) == -1) {
                    $scope.loadingEffect = false;
                }
            });

            $scope.$on('$routeChangeStart', function ($event, next, current) {
                if (typeof $scope.authenticatedUser != 'undefined' && $scope.authenticatedUser.role == 3) {
                    var path = $location.url().split("/")[1];
                    if (customerAuthRoutes.indexOf(path.split("?")[0]) == -1) {
                        $location.path("/customer-orders");
                        return;
                    }
                }
                if (typeof $scope.authenticatedUser != 'undefined' && $scope.authenticatedUser.role == 4) {
                    var path = $location.url().split("/")[1];
                    if (salesAuthRoutes.indexOf(path.split("?")[0]) == -1) {
                        $location.path("/sales-dashboard");
                        return;
                    }
                }
            });

            $scope.newOrderData = {
                type: 1,
                address: {},
                images: [],
                assign: 'manual',
                tracking_sms: 0,
                notifications: [{
                    type: 'push',
                    time: 30,
                    period: 'minute'
                }],
                faults: [{
                    stock: '',
                    description: ''
                }],
                checklists: [],
                scheduled_time: moment().add(1, 'hours').startOf('hour').format('MM/DD/YYYY HH:mm')
            };

            $scope.opts = {
                autoApply: false,
                locale: {
                    applyClass: 'btn-green',
                    applyLabel: "Done",
                    cancelLabel: "",
                    fromLabel: "From",
                    toLabel: "/",
                    format: "MM/DD/YY",
                    customRangeLabel: 'Custom Range'
                },
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Day': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Day': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }

            $scope.showStatus = function (status) {
                var selected = $filter('filter')($scope.statuses, { value: status });
                return (status && selected.length) ? selected[0].text : 'Not set';
            };

            var componentForm = {
                street_number: 'short_name',
                route: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                administrative_area_level_2: 'short_name',
                country: 'long_name',
                postal_code: 'short_name'
            };

            var mapping = {
                street_number: 'number',
                route: 'street',
                locality: 'state',
                administrative_area_level_1: 'city',
                administrative_area_level_2: 'district',
                country: 'country',
                postal_code: 'zip'
            };

            $scope.colorList = [
                '#FF9800',
                '#3F51B5',
                '#2196F3',
                '#673AB7',
                '#009688',
                '#795548',
                '#607D8B',
                '#F44336'
            ];

            $scope.allowTimes = [
                "00:00", "00:15", "00:30", "00:45",
                "01:00", "01:15", "01:30", "01:45",
                "02:00", "02:15", "02:30", "02:45",
                "03:00", "03:15", "03:30", "03:45",
                "04:00", "04:15", "04:30", "04:45",
                "05:00", "05:15", "05:30", "05:45",
                "06:00", "06:15", "06:30", "06:45",
                "07:00", "07:15", "07:30", "07:45",
                "08:00", "08:15", "08:30", "08:45",
                "09:00", "09:15", "09:30", "09:45",
                "10:00", "10:15", "10:30", "10:45",
                "11:00", "11:15", "11:30", "11:45",
                "12:00", "12:15", "12:30", "12:45",
                "13:00", "13:15", "13:30", "13:45",
                "14:00", "14:15", "14:30", "14:45",
                "15:00", "15:15", "15:30", "15:45",
                "16:00", "16:15", "16:30", "16:45",
                "17:00", "17:15", "17:30", "17:45",
                "18:00", "18:15", "18:30", "18:45",
                "19:00", "19:15", "19:30", "19:45",
                "20:00", "20:15", "20:30", "20:45",
                "21:00", "21:15", "21:30", "21:45",
                "22:00", "22:15", "22:30", "22:45",
                "23:00", "23:15", "23:30", "23:45"
            ];

            $scope.cancelReasons = [
                {
                    'id': 'Before Delivery',
                    'name': 'Before Delivery',
                    'type': 'failed'
                },
                // {
                //     'id': 'After Delivery',
                //     'name': 'After Delivery',
                //     'type': 'cancel'
                // },
                {
                    'id': 'Late Delivery "30 min after delivery time"',
                    'name': 'Late Delivery "30 min after delivery time"',
                    'type': 'failed'
                },
                {
                    'id': 'Delivered Wrong Address',
                    'name': 'Delivered Wrong Address',
                    'type': 'failed'
                },
                {
                    'id': 'Damaged or Other',
                    'name': 'Damaged or Other',
                    'type': 'failed'
                },
                // {
                //     'id': 'Not enough information to complete delivery',
                //     'name': 'Not enough information to complete delivery',
                //     'type': 'unsuccessfull'
                // }
            ]

            $scope.states = [
                {
                    "name": "AL",
                    "abbreviation": "AL"
                },
                {
                    "name": "AK",
                    "abbreviation": "AK"
                },
                {
                    "name": "AS",
                    "abbreviation": "AS"
                },
                {
                    "name": "AZ",
                    "abbreviation": "AZ"
                },
                {
                    "name": "AR",
                    "abbreviation": "AR"
                },
                {
                    "name": "CA",
                    "abbreviation": "CA"
                },
                {
                    "name": "CO",
                    "abbreviation": "CO"
                },
                {
                    "name": "CT",
                    "abbreviation": "CT"
                },
                {
                    "name": "DE",
                    "abbreviation": "DE"
                },
                {
                    "name": "DC",
                    "abbreviation": "DC"
                },
                {
                    "name": "FM",
                    "abbreviation": "FM"
                },
                {
                    "name": "FL",
                    "abbreviation": "FL"
                },
                {
                    "name": "GA",
                    "abbreviation": "GA"
                },
                {
                    "name": "GU",
                    "abbreviation": "GU"
                },
                {
                    "name": "HI",
                    "abbreviation": "HI"
                },
                {
                    "name": "ID",
                    "abbreviation": "ID"
                },
                {
                    "name": "IL",
                    "abbreviation": "IL"
                },
                {
                    "name": "IN",
                    "abbreviation": "IN"
                },
                {
                    "name": "IA",
                    "abbreviation": "IA"
                },
                {
                    "name": "KS",
                    "abbreviation": "KS"
                },
                {
                    "name": "KY",
                    "abbreviation": "KY"
                },
                {
                    "name": "LA",
                    "abbreviation": "LA"
                },
                {
                    "name": "ME",
                    "abbreviation": "ME"
                },
                {
                    "name": "MH",
                    "abbreviation": "MH"
                },
                {
                    "name": "MD",
                    "abbreviation": "MD"
                },
                {
                    "name": "MA",
                    "abbreviation": "MA"
                },
                {
                    "name": "MI",
                    "abbreviation": "MI"
                },
                {
                    "name": "MN",
                    "abbreviation": "MN"
                },
                {
                    "name": "MS",
                    "abbreviation": "MS"
                },
                {
                    "name": "MO",
                    "abbreviation": "MO"
                },
                {
                    "name": "MT",
                    "abbreviation": "MT"
                },
                {
                    "name": "NE",
                    "abbreviation": "NE"
                },
                {
                    "name": "NV",
                    "abbreviation": "NV"
                },
                {
                    "name": "NH",
                    "abbreviation": "NH"
                },
                {
                    "name": "NJ",
                    "abbreviation": "NJ"
                },
                {
                    "name": "NM",
                    "abbreviation": "NM"
                },
                {
                    "name": "NY",
                    "abbreviation": "NY"
                },
                {
                    "name": "NC",
                    "abbreviation": "NC"
                },
                {
                    "name": "ND",
                    "abbreviation": "ND"
                },
                {
                    "name": "MP",
                    "abbreviation": "MP"
                },
                {
                    "name": "OH",
                    "abbreviation": "OH"
                },
                {
                    "name": "OK",
                    "abbreviation": "OK"
                },
                {
                    "name": "OR",
                    "abbreviation": "OR"
                },
                {
                    "name": "PW",
                    "abbreviation": "PW"
                },
                {
                    "name": "PA",
                    "abbreviation": "PA"
                },
                {
                    "name": "PR",
                    "abbreviation": "PR"
                },
                {
                    "name": "RI",
                    "abbreviation": "RI"
                },
                {
                    "name": "SC",
                    "abbreviation": "SC"
                },
                {
                    "name": "SD",
                    "abbreviation": "SD"
                },
                {
                    "name": "TN",
                    "abbreviation": "TN"
                },
                {
                    "name": "TX",
                    "abbreviation": "TX"
                },
                {
                    "name": "UT",
                    "abbreviation": "UT"
                },
                {
                    "name": "VT",
                    "abbreviation": "VT"
                },
                {
                    "name": "VI",
                    "abbreviation": "VI"
                },
                {
                    "name": "VA",
                    "abbreviation": "VA"
                },
                {
                    "name": "WA",
                    "abbreviation": "WA"
                },
                {
                    "name": "WV",
                    "abbreviation": "WV"
                },
                {
                    "name": "WI",
                    "abbreviation": "WI"
                },
                {
                    "name": "WY",
                    "abbreviation": "WY"
                }
            ];

            $scope.statuses = [
                {
                    name: 'Open',
                    value: '',
                    id: 0,
                },
                {
                    name: 'Paid',
                    value: 'complete',
                    id: 1,
                }
            ];

            $scope.orderCancelReasons = [
                {
                    name: 'Out of items',
                    value: 'OUT_OF_ITEMS'
                },
                {
                    name: 'Kitchen closed',
                    value: 'KITCHEN_CLOSED'
                },
                {
                    name: 'Customer called to cancel',
                    value: 'CUSTOMER_CALLED_TO_CANCEL'
                },
                {
                    name: 'Restaurant too bussy',
                    value: 'RESTAURANT_TOO_BUSY'
                },
                {
                    name: 'Cannot complete customer note',
                    value: 'CANNOT_COMPLETE_CUSTOMER_NOTE'
                },
                {
                    name: 'Other',
                    value: 'OTHER'
                },
            ];

            $scope.$on('IdleStart', function () {
                if ($scope.authenticatedUser.setting.idle) {
                    // $('#idle').modal({
                    //     backdrop: 'static',
                    //     keyboard: false,
                    //     show: true
                    // });
                } else {
                    location.reload();
                }
            });

            $scope.refreshPage = function () {
                location.reload();
            }

            $scope.searchCustomer = function ($select, type) {
                if (type != lastType) $scope.searchRes = [];
                if ($select.search) {
                    return $http.get('api/find-customer', {
                        params: {
                            s: $select.search,
                            type: type
                        },
                        ignoreLoadingBar: true
                    }).then(function (response) {
                        $scope.searchRes = response.data.customers;
                    });
                    lastType = type;
                };
            };

            $scope.createWebhook = function () {
                Account.createWebhook({ customer_id: $scope.customer.id }).success(function (data, status, headers, config) {
                    Messenger().post(data.message);
                    console.log(data);
                    $scope.customer.user.webhook = data.token.access_token;
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                })
            };

            function removeURLParameter(url, parameter) {
                return url
                    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
                    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
            }

            $scope.init = function (user) {
                User.getUser().success(function (data, status, headers, config) {
                    AclService.flushStorage();
                    var abilities = {
                        user: []
                    };
                    AclService.setAbilities(abilities);
                    AclService.attachRole('user');
                    $scope.can = AclService.can;

                    $scope.authenticatedUser = data;
                    if (typeof $scope.authenticatedUser != 'undefined' && $scope.authenticatedUser.role == 3) {
                        var path = $location.url().split("/")[1];
                        if (customerAuthRoutes.indexOf(removeURLParameter(path, 'data')) == -1) {
                            $location.path("/customer-orders");
                            return;
                        }
                    }

                    if ($scope.authenticatedUser.role != 1) {
                        changeFavicon('/favicon-customer.ico')
                    } else {
                        changeFavicon('/favicon-blue.ico')
                    }

                    if (data.authorization.maps) AclService.addAbility('user', 'maps');
                    if (data.authorization.office) AclService.addAbility('user', 'office');
                    if (data.authorization.customers) AclService.addAbility('user', 'customers');
                    if (data.authorization.users) AclService.addAbility('user', 'users');
                    if (data.authorization.settings) AclService.addAbility('user', 'settings');
                    if (data.authorization.reports) AclService.addAbility('user', 'reports');
                    if (data.role == 3) AclService.addAbility('user', 'customer');
                    if (data.role == 4) {
                        AclService.addAbility('user', 'sales');
                        var typeNumber = 4;
                        var errorCorrectionLevel = 'L';
                        var qr = qrcode(typeNumber, errorCorrectionLevel);
                        qr.addData('https://hodxpress.com/merchant/register?ref='+$scope.authenticatedUser.user_reference_code);
                        qr.make();
                        $scope.authenticatedUser.qr = qr.createDataURL(10);
                    }
                    $rootScope.selectedTeamName = data.teams[0];
                    $rootScope.firstLoad = true;

                    if (data.tour != -1 && $scope.authenticatedUser.remaining_day > 0) {
                        WizardHandler.wizard().goTo(data.tour);
                        $('#tutorialModal').modal('show');
                        $scope.$on('wizard:stepChanged', function (event, args) {
                            Order.tour({ step: args.index });
                        });
                    }
                    if (data.first_look == 1) {
                        $('#firstLook').modal({
                            backdrop: 'static',
                            keyboard: false,
                            show: true
                        });
                    }
                    if (data.update_status) {
                        $scope.update_data = data.update;
                        $('#updates').modal({
                            backdrop: 'static',
                            keyboard: false,
                            show: true
                        });
                    }

                    /* Tawk_API.visitor = {
                        name  : $scope.authenticatedUser.company_name
                    }; */
                });
            };

            function changeFavicon(src) {
                var link = document.createElement('link'),
                    oldLink = document.getElementById('dynamic-favicon');
                link.id = 'dynamic-favicon';
                link.rel = 'shortcut icon';
                link.href = src;
                if (oldLink) {
                    document.head.removeChild(oldLink);
                }
                document.head.appendChild(link);
            }

            $scope.firstLook = function () {
                User.firstLook({ step: 2 }).success(function (data, status, headers, config) {
                    $('#firstLook').modal('hide');
                });
            }

            $scope.updates = function () {
                User.updates().success(function (data, status, headers, config) {
                    $('#updates').modal('hide');
                });
            }

            $scope.mainPage = function () {
                /*
                User.getUser().success(function(data, status, headers, config) {
                    if(data.authorization.maps) $location.path('').search('type', null);  
                    else if(data.authorization.office) $location.path('projeler').search('type', null);
                    else if(data.authorization.users) $location.path('calisan-yonetimi').search('type', null);
                    else if(data.authorization.customers) $location.path('musteri-yonetimi').search('type', null);  
                    else if(data.authorization.reports) $location.path('order-report').search('type', null);
                    else if(data.authorization.settings) $location.path('profile').search('type', null);
                });
                */
            }

            $scope.newWorkerLink = function () {
                $('#newOrder').modal('hide');
                setTimeout(function () {
                    $location.path('yeni-calisan');
                    $scope.$apply();
                }, 300);
            }

            $scope.selectedTeam = function (team) {
                $rootScope.selectedTeamName = team;
                $scope.$broadcast('teamChange', team);
            }

            socket.on('worker_answer', function (data) {
                $scope.$broadcast('worker_answer', data);
            });

            socket.on('location_update', function (data) {
                try {
                    $scope.$broadcast('location_update', data);
                } catch (error) {

                }
            });

            socket.on('delete_order', function (data) {
                $scope.$broadcast('delete_order', data);
            });

            socket.on('refresh_page', function (data) {
                window.location.reload();
            });

            socket.on('new_order', function (data) {
                $scope.$broadcast('new_order', data);
            });

            socket.on('update_order', function (data) {
                $scope.$broadcast('update_order', data);
            });

            socket.on('worker_status', function (data) {
                $scope.$broadcast('worker_status', data);
            });
            $rootScope.messageCount = 0;
            socket.on('new_message', function (data) {
                $scope.beepSound.play();
                $rootScope.messageCount += 1;
                $scope.$broadcast('new_message', data);
            });

            $scope.addOrderNotification = function () {
                if (typeof $scope.newOrderData.notifications === 'undefined') {
                    $scope.newOrderData.notifications = [];
                }
                $scope.newOrderData.notifications.push({
                    type: 'push',
                    time: 30,
                    period: 'minute'
                });
            }

            $scope.removeOrderNotification = function (index) {
                $scope.newOrderData.notifications.splice(index, 1);
            }

            $scope.newTaskModal = function (_callback) {
                Order.newOrderData().success(function (data) {
                    $scope.orderData = data;
                    $scope.newOrderData = {
                        type: 1,
                        address: {},
                        images: [],
                        assign: 'manual',
                        tracking_sms: 0,
                        notifications: [{
                            type: 'push',
                            time: 30,
                            period: 'minute'
                        }],
                        faults: [{
                            stock: '',
                            description: ''
                        }],
                        checklists: [],
                        scheduled_time: moment().add(15 - (moment().format('mm') % 15), 'minutes').startOf('minute').format('MM/DD/YYYY HH:mm')
                    };
                    $('.new-work-screen').scrollTop(0);
                    // Create Map
                    createMap();
                    $scope.addOrderLocation();
                    jQuery.datetimepicker.setLocale('en');
                    jQuery('#datetimepicker').datetimepicker({
                        mask: true,
                        format: 'd/m/Y H:i',
                        defaultDate: $scope.newOrderData.scheduled_time,
                        inline: true,
                        timepickerScrollbar: false,
                        allowTimes: [
                            "00:00", "00:15", "00:30", "00:45",
                            "01:00", "01:15", "01:30", "01:45",
                            "02:00", "02:15", "02:30", "02:45",
                            "03:00", "03:15", "03:30", "03:45",
                            "04:00", "04:15", "04:30", "04:45",
                            "05:00", "05:15", "05:30", "05:45",
                            "06:00", "06:15", "06:30", "06:45",
                            "07:00", "07:15", "07:30", "07:45",
                            "08:00", "08:15", "08:30", "08:45",
                            "09:00", "09:15", "09:30", "09:45",
                            "10:00", "10:15", "10:30", "10:45",
                            "11:00", "11:15", "11:30", "11:45",
                            "12:00", "12:15", "12:30", "12:45",
                            "13:00", "13:15", "13:30", "13:45",
                            "14:00", "14:15", "14:30", "14:45",
                            "15:00", "15:15", "15:30", "15:45",
                            "16:00", "16:15", "16:30", "16:45",
                            "17:00", "17:15", "17:30", "17:45",
                            "18:00", "18:15", "18:30", "18:45",
                            "19:00", "19:15", "19:30", "19:45",
                            "20:00", "20:15", "20:30", "20:45",
                            "21:00", "21:15", "21:30", "21:45",
                            "22:00", "22:15", "22:30", "22:45",
                            "23:00", "23:15", "23:30", "23:45"
                        ],
                        scrollMonth: false,
                        onChangeDateTime: function (dp, $input) {
                            $scope.newOrderData.scheduled_time = moment($input.val(), 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY HH:mm');
                        }
                    })
                        .datetimepicker('reset');
                    if (typeof _callback !== 'undefined') _callback(data);
                });
            }

            $scope.loadRoute = function () {
                $('#routeDetail').modal('show');
                $scope.routeMarkers = [];
                Order.loadRoute($scope.selectedWork.id).success(function (data) {

                    var location = new google.maps.LatLng(Number($scope.selectedWork.customer.latitude), Number($scope.selectedWork.customer.longitude)),
                        bounds = new google.maps.LatLngBounds(),
                        route = [],
                        workerPath;

                    $scope.historyMap = new google.maps.Map(document.getElementById('map-history'), {
                        zoom: 13,
                        center: location,
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    });

                    data.locations.forEach(function (element) {
                        var routeLocation = new google.maps.LatLng(Number(element.latitude), Number(element.longitude));
                        route.push(routeLocation);
                        var marker = new google.maps.Marker({
                            position: routeLocation,
                            map: $scope.historyMap
                        });
                        var infowindow = new google.maps.InfoWindow({
                            content: "<strong>Accuracy : </strong> " + element.accuracy + ' Metre </br> <strong>Tarih : </strong>' + moment(element.created_at).format('MM/DD/YYYY HH:mm')
                        });
                        marker.addListener('click', function () {
                            infowindow.open($scope.historyMap, marker);
                        });
                        bounds.extend(routeLocation);
                        $scope.routeMarkers.push(marker);
                    });
                    var iconsetngs = {
                        path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
                    };

                    workerPath = new google.maps.Polyline({
                        path: route,
                        geodesic: true,
                        strokeColor: '#024466',
                        strokeOpacity: 0.7,
                        strokeWeight: 2,
                        icons: [{
                            repeat: '140px', //CHANGE THIS VALUE TO CHANGE THE DISTANCE BETWEEN ARROWS
                            icon: iconsetngs,
                            offset: '100%'
                        }]
                    });
                    workerPath.setMap($scope.historyMap);
                    $scope.historyMap.fitBounds(bounds);

                    // Company Marker
                    if ($scope.selectedWork.customer.latitude.length > 0) {
                        var routeLocation = new google.maps.LatLng(Number($scope.selectedWork.customer.latitude), Number($scope.selectedWork.customer.longitude));
                        var marker = new google.maps.Marker({
                            position: routeLocation,
                            map: $scope.historyMap,
                            icon: 'img/markers/location_guest.png'
                        });
                        var infowindow = new google.maps.InfoWindow({
                            content: "<strong>Firma Adı : </strong> " + $scope.selectedWork.customer.customer_name + '</br> <strong>Adres : </strong>' + $scope.selectedWork.customer.customer_name
                        });
                        marker.addListener('click', function () {
                            infowindow.open($scope.historyMap, marker);
                        });
                        infowindow.open($scope.historyMap, marker);
                        bounds.extend(routeLocation);
                        $scope.historyMap.fitBounds(bounds);
                    }
                });
            }

            $scope.toggleMarkerVisible = function () {
                $scope.routeMarkers.forEach(function (element) {
                    if (element.getVisible()) {
                        element.setVisible(false);
                    }
                    else {
                        element.setVisible(true);
                    }
                });
                $scope.routeMarkerVisible = !$scope.routeMarkerVisible;
            }

            $scope.duplicateWork = function () {
                $scope.newTaskModal(function (data) {
                    $scope.newOrderData = $rootScope.selectedWork;
                    $scope.newOrderData.address = $rootScope.selectedWork.customer;
                    $scope.addressSelected($scope.newOrderData.address, 'outsource');
                    delete $scope.newOrderData.id;
                    $scope.newOrderData.worker_id = [$scope.newOrderData.worker_id];
                });
            }

            $scope.copyText = async function  (text) {
                try {
                    await navigator.clipboard.writeText(text);
                    Messenger().post({ message: 'Copied', type: "success" });
                } catch (err) {
                    console.error('Failed to copy: ', err);
              }
            }

            $scope.updateWork = function (type) {
                if (type && type == 'customer') {
                    $location.path('customer-new-order').search('order_id', $rootScope.selectedWork.id);
                } else {
                    $location.path('new-order').search('order_id', $rootScope.selectedWork.id);
                }
            }

            $scope.cancelReason = {}
            $scope.setCancelReason = function (reason) {
                $scope.cancelReason.value = reason
            }
            $scope.setCancelOrder = function (id) {
                $scope.cancelReason.id = id
            }

            $scope.cancelOrder = function () {
                if (!$scope.cancelReason.id || !$scope.cancelReason.value) {
                    alert('Please select reason!');
                    return;
                }
                Order.changeStatus({
                    order_id: $scope.cancelReason.id,
                    status: 'cancel',
                    reason: $scope.cancelReason.value,
                    other: $scope.cancelReason.reason
                }).success(function (data, status, headers, config) {
                    Messenger().post('Successful');
                    $('#orderCancelReason').modal('hide');
                });
            }

            // $scope.printOrder = function(id) {
            //     window.open('/print-order/'+id,'popUpWindow','height=300,width=445,left=100,top=100,resizable=no,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
            // }

            $scope.printOrder = function (id) {
                var newwindow = window.open('/print-order/' + id, 'HODXpress Print Order', 'width=700, height=470');
                if (window.focus) {
                    newwindow.focus()
                }
                return false;
            }

            $scope.printDeliveryOrder = function (order) {
                CustomerOrder.updatePrintStatus([order.id]).success(function (data, status, headers, config) {
                    console.log('success');
                    order.print_status = 'printed';
                    var newwindow = window.open('/print-ondemand/' + order.id, 'HODXpress Print Order', 'width=700, height=470');
                    // var newwindow = window.open('/print-delivery/' + id, 'HODXpress Print Order', 'width=700, height=470');
                    if (window.focus) {
                        newwindow.focus()
                    }
                    return false;
                });
            }

            $scope.printWorker = function (id) {
                var newwindow = window.open('/print-worker/' + id, 'HODXpress Print Worker', 'width=700, height=470');
                if (window.focus) {
                    newwindow.focus()
                }
                return false;
            }

            $rootScope.collapseMenuStatus = false;
            $scope.collapseMenu = function () {
                $rootScope.collapseMenuStatus = !$rootScope.collapseMenuStatus;
            }

            $scope.openOptimize = function () {
                if ($scope.selectedRouteOrder.length == 0) {
                    alert('Lütfen atama yapmak istediğiniz işleri seçiniz.');
                    return true;
                }
                $scope.optimize = {
                    optimizeBy: 1
                };
                $scope.optimize.workers = [];
                Order.getWorkersForOptimizing().success(function (data, status, headers, config) {
                    $scope.optimizedWorkers = data.workers;
                    for (var i = 0; i < data.length; i++) {
                        $scope.optimize.workers.push(data[i]['id']);
                    }
                    $('#assignToWorkerRoute').modal('show');
                })
                    .error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                    });
            }

            $scope.getCheckbox = function (value) {
                try {
                    return JSON.parse(value);
                } catch (error) {
                    return [];
                }
            }

            $scope.newOrder = function (newWork) {
                // if(newWork) {
                /* if($scope.newOrderData.assign == 'manual' && typeof $scope.newOrderData.worker_id == 'undefined') {
                    $scope.info = {
                        title : "Dikkat",
                        message : "Manuel iş atamalarında personel seçmeniz gerekmektedir.",
                        class : 'danger'
                    };
                    $('#orderInfo').modal('show');
                }else { */
                Order.newOrder($scope.newOrderData).success(function (data, status, headers, config) {
                    $('#newOrder').modal('hide');
                    $scope.$broadcast('new_order', data);
                    $scope.newOrderData = {
                        type: 1,
                        address: {},
                        images: [],
                        scheduled_time: moment().add(1, 'hours').startOf('hour').format('MM/DD/YYYY HH:mm')
                    };
                    if ($scope.orderFromTour) $('#tutorialModal').modal('show');
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                });
                /*    }
                 }else {
                    $scope.info = {
                        title : "Dikkat",
                        message : "Lütfen açıklama, müşteri, adres ve personel alanlarını doldurun.",
                        class : 'danger'
                    };
                    $('#orderInfo').modal('show');
                } */
            };

            $scope.shortEnglishHumanizer = humanizeDuration.humanizer({
                language: "shortEn",
                languages: {
                    shortEn: {
                        y: () => "y",
                        mo: () => "mo",
                        w: () => "w",
                        d: () => "d",
                        h: () => "h",
                        m: () => "m",
                        s: () => "s",
                        ms: () => "ms",
                    },
                },
            });

            $scope.assignWorker = function () {
                Order.assignWorker($rootScope.selectedWork).success(function (data, status, headers, config) {
                    $rootScope.selectedWork = data;
                    $scope.$broadcast('updateOrder', data);
                    Messenger().post({ message: 'Order has been assigned successfully', type: "success" });
                    $('#assignWorker').modal('hide');
                });
            }

            $scope.changeWorkStatus = function () {
                if ($scope.selectedWork.status.value == 'failed' || $scope.selectedWork.status.value == 'unsuccessfull') {
                    if (!$scope.selectedWork.cancel_reason) {
                        alert('Please select reason!');
                        return;
                    }
                }
                Order.changeWorkStatus($rootScope.selectedWork).success(function (data, status, headers, config) {
                    $rootScope.selectedWork = data;
                    $scope.$broadcast('updateOrder', data);
                    $('#changeWorkStatus').modal('hide');
                });
            }

            $scope.addressSelected = function (selected, type) {
                console.log(selected);
                if (typeof type === 'undefined') {
                    if (typeof selected !== 'undefined') {
                        var customer = selected.originalObject;
                    } else {
                        var customer = [];
                    }
                } else {
                    var customer = selected;
                }
                if (typeof customer !== 'undefined' && typeof customer.latitude !== 'undefined') {
                    var location = new google.maps.LatLng(customer.latitude, customer.longitude);
                    if (typeof $scope.searchMap !== 'undefined') {
                        createMap();
                    }
                    setTimeout(function () {
                        $scope.searchMap.setCenter(location);
                        $scope.marker.setDuration(1000);
                        $scope.marker.setEasing("swing");
                        $scope.marker.setPosition(location);
                        angular.merge($scope.newOrderData.address, customer);
                        $scope.newOrderData.address.city = customer.state;
                        $scope.newOrderData.address.state = customer.city;
                        $scope.newOrderData.address.address = customer.address;
                        $scope.newOrderData.address.zipcode = customer.zipcode;
                        $scope.states.forEach(element => {
                            if (element.abbreviation == customer.city) {
                                $scope.newOrderData.address.state = element.name;
                                return true;
                            }
                        });
                        $scope.$apply();
                    }, 300);
                }

                if (typeof customer !== 'undefined' && typeof customer.fields_values !== 'undefined') {
                    $scope.newOrderData.address.fields = {};
                    angular.forEach(customer.fields_values, function (value, key) {
                        var val = value.value;
                        if (value.type == 'number') val = Number(value.value);
                        if (value.type == 'date') val = new Date(value.value);
                        $scope.newOrderData.address.fields[value.customer_field_id] = val;
                    });
                }
            }

            $scope.locationSelected = function (selected, type) {
                if (typeof type === 'undefined') {
                    if (typeof selected !== 'undefined') {
                        var customer = selected.originalObject;
                    } else {
                        var customer = [];
                    }
                } else {
                    var customer = selected;
                }
                if (typeof customer !== 'undefined' && typeof customer.latitude !== 'undefined') {
                    var location = new google.maps.LatLng(customer.latitude, customer.longitude);
                    setTimeout(function () {
                        $scope.searchMap.setCenter(location);
                        $scope.locationMarker.setDuration(1000);
                        $scope.locationMarker.setEasing("swing");
                        $scope.locationMarker.setPosition(location);
                        angular.merge($scope.newOrderData.location, customer);
                        $scope.newOrderData.location.city = customer.state;
                        $scope.newOrderData.location.state = customer.city;
                        $scope.newOrderData.location.address = customer.address;
                        $scope.newOrderData.location.zipcode = customer.zipcode;
                        $scope.states.forEach(element => {
                            if (element.abbreviation == customer.city) {
                                $scope.newOrderData.location.state = element.name;
                                return true;
                            }
                        });
                        $scope.$apply();
                    }, 300);
                }
            }

            $scope.addOrderLocation = function (data) {
                if (data) {
                    $scope.newOrderData.location = data;
                    var location = new google.maps.LatLng(Number(data.latitude), Number(data.longitude));
                } else {
                    $scope.newOrderData.location = {};
                    var location = new google.maps.LatLng(Number($scope.authenticatedUser.location.lat), Number($scope.authenticatedUser.location.lon));
                }
                var bounds = new google.maps.LatLngBounds();
                setTimeout(function () {
                    $scope.locationMarker = new SlidingMarker({
                        position: location,
                        map: $scope.searchMap,
                        visible: true,
                        draggable: true,
                        icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                    });
                    $scope.locationMarker.addListener('dragend', function (event) {
                        var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
                        var geocoder = new google.maps.Geocoder();
                        geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                if (results[0]) {
                                    var place = results[0];
                                    var details = place.geometry && place.geometry.location ? {
                                        latitude: place.geometry.location.lat(),
                                        longitude: place.geometry.location.lng()
                                    } : {};
                                    for (var i = 0; i < place.address_components.length; i++) {
                                        var addressType = place.address_components[i].types[0];
                                        if (componentForm[addressType]) {
                                            var val = place.address_components[i][componentForm[addressType]];
                                            details[mapping[addressType]] = val;
                                        }
                                    }
                                    details.formatted = place.formatted_address;
                                    details.placeId = place.place_id;
                                    details.address = place.formatted_address;
                                    angular.merge($scope.newOrderData.location, details);
                                    $scope.newOrderData.location.city = details.state;
                                    $scope.newOrderData.location.state = details.city;
                                    $scope.newOrderData.location.address = (details.number ? details.number : '') + ' ' + (details.street ? details.street : '');
                                    $scope.newOrderData.location.zipcode = details.zip;
                                    $scope.states.forEach(element => {
                                        if (element.abbreviation == details.city) {
                                            $scope.newOrderData.location.state = element.name;
                                            return true;
                                        }
                                    });
                                    $scope.$apply();
                                }
                            }
                        });
                    });
                }, 600);
            }

            $scope.removeOrderLocation = function () {
                $scope.locationMarker.setMap(null);
                delete $scope.newOrderData.location;
            }

            $scope.deleteWork = function () {
                Order.deleteWork($rootScope.selectedWork).success(function (data, status, headers, config) {
                    $rootScope.selectedWork = data;
                    $scope.$broadcast('deleteOrder', data);
                    $('#deleteWork').modal('hide');
                });
            }

            $scope.deleteTask = function () {
                Order.deleteTask($rootScope.selectedWork).success(function (data, status, headers, config) {
                    $rootScope.selectedWork = data;
                    $scope.$broadcast('deleteTask', data);
                    $('#deleteTask').modal('hide');
                });
            }

            $scope.getOrderDetails = function (work) {
                $rootScope.selectedWork = null;
                $rootScope.loadingWork = true;
                var id = work.id;
                if (typeof id == 'undefined') {
                    var id = work;
                }
                Order.order(id).success(function (data, status, headers, config) {
                    $rootScope.loadingWork = false;
                    $rootScope.selectedWork = data.order;
                    $scope.data = {
                        'steps': data.steps,
                        'workers': data.workers
                    }
                    $scope.data.steps.unshift({
                        id: 0,
                        name: 'Created'
                    });
                    $scope.addCallRecord = false;
                    $scope.selectedWork.items_code.forEach(element => {
                        var typeNumber = 4;
                        var errorCorrectionLevel = 'L';
                        var qr = qrcode(typeNumber, errorCorrectionLevel);
                        qr.addData(element.code);
                        qr.make();
                        element.qr = qr.createDataURL(10);
                    });
                    $('#orderDetail').modal('show');
                    setTimeout(() => {
                        $('#tabContent a[href="#workdetails"]').tab('show');
                        $scope.$apply()
                    }, 50);
                });
            }


            $scope.assignOrderModal = function (work) {
                $rootScope.selectedWork = null;
                $rootScope.loadingWork = true;
                var id = work.id;
                if (typeof id == 'undefined') {
                    var id = work;
                }
                Order.order(id).success(function (data, status, headers, config) {
                    $rootScope.loadingWork = false;
                    $rootScope.selectedWork = data.order;
                    $scope.data = {
                        'steps': data.steps,
                        'workers': data.workers
                    }
                    $scope.data.steps.unshift({
                        id: 0,
                        name: 'Created'
                    });
                    $scope.addCallRecord = false;
                    $scope.selectedWork.items_code.forEach(element => {
                        var typeNumber = 4;
                        var errorCorrectionLevel = 'L';
                        var qr = qrcode(typeNumber, errorCorrectionLevel);
                        qr.addData(element.code);
                        qr.make();
                        element.qr = qr.createDataURL(10);
                    });
                    $('#assignWorker').modal('show');
                    setTimeout(() => {
                        $('#tabContent a[href="#workdetails"]').tab('show');
                        $scope.$apply()
                    }, 50);
                });
            }

            $scope.changeVariable = function (a, b) {
                $scope[a] = b;
            }

            $scope.getWorkerDetails = function (id) {
                $rootScope.loadingWork = true;
                Worker.show(id).success(function (data, status, headers, config) {
                    $scope.worker = data.worker;
                    $scope.teams = data.teams;
                    $rootScope.loadingWork = false;
                    $('#workerDetail').modal('show');
                });
            }

            $scope.chageDocumentField = function (key, id, value) {
                if (confirm('Are you sure you want to do?')) {
                    Worker.chageDocumentField({ worker_id: $scope.worker.id, id: id, key: key, value: value }).success(function (data, status, headers, config) {
                        $scope.getWorkerDetails($scope.worker.id);
                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                    })
                }
            };

            $scope.chageUserStatus = function (status) {
                if (confirm('Are you sure you want to do?')) {
                    Worker.chageUserStatus({ worker_id: $scope.worker.id, status: status }).success(function (data, status, headers, config) {
                        $scope.getWorkerDetails($scope.worker.id);
                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                    })
                }
            };

            $scope.changeCustomerStatus = function (status) {
                if (confirm('Are you sure you want to do?')) {
                    Customer.changeCustomerStatus({ customer_id: $scope.customer.id, status: status }).success(function (data, status, headers, config) {
                        $scope.getCustomerDetails($scope.customer.id);
                        Messenger().post({ message: 'Approved' });
                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                    })
                }
            };

            $scope.updateWorkerField = function (key, value) {
                if (confirm('Are you sure you want to do?')) {
                    Worker.updateWorkerField({ worker_id: $scope.worker.id, key: key, value: value }).success(function (data, status, headers, config) {
                        $scope.getWorkerDetails($scope.worker.id);
                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                    })
                }
            };

            $scope.chageWorkerStatus = function (status) {
                if (confirm('Are you sure you want to do?')) {
                    Worker.chageWorkerStatus({ worker_id: $scope.worker.id, status: status }).success(function (data, status, headers, config) {
                        $scope.getWorkerDetails($scope.worker.id);
                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                    })
                }
            };

            $scope.chageWorkerRouteStatus = function (status) {
                if (confirm('Are you sure you want to do?')) {
                    Worker.chageWorkerRouteStatus({ worker_id: $scope.worker.id, status: status }).success(function (data, status, headers, config) {
                        $scope.getWorkerDetails($scope.worker.id);
                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                    })
                }
            };

            $scope.getCustomerDetails = function (id) {
                $rootScope.loadingWork = true;
                Customer.show(id).success(function (data, status, headers, config) {
                    $scope.customer = data.customer;
                    $scope.chart = data.chart;
                    $scope.fields = data.fields;
                    $scope.workTypes = data.workTypes;
                    $rootScope.loadingWork = false;
                    $('#customerDetail').modal('show');
                    $scope.map = new google.maps.Map(document.getElementById('map-customer'), {
                        zoom: 15,
                        disableDefaultUI: true,
                        scrollwheel: false,
                        navigationControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        draggable: false,
                        center: new google.maps.LatLng(Number(data.customer.latitude), Number(data.customer.longitude)),
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    });
                    $scope.marker = new SlidingMarker({
                        position: new google.maps.LatLng(Number(data.customer.latitude), Number(data.customer.longitude)),
                        map: $scope.map,
                        visible: true,
                        draggable: true
                    });
                });
            }

            $scope.getStockDetail = function (id) {
                $rootScope.loadingWork = true;
                Stock.get(id).success(function (data, status, headers, config) {
                    $scope.data = data;
                    $rootScope.loadingWork = false;
                    $('#stockDetail').modal('show');
                });
            }

            $scope.getDayendDetail = function (id) {
                $rootScope.loadingWork = true;
                Dayend.get(id).success(function (data, status, headers, config) {
                    $scope.data = data;
                    $rootScope.loadingWork = false;
                    $('#dayendDetail').modal('show');
                });
            }

            $scope.getStockStoreDetail = function (id) {
                $rootScope.loadingWork = true;
                StockStore.get(id).success(function (data, status, headers, config) {
                    $scope.data = data;
                    $rootScope.loadingWork = false;
                    $('#stockStoreDetail').modal('show');
                });
            }

            $scope.$on('$routeChangeStart', function (next, current) {
                $('.modal').modal('hide');
            });

            $scope.inputType = 'password';
            // Hide & show password function
            $scope.hideShowPassword = function () {
                if ($scope.inputType == 'password')
                    $scope.inputType = 'text';
                else
                    $scope.inputType = 'password';
            };
            $scope.inputTypeArr = [
                'password',
                'password',
                'password'
            ];
            $scope.hideShowPasswordArr = function (index) {
                if ($scope.inputTypeArr[index] == 'password')
                    $scope.inputTypeArr[index] = 'text';
                else
                    $scope.inputTypeArr[index] = 'password';
            };

            $scope.login = function (e) {
                User.login(e).success(function (data, status, headers, config) {
                    if (data.route == '/admin') {
                        location.reload();
                        return;
                    }

                    localStorage.setItem('token', data.token)
                    $scope.authenticatedUser = data.data;
                    AclService.flushStorage();
                    var abilities = {
                        user: []
                    };
                    AclService.setAbilities(abilities);
                    AclService.attachRole('user');
                    $scope.can = AclService.can;
                    socket.connect(data.stoken);

                    if (data.authorization.maps) AclService.addAbility('user', 'maps');
                    if (data.authorization.office) AclService.addAbility('user', 'office');
                    if (data.authorization.customers) AclService.addAbility('user', 'customers');
                    if (data.authorization.users) AclService.addAbility('user', 'users');
                    if (data.authorization.settings) AclService.addAbility('user', 'settings');
                    if (data.authorization.reports) AclService.addAbility('user', 'reports');
                    if (data.role == 3) AclService.addAbility('user', 'customer');
                    if (data.role == 4) AclService.addAbility('user', 'sales');

                    location.href = data.route;
                    return;

                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                });
            };

            $scope.registerLoading = false;
            $scope.register = function (status, outOfRegion) {
                if (!status || $scope.registerStep < 6) {
                    $scope.registerNext();
                    return true;
                }
                if ($scope.registerStep < 6) {
                    return;
                }

                $scope.registerLoading = true;
                if ($routeParams.platform) {
                    $scope.registerForm.platform = $routeParams.platform;
                }
                $scope.registerForm.uuid = $routeParams.uuid;
                User.register($scope.registerForm).success(function (data, status, headers, config) {
                    $scope.registerLoading = false;
                    window.localStorage.removeItem('registeredDriver')

                    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        console.log('mobile')
                        try {
                            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(data));
                        } catch (error) {
                            console.log(error);
                        }
                        // window.open('hodxpressdriver://loginasuser?data='+ JSON.stringify(data), '_system')
                        $scope.registerStep = 7;
                    } else {
                        $scope.registerStep = 7;
                    }

                    // if($routeParams.platform && $routeParams.platform !== 'null') {
                    // }else {
                    // }
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                    $scope.registerLoading = false;
                });
            };

            $scope.registerStepMerchant = 1;
            $scope.registerMerchant = function (status) {
                if (!status || $scope.registerStepMerchant < 5) {
                    $scope.registerNextMerchant();
                    return true;
                }
                if ($scope.registerStepMerchant < 5) {
                    return;
                }

                $scope.registerLoading = true;
                if ($routeParams.platform) {
                    $scope.registerForm.platform = $routeParams.platform;
                }
                if ($routeParams.ref) {
                    $scope.registerForm.ref = $routeParams.ref;
                }
                User.registerMerchant($scope.registerForm).success(function (data, status, headers, config) {
                    $scope.registerLoading = false;
                    window.localStorage.removeItem('registeredDriver')

                    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        try {
                            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(data));
                        } catch (error) {
                            console.log(error);
                        }
                        $scope.registerStepMerchant = 6;
                    } else {
                        $scope.registerStepMerchant = 6;
                    }

                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                    $scope.registerLoading = false;
                });
            };
            
            $scope.nextDisabledMerchant = function () {
                if ($scope.registerStep == 1) {
                    return true;
                }
                if ($scope.registerStep == 2) {
                    if (
                        $scope.registerForm.company_name && $scope.registerForm.company_name.length > 0 &&
                        $scope.registerForm.dba_name && $scope.registerForm.dba_name.length > 0 && 
                        $scope.registerForm.address && $scope.registerForm.tax_number.length > 0 &&
                        $scope.registerForm.address && $scope.registerForm.address.length > 0 &&
                        $scope.registerForm.city && $scope.registerForm.city.length > 0 &&
                        $scope.registerForm.state && $scope.registerForm.state.length > 0 &&
                        $scope.registerForm.zipcode && $scope.registerForm.zipcode.length > 0
                    ) {
                        return true;
                    }
                }
               
                if ($scope.registerStep == 3) {
                    if (
                        $scope.registerForm.first_name && $scope.registerForm.first_name.length > 0 &&
                        $scope.registerForm.last_name && $scope.registerForm.last_name.length > 0
                    ) {
                        return true;
                    }
                }
               
                if ($scope.registerStep == 4) {
                    if (
                        $scope.registerForm.email && $scope.registerForm.email.length > 0 &&
                        $scope.registerForm.password && $scope.registerForm.password.length > 0
                    ) {
                        return true;
                    }
                }

                if ($scope.registerStep == 5) {
                    if (
                        $scope.registerForm.account_name && $scope.registerForm.account_name.length > 0 &&
                        $scope.registerForm.routing_number && $scope.registerForm.routing_number.length > 0 &&
                        $scope.registerForm.account_number && $scope.registerForm.account_number.length > 0
                    ) {
                        return true;
                    }
                }

                return false;
            };

            $scope.registerNextMerchant = function () {

                if ($scope.registerStepMerchant == 1) {
                    $scope.registerStepMerchant = 2;
                }

                if($scope.registerStepMerchant == 2) {
                    if (
                        $scope.registerForm.company_name && $scope.registerForm.company_name.length > 0 &&
                        $scope.registerForm.dba_name && $scope.registerForm.dba_name.length > 0 && 
                        $scope.registerForm.address && $scope.registerForm.tax_number.length > 0 &&
                        $scope.registerForm.address && $scope.registerForm.address.length > 0 &&
                        $scope.registerForm.city && $scope.registerForm.city.length > 0 &&
                        $scope.registerForm.state && $scope.registerForm.state.length > 0 &&
                        $scope.registerForm.zipcode && $scope.registerForm.zipcode.length > 0
                    ) {
                        $scope.registerStepMerchant = 3;
                    }
                }

                if ($scope.registerStepMerchant == 3) {
                    if (
                        $scope.registerForm.first_name && $scope.registerForm.first_name.length > 0 &&
                        $scope.registerForm.last_name && $scope.registerForm.last_name.length > 0
                    ) {
                        $scope.registerStepMerchant = 4;
                    }
                }

                if ($scope.registerStepMerchant == 4) {
                    if (
                        $scope.registerForm.email && $scope.registerForm.email.length > 0 &&
                        $scope.registerForm.password && $scope.registerForm.password.length > 0
                    ) {
                        $scope.registerStepMerchant = 5;
                    }
                }

                if ($scope.registerStepMerchant == 5) {
                    if (
                        $scope.registerForm.account_name && $scope.registerForm.account_name.length > 0 &&
                        $scope.registerForm.routing_number && $scope.registerForm.routing_number.length > 0 &&
                        $scope.registerForm.account_number && $scope.registerForm.account_number.length > 0
                    ) {
                        $scope.registerMerchant(true);
                    }
                }                
            };

            $scope.nextDisabled = function () {
                if ($scope.registerStep == 1) {
                    if (
                        $scope.registerForm.first_name && $scope.registerForm.first_name.length > 0 &&
                        $scope.registerForm.last_name && $scope.registerForm.last_name.length > 0
                    ) {
                        return true;
                    }
                }
                if ($scope.registerStep == 2) {
                    if (
                        $scope.registerForm.address && $scope.registerForm.address.length > 0 &&
                        $scope.registerForm.city && $scope.registerForm.city.length > 0 &&
                        $scope.registerForm.state && $scope.registerForm.state.length > 0 &&
                        $scope.registerForm.zipcode && $scope.registerForm.zipcode.length > 0
                    ) {
                        return true;
                    }
                }
                if ($scope.registerStep == 3) {
                    if (
                        $scope.registerForm.vehicle_type && $scope.registerForm.vehicle_type.length > 0 &&
                        $scope.registerForm.vehicle_year && $scope.registerForm.vehicle_year.length > 0 &&
                        $scope.registerForm.vehicle_make && $scope.registerForm.vehicle_make.length > 0 &&
                        $scope.registerForm.vehicle_model && $scope.registerForm.vehicle_model.length > 0
                    ) {
                        return true;
                    } else {
                        if (['Scooter', 'Bike', 'Motorcycle'].indexOf($scope.registerForm.vehicle_type) > -1) {
                            return true;
                        }
                        $scope.showErrorMessage = true;
                    }
                }
                if ($scope.registerStep == 4) {
                    if (
                        $scope.registerForm.license_number && $scope.registerForm.license_number.length > 0 &&
                        $scope.registerForm.birthday_month && $scope.registerForm.birthday_month.length > 0 &&
                        $scope.registerForm.birthday_day && $scope.registerForm.birthday_day.length > 0 &&
                        $scope.registerForm.birthday_year && $scope.registerForm.birthday_year.length > 0 &&
                        $scope.registerForm.ssn_number && $scope.registerForm.ssn_number.length > 0 &&
                        $scope.registerForm.bgc_status
                    ) {
                        return true;
                    }
                } else if ($scope.registerStep == 5) {
                    if ($scope.w9FormStatus) {
                        return true;
                    }
                }
                if ($scope.registerStep == 6) {
                    if (
                        $scope.registerForm.account_name && $scope.registerForm.account_name.length > 0 &&
                        $scope.registerForm.routing_number && $scope.registerForm.routing_number.length > 0 &&
                        $scope.registerForm.account_number && $scope.registerForm.account_number.length > 0
                    ) {
                        return true;
                        $scope.register(true);
                    }
                }

                return false;
            };

            $scope.registerStep = 1;
            $scope.signStatus = false;
            $scope.registerNext = function () {
                if ($scope.registerStep == 1) {
                    if (
                        $scope.registerForm.first_name && $scope.registerForm.first_name.length > 0 &&
                        $scope.registerForm.last_name && $scope.registerForm.last_name.length > 0
                    ) {
                        $scope.registerStep = 2;
                    }
                }
                if ($scope.registerStep == 2) {
                    if (
                        $scope.registerForm.address && $scope.registerForm.address.length > 0 &&
                        $scope.registerForm.city && $scope.registerForm.city.length > 0 &&
                        $scope.registerForm.state && $scope.registerForm.state.length > 0 &&
                        $scope.registerForm.zipcode && $scope.registerForm.zipcode.length > 0
                    ) {
                        if ($scope.registerForm.state != 'NY' && $scope.registerForm.state != 'NJ') {
                            $scope.registerLoading = true;
                            $scope.registerForm.outOfRegion = true;
                            $scope.registerForm.uuid = $routeParams.uuid;
                            User.register($scope.registerForm).success(function (data, status, headers, config) {
                                $scope.successBoxMessage = "We are currently operating NY and NJ only. We will notify you when we start operating in your area.";
                                $('#successBox').modal('show');
                            }).error(function (data) {
                                Messenger().post({ message: data.message, type: "error" });
                                $scope.registerLoading = false;
                            });
                        } else {
                            $scope.registerStep = 3;
                        }
                    }
                }
                if ($scope.registerStep == 3) {
                    if (
                        $scope.registerForm.vehicle_type && $scope.registerForm.vehicle_type.length > 0 &&
                        $scope.registerForm.vehicle_year && $scope.registerForm.vehicle_year.length > 0 &&
                        $scope.registerForm.vehicle_make && $scope.registerForm.vehicle_make.length > 0 &&
                        $scope.registerForm.vehicle_model && $scope.registerForm.vehicle_model.length > 0
                    ) {
                        $scope.registerStep = 4;
                    } else {
                        if (['Scooter', 'Bike', 'Motorcycle'].indexOf($scope.registerForm.vehicle_type) > -1) {
                            $scope.registerStep = 4;
                        }
                        $scope.showErrorMessage = true;
                    }
                }
                if ($scope.registerStep == 4) {
                    if (
                        $scope.registerForm.license_number && $scope.registerForm.license_number.length > 0 &&
                        $scope.registerForm.birthday_month && $scope.registerForm.birthday_month.length > 0 &&
                        $scope.registerForm.birthday_day && $scope.registerForm.birthday_day.length > 0 &&
                        $scope.registerForm.birthday_year && $scope.registerForm.birthday_year.length > 0 &&
                        $scope.registerForm.ssn_number && $scope.registerForm.ssn_number.length > 0
                    ) {
                        $scope.registerStep = 5;
                    }
                } else if ($scope.registerStep == 5) {
                    if ($scope.w9FormStatus) {
                        $scope.registerStep = 6;
                    } else {
                        Messenger().post({ message: 'Please Sign W-9 Form', type: "error" });
                    }
                }
                User.setTempWorker({ data: $scope.registerForm, uuid: $routeParams.uuid })
                if ($scope.registerStep == 6) {
                    if (
                        $scope.registerForm.account_name && $scope.registerForm.account_name.length > 0 &&
                        $scope.registerForm.routing_number && $scope.registerForm.routing_number.length > 0 &&
                        $scope.registerForm.account_number && $scope.registerForm.account_number.length > 0
                    ) {
                        $scope.register(true);
                    }
                }
            };

            $scope.w9FormStatus = false;
            $scope.openW9Form = function () {
                $scope.disableW9Button = true;
                window.localStorage.setItem('registeredDriver', JSON.stringify($scope.registerForm));
                User.setTempWorker({ data: $scope.registerForm, uuid: $routeParams.uuid }).success(function (data, status, headers, config) {
                    User.w9Form({ data: $scope.registerForm, uuid: $routeParams.uuid }).success(function (data, status, headers, config) {
                        $scope.w9FormStatus = true;
                        $scope.signStatus = true;
                        // window.location.href = data.data.url;
                    }).error(function (data) {
                        $scope.disableW9Button = false;
                        Messenger().post({ message: data.message, type: "error" });
                    });
                })
            }
            $scope.registerBack = function () {
                $scope.registerStep -= 1;
            }
            $scope.registerBackMerchant = function () {
                $scope.registerStepMerchant -= 1;
            }
            
            $scope.registerMerchantInit = function (e) {

                window.onbeforeunload = function (event) {
                    var $openModalSelector = $(".modal.in"); 
                    if( ($openModalSelector.data('bs.modal') || {}).isShown == true){
                        $openModalSelector.modal("hide");
                    }
                    event.preventDefault();
                    return 'Are you sure you want to leave? You are in the middle of something.';
                };

                $scope.registerForm = {
                    type: 'merchant'
                };
                if ($routeParams.ref) {
                    $scope.registerForm.ref = $routeParams.ref;
                }
                if ($routeParams.email) {
                    $scope.registerForm.email = $routeParams.email;
                }
                if ($routeParams.phone_number) {
                    $scope.registerForm.phone_number = $routeParams.phone_number;
                }
                if ($routeParams.password) {
                    $scope.registerForm.password = $routeParams.password;
                }
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        $scope.registerForm.latitude = position.coords.latitude;
                        $scope.registerForm.longitude = position.coords.longitude;
                    });
                }
            };

            $scope.registerInit = function (e) {

                // window.onbeforeunload = function (event) {
                //     var $openModalSelector = $(".modal.in"); 
                //     if( ($openModalSelector.data('bs.modal') || {}).isShown == true){
                //         $openModalSelector.modal("hide");
                //     }
                //     event.preventDefault();
                //     return 'Are you sure you want to leave? You are in the middle of something.';
                // };

                $scope.registerForm = {};
                if ($routeParams.ref) {
                    $scope.registerForm.coming_reference_key = $routeParams.ref;
                }
                if ($routeParams.email) {
                    $scope.registerForm.email = $routeParams.email;
                }
                if ($routeParams.phone_number) {
                    $scope.registerForm.phone_number = $routeParams.phone_number;
                }
                if ($routeParams.password) {
                    $scope.registerForm.password = $routeParams.password;
                }
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        $scope.registerForm.latitude = position.coords.latitude;
                        $scope.registerForm.longitude = position.coords.longitude;
                    });
                }
                if ($routeParams.step) {
                    $scope.registerStep = $routeParams.step;
                    if ($scope.registerStep == 6) {
                        if ($routeParams.data) {
                            User.getTempWorker({ uuid: $routeParams.data }).success(function (data, status, headers, config) {
                                if (data.data.data) {
                                    $scope.registerForm = data.data.data; s
                                }
                            })
                        }
                    }
                }

                /* window.onbeforeunload = function () {
                    return 'Are you sure you want to leave? You are in the middle of something.';
                }; */

                User.getTempWorker({ uuid: $routeParams.uuid }).success(function (data, status, headers, config) {
                    if (data.data.data) {
                        $scope.registerForm = data.data.data;

                        if (data.data && data.data.status == 'success') {
                            $scope.registerStep = 7;
                        }
                    }
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                });
            };

            $scope.exportProof = function (status) {
                $scope.exportProofLoad = true;
                Order.exportProof($scope.selectedWork.id).success(function (data, status, headers, config) {
                    $scope.exportProofLoad = false;
                    window.location.href = data;
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                    $scope.exportProofLoad = false;
                });
            };

            $scope.forgot = function (e) {
                User.forgot({ email: e }).success(function (data, status, headers, config) {
                    Messenger().post("Password Reset link has been sent to your e-mail address.");
                    setTimeout(function () { window.location = "/"; }, 2000);
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                });
            };

            $scope.filterWorkerFromTeam = function (item) {
                if ($scope.newOrderData.team_id !== null && typeof $scope.newOrderData.team_id !== 'undefined') {
                    return item.team_id === $scope.newOrderData.team_id;
                } else {
                    return item;
                }
            }

            $scope.password = function (e) {
                e.token = $routeParams.token;
                User.password(e).success(function (data, status, headers, config) {
                    Messenger().post(data.message);
                    $location.path('/login');
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                });
            };

            $rootScope.$on('unauthorized', function () {
                $scope.logout();
            });

            $rootScope.closeModal = function (modal) {
                $('#accountLimit').modal('hide');
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open');
            }

            $scope.logout = function () {
                window.location = '/cikis';
                delete $localStorage.token;
            };

            $scope.stringToArray = function (data) {
                return data.split(',');
            }

            $scope.toNumber = function (data) {
                return Number(data);
            }

            function clearMarker(type) {
                $scope.markers[type] = [];
            }

            $(document).on('click', '.dropdown-menu.dropdown-menu-form', function (e) {
                e.stopPropagation();
            });

            $scope.isActive = function (route) {
                var path = $location.url().split("/")[1];
                if (typeof route === 'string') {
                    return route == path;
                }
                return route.indexOf(path) !== -1;
            };

            $scope.isInclude = function (route) {
                if (Array.isArray(route)) {
                    var status = 0;
                    route.forEach(function (item) {
                        if ($location.url().indexOf(item) > -1) {
                            status += 1;
                        }
                    })
                    return status > 0 ? true : false;
                } else {
                    return $location.url().indexOf(route) > -1;
                }
            };


            $scope.isActiveCustomer = function (route) {
                var path = '';
                for (var index = 0; index < $location.url().split("/").length; index++) {
                    path += $location.url().split("/")[index] + '/';
                }
                return route.indexOf(path) !== -1;
            };

            $scope.isActiveStock = function (route) {
                var path = '';
                for (var index = 0; index < $location.url().split("/").length; index++) {
                    path += $location.url().split("/")[index] + '/';
                }
                return route.indexOf(path) > -1;
            };

            $scope.getDate = function (date) {
                return new Date(date);
            }

            $scope.print = function () {
                window.print();
            }

            function createMap() {
                var location = new google.maps.LatLng(Number($scope.authenticatedUser.location.lat), Number($scope.authenticatedUser.location.lon));

                setTimeout(function () {
                    $scope.searchMap = new google.maps.Map(document.getElementById('map-order'), {
                        zoom: 9,
                        center: location,
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    });

                    $scope.marker = new SlidingMarker({
                        position: location,
                        map: $scope.searchMap,
                        visible: true,
                        draggable: true
                    });

                    var input = document.getElementById('googlePlaceInput');
                    google.maps.event.addDomListener(input, 'keydown', function (e) {
                        if (e.keyCode == 13) {
                            e.preventDefault();
                        }
                    });

                    $scope.marker.addListener('dragend', function (event) {
                        var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
                        var geocoder = new google.maps.Geocoder();
                        geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                if (results[0]) {
                                    var place = results[0];
                                    var details = place.geometry && place.geometry.location ? {
                                        latitude: place.geometry.location.lat(),
                                        longitude: place.geometry.location.lng()
                                    } : {};
                                    for (var i = 0; i < place.address_components.length; i++) {
                                        var addressType = place.address_components[i].types[0];
                                        if (componentForm[addressType]) {
                                            var val = place.address_components[i][componentForm[addressType]];
                                            details[mapping[addressType]] = val;
                                        }
                                    }
                                    details.formatted = place.formatted_address;
                                    details.placeId = place.place_id;
                                    details.address = place.formatted_address;
                                    angular.merge($scope.newOrderData.address, details);
                                    $scope.newOrderData.address.city = details.state;
                                    $scope.newOrderData.address.state = details.city;
                                    $scope.newOrderData.address.address = (details.number ? details.number : '') + ' ' + (details.street ? details.street : '');
                                    $scope.newOrderData.address.zipcode = details.zip;
                                    $scope.states.forEach(element => {
                                        if (element.abbreviation == details.city) {
                                            $scope.newOrderData.address.state = element.name;
                                            return true;
                                        }
                                    });
                                    $scope.$apply();
                                }
                            }
                        });
                    });
                }, 300);
            }

            var logo = $scope.logo = new FileUploader({
                url: '/api/upload-image-order',
                headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
                autoUpload: true
            });

            logo.filters.push({
                name: 'formatFilter',
                fn: function (item /*{File|FileLikeObject}*/, options) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    if ('|jpg|png|jpeg|bmp|gif|'.indexOf(type) === -1) {
                        alert('Sadece resim formatları desteklenmektedir. (jpg, png, jpeg, bmp, gif)');
                        return false;
                    };
                    return true;
                }
            });

            logo.onAfterAddingFile = function (fileItem) {
                $scope.progress = true;
                $scope.pending = true;
            };

            logo.onCompleteItem = function (item, response, status, headers) {
                $scope.progress = false;
                $scope.newOrderData.images.push(response.image);
                if (response.status == 'success') $scope.pending = false;
            }

            $scope.deleteImgFromNewOrder = function (image) {
                var index = $scope.newOrderData.images.indexOf(image);
                $scope.newOrderData.images.splice(index, 1);
            }

            // import task list
            $scope.donwloadSampleExcel = function () {
                Order.donwloadSampleExcel().success(function (data, status, headers, config) {
                    downloadURI(data.url, "Hodxpress Import Order.xlsx");
                });
            };

            function downloadURI(uri, name) {
                var link = document.createElement("a");
                link.download = name;
                link.href = uri;
                link.click();
            }

            $scope.importOrderList = function (files) {
                $scope.upload = true;
                var fd = new FormData();
                var imgBlob = dataURItoBlob(files);
                fd.append('file', imgBlob);
                $http.post('/api/import-order-list', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined,
                        'X-CSRF-TOKEN': CSRF_TOKEN
                    }
                })
                    .success(function (response) {
                        // Messenger().post(response.message);
                        angular.element('#excelImportInput').val(null);
                        $('#importOrderList').modal('hide');
                        $rootScope.proccesCount = 2;
                        $('#successBox').modal('show');
                    })
                    .error(function (response) {
                        $rootScope.message = {
                            status: true,
                            type: 'error',
                            text: response.message
                        }
                        setTimeout(function () {
                            $rootScope.message.status = false;
                            $scope.$apply();
                        }, 3000);
                        // Messenger().post({message: response.message,type: "error"});
                    })
                    .finally(function (data) {
                        $scope.upload = false;
                    });
            };

            $scope.uploadme;

            function dataURItoBlob(dataURI) {
                var binary = atob(dataURI.split(',')[1]);
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
                var array = [];
                for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                return new Blob([new Uint8Array(array)], {
                    type: mimeString
                });
            }

            $scope.humanizeDuration = function (time) {
                return humanizeDuration(time, { largest: 2, round: true, serialComma: false });
            }

            $scope.humanizeDurationMin = function (time, min) {
                return moment(time).diff(moment(), 'minutes') + min;
            }

            $scope.sendComment = function (description) {
                if (typeof description === 'undefined' || description.length == 0) {
                    alert('Please fill note field!');
                    return false;
                }
                $rootScope.loadingWork = true;
                var data = {
                    description: description,
                    order_id: $rootScope.selectedWork.id
                }
                Order.sendComment(data).success(function (data, status, headers, config) {
                    $rootScope.loadingWork = false;
                    $rootScope.selectedWork.logs.unshift(data);
                    $('#noteInput').val('');
                });
            }

            $scope.sendSms = function (sms_content, to) {
                if (typeof sms_content === 'undefined' || sms_content.length == 0) {
                    alert('Boş sms gönderilemez!');
                    return false;
                }
                $rootScope.loadingWork = true;
                var data = {
                    sms_content: sms_content,
                    order_id: $rootScope.selectedWork.id,
                    to: to
                }
                Order.sendSms(data).success(function (data, status, headers, config) {
                    $rootScope.loadingWork = false;
                    $('#smsContent').val('');
                    $('#smsContent2').val('');
                    Messenger().post(data.message);
                });
            }

            $scope.sendCallRecord = function (reason, to) {
                if (typeof reason === 'undefined' || reason.length == 0) {
                    alert('Boş çağrı kaydı eklenemez!');
                    return false;
                }
                $rootScope.loadingWork = true;
                var data = {
                    reason: reason,
                    order_id: $rootScope.selectedWork.id,
                    to: to
                }
                Order.sendCallRecord(data).success(function (data, status, headers, config) {
                    $rootScope.loadingWork = false;
                    $('#callRecord').val('');
                    $scope.addCallRecord = false;
                    Messenger().post(data.message);
                });
            }

            $scope.sendCustomerNote = function (description, id) {
                if (typeof description === 'undefined' || description.length == 0) {
                    alert('Please fill note field!');
                    return false;
                }
                $rootScope.loadingWork = true;
                var data = {
                    description: description,
                    customer_id: id
                }
                Customer.sendNote(data).success(function (data, status, headers, config) {
                    $rootScope.loadingWork = false;
                    $scope.customer.notes.unshift(data);
                    $('#noteInputCustomer').val('');
                    description = '';
                });
            }

            $scope.toggleDays = function (item) {
                item.checked = !item.checked;
            }

            $scope.addReceiver = function () {
                if (typeof $scope.newOrderData.receivers === 'undefined') {
                    $scope.newOrderData.receivers = [{
                        'phone_number': ''
                    }];
                } else {
                    $scope.newOrderData.receivers.push({
                        'phone_number': ''
                    });
                }
            }

            $scope.removeReceiver = function (index) {
                $scope.newOrderData.receivers.splice(index, 1);
            }

            $scope.showImageDetails = function (index) {
                $scope.showImageDetail[index] = !$scope.showImageDetail[index];
            }

            $scope.toggleRepeat = function (bool) {
                if (!bool) {
                    delete $scope.newOrderData.repeat;
                } else {
                    $scope.newOrderData.repeat = {
                        period: 'day',
                        end: 0,
                        days: [
                            {
                                name: 'Pt',
                                value: 'Pt',
                                checked: true
                            },
                            {
                                name: 'Sa',
                                value: 'Sa',
                                checked: true
                            },
                            {
                                name: 'Ça',
                                value: 'Ça',
                                checked: true
                            },
                            {
                                name: 'Pe',
                                value: 'Pe',
                                checked: true
                            },
                            {
                                name: 'Cu',
                                value: 'Cu',
                                checked: true
                            },
                            {
                                name: 'Ct',
                                value: 'Ct',
                                checked: true
                            },
                            {
                                name: 'Pz',
                                value: 'Pz',
                                checked: true
                            }
                        ]
                    };
                }
            }

            $scope.goBack = function () {
                window.history.back();
            }

            $scope.intercom = function () {
                Intercom("boot", {
                    app_id: "ojb4g6vy"
                });
                Intercom('show');
            }

            $scope.finishTour = function () {
                Order.tour({ completed: true });
                $('#tutorialModal').modal('hide');
                $location.path('/map');
                $scope.authenticatedUser.tour = true;
                tourConfig.backDrop = true;
                var curStep = 0;
                if (typeof curStep === 'string')
                    curStep = parseInt(curStep);
                $scope.currentStep = curStep || 0;
                $('#tutorialModal').modal('hide');
                $scope.tour = 1;
                $scope.orderFromTour = false;
            }

            $scope.startCreateOrder = function () {
                $('#tutorialModal').modal('hide');
                $('#newOrder').modal('show');
                $scope.newTaskModal();
                $scope.orderFromTour = true;
            }

            $(window).on('shown.bs.modal', function () {
                // Tawk_API.hideWidget();
            });

            $(window).on('hidden.bs.modal', function () {
                // Tawk_API.showWidget();
            });


            $scope.refreshData = function (page) {
                $scope.$broadcast('refresh_data');
            }

            $scope.newData = function (page) {
                $scope.$broadcast('new_data');
            }

            $scope.editData = function (data) {
                $scope.$broadcast('edit_data', data);
            }

            $scope.callDriver = function (data) {
                $scope.$broadcast('call_driver', data);
            }

            $scope.openLightboxModal = function (index) {
                Lightbox.openModal($scope.selectedWork.images, index);
            };

            $scope.openImage = function (image) {
                var data = [
                    'https://s3.us-west-2.amazonaws.com/hodxpress/' + image
                ];
                console.log(data);
                Lightbox.openModal(data, 0);
            };

            $scope.filterProp = [];

            $scope.addFilterProps = function (item) {
                if ($scope.filterProp.indexOf(item) === -1)
                    $scope.filterProp.push(item);
                else {
                    var index = $scope.filterProp.indexOf(item);
                    $scope.filterProp.splice(index, 1);
                }
            }

            $scope.searchStock = function ($select, type) {
                if (type != lastType) $scope.searchRes = [];
                if ($select.search) {
                    return $http.get('api/find-stock', {
                        params: {
                            s: $select.search,
                            type: type
                        },
                        ignoreLoadingBar: true
                    }).then(function (response) {
                        $scope.searchRes = response.data.stocks;
                    });
                    lastType = type;
                };
            };

            $scope.removeFault = function (index) {
                $scope.newOrderData.faults.splice(index, 1);
            }

            $scope.addOrderFault = function () {
                if (typeof $scope.newOrderData.faults === 'undefined') {
                    $scope.newOrderData.faults = [];
                }
                $scope.newOrderData.faults.push({
                    stock_id: '',
                    description: ''
                });
            }

            $scope.removeChecklist = function (index) {
                $scope.newOrderData.checklists.splice(index, 1);
            }

            $scope.addChecklist = function () {
                if (typeof $scope.newOrderData.checklists === 'undefined') {
                    $scope.newOrderData.checklists = [];
                }
                $scope.newOrderData.checklists.push({
                    stock_id: '',
                    description: ''
                });
            }

            $scope.updateChecklist = function (item) {
                Order.updateChecklist(item).success(function (data, status, headers, config) {

                });
            }

            $scope.calculateChecklist = function (checklist) {
                var count = checklist.length,
                    completed = 0;
                checklist.forEach(element => {
                    if (element.status == 1 || element.status == 2) {
                        completed++
                    }
                });
                return Number((completed * 100) / count).toFixed(2);
            }

            $scope.copyToClipBoard = function (text) {
                navigator.clipboard.writeText(text).then(function () {
                    alert("Kopyalandı");
                }, function (err) {
                    console.error('Async: Could not copy text: ', err);
                });
            }

            $scope.completeDayend = function (dayend) {
                Dayend.completeDayend({ id: dayend.id }).success(function (data, status, headers, config) {
                    $scope.data.dayend.status = true;
                    $scope.$broadcast('completeDayend', dayend);
                });
            }

            $scope.unCompleteDayend = function (dayend) {
                Dayend.unCompleteDayend({ id: dayend.id }).success(function (data, status, headers, config) {
                    $scope.data.dayend.status = false;
                    $scope.$broadcast('unCompleteDayend', dayend);
                });
            }


            $scope.downloadExcelDayend = function () {
                var data = {
                    orders: []
                };
                $scope.data.dayend.orders.forEach(element => {
                    data.orders.push(element.id);
                });
                Order.generateExcelReport(data).success(function (data, status, headers, config) {
                    window.location.href = data['url'];
                    setTimeout(function () {
                        Order.removeDownloadFile({ path: data['path'] }).success(function (response, status, headers, config) {
                            $scope.selectedItem = [];
                        });
                    }, 2000)
                });
            };

            $scope.resetRoute = function () {
                $location.search({});
            }

            $scope.addAlias = function () {
                console.log($scope.customer.aliases)
                $scope.customer.aliases.push({
                    alias: "",
                    integration: 'grubhub',
                    auto_accept: 1
                });
            }

            $scope.removeAlias = function (index) {
                $scope.customer.aliases.splice(index, 1);
            }

            $scope.saveAlias = function () {
                Customer.updateCustomer($scope.customer).success(function (data, status, headers, config) {
                  Messenger().post(data.message);
                }).error(function (data) {
                  Messenger().post({ message: data.message, type: "error" });
                })
              };

            $scope.aliasOptions = [
                { 'name': 'Yes', 'value': true }, 
                { 'name': 'No', 'value': false }
            ];

            $scope.registerStepSales = 1;
            $scope.registerBackSales = function () {
                $scope.registerStepSales -= 1;
            }

            $scope.registerSales = function (status) {
                if (!status || $scope.registerStepSales < 3) {
                    $scope.registerNextSales();
                    return true;
                }
                if ($scope.registerStepSales < 3) {
                    return;
                }
                $scope.registerLoading = true;
                if ($routeParams.ref) {
                    $scope.registerForm.ref = $routeParams.ref;
                }
                User.registerSales($scope.registerForm).success(function (data, status, headers, config) {
                    $scope.registerLoading = false;
                    window.localStorage.removeItem('registeredDriver')
                    $scope.registerStepSales = 4;
                    $scope.login({
                        email: $scope.registerForm.email,
                        password: $scope.registerForm.password,
                    })
                }).error(function (data) {
                    Messenger().post({ message: data.message, type: "error" });
                    $scope.registerLoading = false;
                });
            }

            $scope.registerSalesInit = function (e) {

                // window.onbeforeunload = function (event) {
                //     var $openModalSelector = $(".modal.in"); 
                //     if( ($openModalSelector.data('bs.modal') || {}).isShown == true){
                //         $openModalSelector.modal("hide");
                //     }
                //     event.preventDefault();
                //     return 'Are you sure you want to leave? You are in the middle of something.';
                // };

                $scope.registerForm = {
                    type: 'sales'
                };
                if ($routeParams.ref) {
                    $scope.registerForm.ref = $routeParams.ref;
                }
                if ($routeParams.email) {
                    $scope.registerForm.email = $routeParams.email;
                }
                if ($routeParams.phone_number) {
                    $scope.registerForm.phone_number = $routeParams.phone_number;
                }
                if ($routeParams.password) {
                    $scope.registerForm.password = $routeParams.password;
                }
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        $scope.registerForm.latitude = position.coords.latitude;
                        $scope.registerForm.longitude = position.coords.longitude;
                    });
                }
            };
            
            $scope.nextDisabledSales = function () {
                if ($scope.registerStepSales == 1) {
                    return true;
                }
                if ($scope.registerStepSales == 2) {
                    if (
                        $scope.registerForm.first_name && $scope.registerForm.first_name.length > 0 &&
                        $scope.registerForm.last_name && $scope.registerForm.last_name.length > 0 && 
                        $scope.registerForm.email && $scope.registerForm.email.length > 0 &&
                        $scope.registerForm.password && $scope.registerForm.password.length > 0
                    ) {
                        return true;
                    }
                }
               
                if ($scope.registerStepSales == 3) {
                    if (
                        $scope.registerForm.account_name && $scope.registerForm.account_name.length > 0 &&
                        $scope.registerForm.routing_number && $scope.registerForm.routing_number.length > 0 &&
                        $scope.registerForm.account_number && $scope.registerForm.account_number.length > 0
                    ) {
                        return true;
                    }
                }

                return false;
            };

            $scope.registerNextSales = function () {

                if ($scope.registerStepSales == 1) {
                    $scope.registerStepSales = 2;
                }

                if($scope.registerStepSales == 2) {
                    if (
                        $scope.registerForm.first_name && $scope.registerForm.first_name.length > 0 &&
                        $scope.registerForm.last_name && $scope.registerForm.last_name.length > 0 &&
                        $scope.registerForm.email && $scope.registerForm.email.length > 0 &&
                        $scope.registerForm.password && $scope.registerForm.password.length > 0
                    ) {
                        $scope.registerStepSales = 3;
                    }
                }

                if ($scope.registerStepSales == 3) {
                    if (
                        $scope.registerForm.account_name && $scope.registerForm.account_name.length > 0 &&
                        $scope.registerForm.routing_number && $scope.registerForm.routing_number.length > 0 &&
                        $scope.registerForm.account_number && $scope.registerForm.account_number.length > 0
                    ) {
                        $scope.registerSales(true);
                    }
                }                
            };
        }

    ]);