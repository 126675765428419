teleskop.
factory('Earning', ['$http', function($http) {
    return {
      all: function(data) {
        return $http.post('api/earnings', data);
      },
      get: function(id) {
        return $http.get('api/earning/' + id);
      },
      new: function(id) {
        return $http.get('api/new-earning');
      },
      delete: function(id) {
        return $http.delete('api/delete-earning/' + id);
      },
      save: function(data) {
        return $http.post('api/save-earning',data);
      },
      update: function(data) {
        return $http.post('api/update-earning',data);
      },
      changeStatus: function(data) {
        return $http.post('api/earning-change-status',data);
      },
      addPayment: function(data) {
        return $http.post('api/save-earning-payment',data);
      },
      updatePayment: function(data) {
        return $http.post('api/update-earning-payment',data);
      },
      deletePayment: function(id) {
        return $http.delete('api/delete-earning-payment/' + id);
      },
    };
}]);