teleskop.controller('WorkTypeController', ['$scope', 'WorkType', '$location','$routeParams','$filter',
  function ($scope, WorkType, $location, $routeParams, $filter) {
    $scope.deleted = [];

    $scope.all = function () {
      WorkType.all().success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.get = function () {
      WorkType.get($routeParams.id).success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.newType = function () {
      $scope.workType = {
        name : '',
      };
      $('#newType').modal('show');
    }

    $scope.editType = function (item) {
      $scope.workType = item;
      $('#newType').modal('show');
    }

    $scope.deleteType = function (id, type) {
			if(type == 'set') {
        $('#delete').modal('show');
        $scope.deleteTypeId = id;
      } else if(type == 'delete') {
				WorkType.delete($scope.deleteTypeId).success(function(data, status, headers, config) {
          Messenger().post(data.message);
          $scope.data = data;
          $('#delete').modal('hide');
				}).error(function(data) {
          Messenger().post({message: data.message,type: "error"});
				});
			} 
		}

		$scope.save = function () {
			WorkType.save($scope.workType).success(function(data, status, headers, config) {
				$scope.data = data;
        Messenger().post(data.message);
        $('#newType').modal('hide');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      }
      else {
        $scope.deleted.push(id);
      }
    };

  }
]);
