teleskop.controller('ChecklistTemplateController', ['$scope', 'ChecklistTemplate', '$location','$routeParams','$filter',
  function ($scope, ChecklistTemplate, $location, $routeParams, $filter) {
    $scope.deleted = [];
    $scope.checklistTemplate = {
      items : []
    }
    $scope.all = function () {
      ChecklistTemplate.all().success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.get = function () {
      ChecklistTemplate.get($routeParams.id).success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.delete = function (type) {
	    if(type == 'set') {
            $('#delete').modal('show');
        } else if(type == 'delete') {
		    ChecklistTemplate.delete($scope.deleted).success(function(data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
              var index = $scope.data.checklistTemplates.indexOf($filter('filter')($scope.data.checklistTemplates, {id: $scope.deleted[i] })[0]);
              if (index > -1)
              $scope.data.checklistTemplates.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
			}).error(function(data) {
                Messenger().post({message: data.message,type: "error"});
			});
		} 
	}

	$scope.save = function () {
		ChecklistTemplate.save($scope.checklistTemplate).success(function(data, status, headers, config) {
			$scope.data = data;
            Messenger().post(data.message);
            $location.path('checklist-sablonlar');
		}).error(function(data) {
      Messenger().post({message: data.message,type: "error"});
    });
	}

  $scope.update = function () {
    ChecklistTemplate.update($scope.data.checklistTemplate).success(function(data, status, headers, config) {
        $scope.data = data;
        Messenger().post(data.message);
        $location.path('checklist-sablonlar');
            }).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
    });
  }

    $scope.goTo = function(id) {
      $location.path('checklist-sablonlar/' + id);
    }

    $scope.getType = function(item) {
      switch (item) {
        case 'text':
            return 'Yazı';
          break;
        case 'number':
            return 'Numara';
          break;
        case 'date':
            return 'Tarih';
          break;
        case 'select':
            return 'Drop-Down (Seçim)';
          break;
      }
    }

    $scope.getValue = function(item) {
      if( item.value.length == 0 ) return '-';

      switch (item.type) {
        case 'text':
            return item.value;
          break;
        case 'number':
            return item.value;
          break;
        case 'date':
            return item.value;
          break;
        case 'select':
            return item.value;
          break;
      }
    }
      
    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      }
      else {
        $scope.deleted.push(id);
      }
    };

    $scope.removeChecklist = function(index) {
      $scope.checklistTemplate.items.splice(index, 1);
    }

    $scope.addChecklist = function () {
      if(typeof $scope.checklistTemplate.items === 'undefined') {
        $scope.checklistTemplate.items = [];
      }
      $scope.checklistTemplate.items.push({
        description: ''
      });
    }

    $scope.removeChecklistEdit = function(index) {
      $scope.data.checklistTemplate.items.splice(index, 1);
    }

    $scope.addChecklistEdit = function () {
      if(typeof $scope.data.checklistTemplate.items === 'undefined') {
        $scope.data.checklistTemplate.items = [];
      }
      $scope.data.checklistTemplate.items.push({
        description: ''
      });
    }

  }
]);
