teleskop.controller('NewOrderController',
    ['$scope', '$location', '$rootScope', 'User', '$timeout', 'Order', 'CustomerOrder', 'FileUploader', '$filter', '$http', '$routeParams',
        function ($scope, $location, $rootScope, User, $timeout, Order, CustomerOrder, FileUploader, $filter, $http, $routeParams) {
            $rootScope.proccesCount = 1;

            $scope.newOrderData = {
                type: 1,
                address: {},
                scheduled_time: moment().add(45, 'minutes').format('MM/DD/YYYY HH:mm'),
                status: 2,
                location: {},
                order_payment: 'online',
                flexible_date : 0,
                items : [{
                    weight : null,
                }],
                lines: [],
                work_type : 3
            };

            $scope.startMarker = null;
            $scope.endMarker = null;

            var componentForm = {
                street_number: 'short_name',
                route: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                administrative_area_level_2: 'short_name',
                country: 'long_name',
                postal_code: 'short_name'
            };

            var mapping = {
                street_number: 'number',
                route: 'street',
                locality: 'city',
                administrative_area_level_1: 'state',
                administrative_area_level_2: 'district',
                country: 'country',
                postal_code: 'zip'
            };

            $scope.setOrderDate = function (type) {
                if (type == 3) {
                    $scope.addLine();
                    $scope.newOrderData.scheduled_time = moment().add(45, 'minutes').format('MM/DD/YYYY HH:mm');
                    jQuery('#order_date').datetimepicker({
                        mask: true,
                        format: 'm/d/Y H:i',
                        startDate: $scope.newOrderData.scheduled_time,
                        inline: false,
                        timepickerScrollbar: false,
                        timepicker: true,
                        scrollMonth: false,
                        allowTimes: $scope.allowTimes,
                        onChangeDateTime: function (dp, $input) {
                            $scope.newOrderData.scheduled_time = moment($input.val(), 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY HH:mm');
                        }
                    });
                } else {
                    $scope.newOrderData.scheduled_time = null;
                    $scope.newOrderData.lines = [];
                }
            }

            $scope.init = function () {
                Order.newOrderData().success(function (data) {
                    $scope.orderData = data;
                    $scope.newOrderData = {
                        type: 1,
                        address: {},
                        scheduled_time: moment().add(45, 'minutes').format('MM/DD/YYYY HH:mm'),
                        status: 2,
                        location: {},
                        order_payment: 'online',
                        flexible_date : 0,
                        items : [{
                            weight : null,
                        }],
                        lines: [],
                        work_type : 3
                    };
                    jQuery('#order_date').datetimepicker({
                        format: 'm/d/Y H:i',
                        startDate: $scope.newOrderData.scheduled_time,
                        inline: false,
                        timepickerScrollbar: false,
                        scrollMonth: false,
                        allowTimes: $scope.allowTimes,
                        onChangeDateTime: function (dp, $input) {
                            $scope.newOrderData.scheduled_time = moment($input.val(), 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY HH:mm');
                        }
                    });
                    createMapCustomer()
                    $scope.setOrderDate(3);
                });

                if ($routeParams.order_id) {
                    Order.order($routeParams.order_id).success(function (data, status, headers, config) {
                        $scope.newOrderData = data.order
                        $scope.newOrderData.work_type = data.order.work_type ? data.order.work_type.id : $scope.orderData.workTypes[0].id;
                        jQuery('#order_date').datetimepicker({
                            mask: true,
                            format: 'd/m/Y H:i',
                            startDate: $scope.newOrderData.scheduled_time,
                            inline: true,
                            timepickerScrollbar: false,
                            scrollMonth: false,
                            allowTimes: $scope.allowTimes,
                            onChangeDateTime: function (dp, $input) {
                                $scope.newOrderData.scheduled_time = moment($input.val(), 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY HH:mm');
                            }
                        });

                        $scope.locationSelectedCustomer(data.order.location, 'order')
                    });
                }
            }

            $scope.submitNewOrder = function () {
                if (!$scope.newOrderData.location.name) {
                    $('.location-name input').focus();
                } else {
                    setTimeout(() => {
                        document.getElementById('newWorkBtn').click()
                    }, 300);
                }
            }

            $scope.addBox = function () {
                $scope.newOrderData.items.push({
                    weight: null,
                });
            }

            $scope.removeBox = function (index) {
                $scope.newOrderData.items.splice(index, 1);
            }

            $scope.addLine = function () {
                $scope.newOrderData.lines.push({
                    name: null,
                    qty: 1,
                    price: 0,
                    source: 'manual'
                });
            }

            $scope.removeLine = function (index) {
                $scope.newOrderData.lines.splice(index, 1);
            }


            $scope.newOrder = function (newWork) {
                if (newWork) {
                    $scope.disabledBtn = true;
                    Order.newOrder($scope.newOrderData).success(function (data, status, headers, config) {
                        $scope.newOrderData = {
                            type: 1,
                            address: {},
                            scheduled_time: moment().add(45, 'minutes').format('MM/DD/YYYY HH:mm'),
                            status: 2,
                            location: {},
                            order_payment: 'online',
                            flexible_date : 0,
                            items : [{
                                weight : null,
                            }],
                            lines: [],
                            work_type : 3
                        };
                        Messenger().post('Order has been created successfully!');
                        $scope.disabledBtn = false;
                        $('#successBox').modal('show');
                        $scope.init();
                        // $scope.getOrderDetails(data);

                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                        $scope.disabledBtn = false;
                    });
                }
            }

            $scope.updateOrder = function (newWork) {
                $scope.disabledBtn = true;
                Order.updateOrder($scope.newOrderData).success(function (data, status, headers, config) {
                    Messenger().post('Order has been updated successfully!');
                    $scope.disabledBtn = false;
                    $scope.getOrderDetails($scope.newOrderData);
                    $scope.newOrderData = {
                        type: 1,
                        address: {},
                        scheduled_time: moment().add(45, 'minutes').format('MM/DD/YYYY HH:mm'),
                        status: 2,
                        location: {},
                        order_payment: 'online',
                        flexible_date : 0,
                        items : [{
                            weight : null,
                        }],
                        lines: [],
                        work_type : 3
                    };
                    $rootScope.procces = 'update';
                    $('#successBox').modal('show');
                }).error(function (data) {
                    $scope.disabledBtn = false;
                    Messenger().post({ message: data.message, type: "error" });
                });
            };

            $scope.locationSelectedCustomer = function (selected, type) {
                if (typeof type === 'undefined') {
                    if (typeof selected !== 'undefined') {
                        var customer = selected.originalObject;
                    } else {
                        var customer = [];
                    }
                } else {
                    var customer = selected;
                }
                if (typeof customer !== 'undefined' && typeof customer.latitude !== 'undefined') {
                    var location = new google.maps.LatLng(customer.latitude, customer.longitude);
                    setTimeout(function () {
                        $scope.searchMap.setCenter(location);
                        $scope.marker.setDuration(1000);
                        $scope.marker.setEasing("swing");
                        $scope.marker.setPosition(location);
                        angular.merge($scope.newOrderData.location, customer);
                        $scope.newOrderData.location.city = customer.city;
                        $scope.newOrderData.location.state = customer.state;
                        if (type && type == 'search') {
                            var address = ''
                            if (customer.number) {
                                address += customer.number + ' '
                            }
                            if (customer.street) {
                                address += customer.street
                            }
                            $scope.newOrderData.location.address = address;
                        } else {
                            $scope.newOrderData.location.address = customer.address;
                        }
                        $scope.newOrderData.location.zipcode = customer.zipcode ? customer.zipcode : customer.zip;
                        $scope.states.forEach(element => {
                            if (element.abbreviation == customer.city) {
                                $scope.newOrderData.location.state = element.name;
                                return true;
                            }
                        });
                        $scope.$apply();
                    }, 300);
                }
            }

            function createMapCustomer() {
                var location = new google.maps.LatLng(Number($scope.authenticatedUser.location.lat), Number($scope.authenticatedUser.location.lon));
                setTimeout(function () {
                    $scope.searchMap = new google.maps.Map(document.getElementById('customerMapOrder'), {
                        zoom: 11,
                        center: location,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        disableDefaultUI: true,
                        styles: [{
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#f5f5f5"
                            }]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#616161"
                            }]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [{
                                "color": "#f5f5f5"
                            }]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#bdbdbd"
                            }]
                        },
                        {
                            "featureType": "landscape.natural",
                            "elementType": "geometry.stroke",
                            "stylers": [{
                                "color": "#4247bd"
                            }]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#eeeeee"
                            }]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#757575"
                            }]
                        },
                        {
                            "featureType": "poi.business",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#e5e5e5"
                            }]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#9e9e9e"
                            }]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#ffffff"
                            }]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#757575"
                            }]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#dadada"
                            }]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#616161"
                            }]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#9e9e9e"
                            }]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#e5e5e5"
                            }]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#eeeeee"
                            }]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#c9c9c9"
                            }]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry.fill",
                            "stylers": [{
                                "color": "#bdd1e8"
                            }]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#9e9e9e"
                            }]
                        }
                        ]
                    });

                    $scope.marker = new SlidingMarker({
                        position: location,
                        map: $scope.searchMap,
                        visible: true,
                        draggable: true,
                        icon: '/img/map-pin-blue.png'
                    });

                    if (navigator.geolocation && !$scope.newOrderData.id) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            var location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                            $scope.marker.setPosition(location)
                            $scope.searchMap.setCenter(location)
                        });
                    } else {
                        console.log("Geolocation is not supported by this browser.");
                    }

                    var input = document.getElementById('googlePlaceInput');
                    google.maps.event.addDomListener(input, 'keydown', function (e) {
                        if (e.keyCode == 13) {
                            e.preventDefault();
                        }
                    });

                    $scope.marker.addListener('dragend', function (event) {

                        var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
                        var geocoder = new google.maps.Geocoder();

                        geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                if (results[0]) {
                                    var place = results[0];
                                    var details = place.geometry && place.geometry.location ? {
                                        latitude: place.geometry.location.lat(),
                                        longitude: place.geometry.location.lng()
                                    } : {};
                                    for (var i = 0; i < place.address_components.length; i++) {
                                        var addressType = place.address_components[i].types[0];
                                        if (componentForm[addressType]) {
                                            var val = place.address_components[i][componentForm[addressType]];
                                            details[mapping[addressType]] = val;
                                        }
                                    }
                                    details.formatted = place.formatted_address;
                                    details.placeId = place.place_id;
                                    details.address = place.formatted_address;
                                    angular.merge($scope.newOrderData.location, details);
                                    $scope.newOrderData.location.city = details.city;
                                    $scope.newOrderData.location.state = details.state;
                                    var address = ''
                                    if (details.number) {
                                        address += details.number + ' '
                                    }
                                    if (details.street) {
                                        address += details.street
                                    }
                                    $scope.newOrderData.location.address = address;
                                    $scope.newOrderData.location.zipcode = details.zipcode ? details.zipcode : details.zip;
                                    $scope.states.forEach(element => {
                                        if (element.abbreviation == details.city) {
                                            $scope.newOrderData.location.state = element.name;
                                            return true;
                                        }
                                    });
                                    $scope.$apply();
                                }
                            }
                        });
                    });
                }, 300);


            }
        }
    ]);