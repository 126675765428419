teleskop.controller('AnalyticController', ['$scope', 'Analytic','Account', '$location','FileUploader','$localStorage',
  function ($scope, Analytic, Account, $location, FileUploader, $localStorage) {

    $scope.filterData = {
      type : 'order',
      date : {
          startDate: moment().subtract(6, 'days'),
          endDate: moment()
      },
      worker_filter_type : 'general',
      worker_filter_status : 'all',
      customer_filter_type : 'customer_work',
    }

    $scope.init = function () {
      Analytic.init().success(function(data, status, headers, config) {
        $scope.data = data;
          $scope.filter();
        
        /*$scope.$watch('filterData', function() {
        }, true);*/
      });
    };

    $scope.filter = function () {
      Analytic.filter($scope.filterData).success(function(data, status, headers, config) {
        $scope.chart = data;
        if($scope.filterData.type == 'worker' && $scope.filterData.worker_filter_type == 'worker') {
          $scope.chart_data = data.chart_data;
          $scope.options = {
            scales: {
              xAxes: [{
                stacked: true,
                gridLines:{
                  display: false
                }
              }],
              yAxes: [{
                stacked: true,
                ticks: {
                    min: 0
                },
                gridLines:{
                  color: 'rgb(255,255,255)',
                  lineWidth: 1
                }
              }]
            }
          };
        } else if($scope.filterData.type == 'worker' && $scope.filterData.worker_filter_type == 'general') {
          $scope.chart_data = data.chart_data;
        }else {
          $scope.chart_data = [data.chart_data];
        }
      }).error(function(data) {
        Messenger().post({message: data.message,type: "error"}); 
      });
    };

    $scope.export = function () {
      $scope.filterData.export = true;
      Analytic.filter($scope.filterData).success(function(data, status, headers, config) {
        delete $scope.filterData.export;
        window.location.href = data.url;
      }).error(function(data) {
        Messenger().post({message: data.message,type: "error"}); 
      });
    };

    $scope.getArrayVal = function(data) {
      return JSON.parse(data)
    }

  }
]);
