teleskop.controller('WorkerController', ['$scope', 'Worker', '$location', 'FileUploader', '$localStorage', '$filter', '$routeParams', '$rootScope',
  function ($scope, Worker, $location, FileUploader, $localStorage, $filter, $routeParams, $rootScope) {
    $scope.address = 0;
    $scope.deleted = [];
    $scope.update = {};
    $scope.edit = [];
    $scope.newAdress = {};
    $scope.sortType = 'user.status';
    $scope.sortReverse = false;

    $scope.selectAll = function (select) {
      if (select) {
        $scope.deleted = [];
        for (let i = 0; i < $scope.data.workers.data.length; i++) {
          $scope.deleted.push($scope.data.workers.data[i].id);
        }
      } else {
        $scope.deleted = [];
      }
    };

    $scope.$on('new_data', function () {
      $scope.newWorker();
      $('#newDriver').modal('show');
    }, true);

    $scope.$on('edit_data', function (event, data) {
      $scope.editWorker(data);
    }, true);
    
    $scope.$on('call_driver', function (event, data) {
      $scope.callDriver(data);
    }, true);

    $scope.init = function (type) {
      Worker.init($routeParams.type).success(function (data, status, headers, config) {
        $scope.data = data;
        if (type && type == 'show') {
          $scope.show();
        }
      });
    };

    $scope.newWorker = function () {
      $scope.worker = {
        type: 'Car',
        icon: 'Car',
        icon_color: '#000000'
      };
      Worker.newWorker().success(function (data, status, headers, config) {
        $scope.stations = data.stations;
      })
    };

    $scope.saveWorker = function (worker) {
      $scope.loading = true;
      if(worker.id) {
        $scope.updateWorker(worker);
        return true;
      }
      Worker.saveWorker(worker).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $scope.worker = data.data;
        $scope.init('show');
        $('#newDriver').modal('hide');
        $scope.loading = false;
      }).error(function (data) {
        $scope.loading = false;
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    };

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Worker.deleteWorker($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.workers.data.indexOf($filter('filter')($scope.data.workers.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.workers.data.splice(index, 1);
          };
          $scope.deleted = [];
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    };

    $scope.sendNotification = function (type, notification) {
      if (type == 'set') {
        $('#notification').modal('show');
      } else if (type == 'send') {
        var data = {
          workers: $scope.deleted,
          notification: notification
        };
        Worker.sendNotification(data).success(function (data, status, headers, config) {
          Messenger().post(data.message);
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    };

    $scope.editWorker = function (id) {
      Worker.show(id).success(function (data, status, headers, config) {
        $scope.worker = data.worker;
        $scope.stations = data.stations;
        $scope.worker.first_name = $scope.worker.user.first_name;
        $scope.worker.last_name = $scope.worker.user.last_name;
        $scope.worker.email = $scope.worker.user.email;
        $('#workerDetail').modal('hide');
        $('#newDriver').modal('show');
      });
    }

    $scope.updateWorker = function (worker) {
      $scope.loading = true;
      Worker.updateWorker(worker).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $('#newDriver').modal('hide');
        $scope.loading = false;
        $scope.init('show');
        $scope.getWorkerDetails($scope.worker.id);
      }).error(function (data) {
        $scope.loading = false;
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    };

    $scope.show = function () {
      Worker.show($scope.worker.id).success(function (data, status, headers, config) {
        $scope.worker = data.worker;
        $scope.stations = data.stations;
        $('#workerDetail').modal('show');
      });
    };

    $scope.getNumber = function (num) {
      return new Array(num);
    };

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

    $rootScope.w9file = new FileUploader({
      url: '/api/upload-document?token=' + $localStorage.token,
      headers: {'X-CSRF-TOKEN': CSRF_TOKEN},
      autoUpload: true
    });

    $rootScope.w9file.filters.push({
      name: 'formatFilter',
      fn: function(item /*{File|FileLikeObject}*/, options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|pdf|docx|doc|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
      }
    });

    $rootScope.w9file.onAfterAddingFile = function(fileItem) {
      $rootScope.proccessProfile = true;
      $scope.pending = true;
    };

    $rootScope.w9file.onCompleteItem = function(item, response, status, headers){
      $rootScope.proccessProfile = false;
      $scope.worker.w9 = response.document;
      if(response.status == 'success')
        $scope.pending = false;
    }

    $rootScope.w9file.onBeforeUploadItem = function(fileItem) {
      fileItem.formData.push({ 
        type: 'w9',
        worker_id: $scope.worker.id
      });
    };

    $scope.callDriver = function () {
      $rootScope.loading = true;
      Worker.callDriver({ worker_id: $scope.worker.id}).success(function (data, status, headers, config) {
        $rootScope.loading = false;
        Messenger().post({
          message: 'Call driver started successfully',
          type: "success"
        });
      });
    };

  }
]);