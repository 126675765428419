teleskop.controller('WorkerAnalyticController', ['$scope', 'Analytic','Account', '$location','FileUploader','$localStorage',
  function ($scope, Analytic, Account, $location, FileUploader, $localStorage) {

		$scope.filterData = {
      		export: false,
			time : { 
				startDate: null, 
				endDate: null 
			}
		}

		$scope.$on('refresh_data', function() {
			console.log('refreshing worker reports...')
			$scope.init();
		}, true);

		$scope.$watch('filterData', function() {
			$scope.init();
		}, true);

		$scope.init = function (type) { 
			Analytic.workers($scope.filterData).success(function(data, status, headers, config) {
				if(typeof type !== undefined && type == 'export') {
			    $scope.filterData.export = false;
					window.location.href = data.url;					
				}else {
					console.log(data);
					$scope.data = data;
					/*
          $scope.series = ['Toplam Personel','Toplam İş'];
          $scope.lineSeries = ['Toplam Personel'];
          $scope.chartData = [data.count.total,data.count.order];
					$scope.chart2Data = [data.chart.count];
					*/
        }
			});
		};

		$scope.getPage = function(page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function(page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.sort = function(field,sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if(type == 'clear') {
				$scope.filterData = {
          			export: false,
					time : { 
						startDate: null, 
						endDate: null 
					}
				}
			}
			$scope.init();
		}

  }
]);
