teleskop.controller('RouteListController', ['$scope', 'RouteList', 'Route', '$location', '$routeParams', '$filter', '$rootScope', 'Order',
  function ($scope, RouteList, Route, $location, $routeParams, $filter, $rootScope, Order) {
    $scope.deleted = [];

    $scope.selectAll = function (select) {
      if (select) {
        $scope.deleted = [];
        for (let i = 0; i < $scope.data.routes.data.length; i++) {
          $scope.deleted.push($scope.data.routes.data[i].id);
        }
      } else {
        $scope.deleted = [];
      }
    };

    $scope.oldRoute = {};

    $scope.changeWorker = function () {
      if (confirm('This route driver will change, are you sure ?')) {
        Route.assignToWorker({
          worker_id: $scope.route.worker_id,
          route_id: $scope.route.id
        }).success(function (data, status, headers, config) {
          Messenger().post('Successfull');
          $scope.all();
        }).error(function (data) {
          $scope.route.worker = $scope.oldRoute.worker;
          alert(data.message);
        });
      } else {
        $scope.route.worker_id = 0;
      }
    }

    $scope.changeRouteStatus = function () {
      if (confirm('This route status will change, are you sure ?')) {
        Route.changeRouteStatus({
          status: $scope.route.status,
          route_id: $scope.route.id
        }).success(function (data, status, headers, config) {
          Messenger().post('Successfull');
          $scope.all();
        }).error(function (data) {
          $scope.route.status = $scope.oldRoute.status;
          alert(data.message);
        });
      } else {
        $scope.route.status = $scope.oldRoute.status;
      }
    }

    $scope.changeItem = function (type, code, status, item) {
      if (confirm('This item status will change ?')) {
        RouteList.changeItem({
          type: type,
          code: code,
          status: status
        }).success(function (data, status, headers, config) {
          Messenger().post('Successfull');
        }).error(function (data) {
          alert('Error');
        });
      } else {
        item[type] = !status;
        $scope.route.worker_id = 0;
      }
    }

    $scope.readAll = function (type, id, field, route_id) {
      if (confirm('Are you sure ?')) {
        RouteList.readAll({
          type: type,
          id: id,
          field: field
        }).success(function (data, status, headers, config) {
          Messenger().post('Successfull');
          RouteList.get(route_id).success(function (data, status, headers, config) {
            $scope.route = data.route;
            $scope.oldRoute = data.route;
            $scope.steps = data.steps;
          });
        }).error(function (data) {
          alert('Error');
        });
      } else {
        item[type] = !status;
        $scope.route.worker_id = 0;
      }
    }

    $scope.changeStatus = function (order, oldStatus) {
      if (confirm('This order status will change ?')) {
        Order.changeWorkStatus(order).success(function (data, status, headers, config) {
          Messenger().post('Successfull');
        });
      } else {
        order.status = oldStatus;
      }
    }

    $scope.changeArrived = function (order, oldArrived) {
      if (confirm('This order arrived will change ?')) {
        Order.changeArrived({ order_id: order.id, arrived: order.arrived }).success(function (data, status, headers, config) {
          Messenger().post('Successfull');
        });
      } else {
        order.arrived = oldArrived;
      }
    }

    $scope.filterData = {
      station_id: [],
      price: '',
      vehicle: [],
      workers: [],
      page: 1,
      date: {
        startDate: null,
        endDate: null
      }
    }

    $scope.clearFilter = function (page) {
      $scope.filterData = {
        station_id: [],
        price: '',
        vehicle: [],
        workers: [],
        page: 1,
        date: {
          startDate: null,
          endDate: null
        }
      }
      $('.selectpicker').selectpicker('val', []);
      $scope.all();
    }

    $scope.$on('refresh_data', function () {
      console.log('refreshing map...')
      $scope.all();
    }, true);

    $scope.all = function () {
      if ($routeParams.worker_id) {
        $scope.filterData.workers.push($routeParams.worker_id);
      }
      if ($routeParams.route_id) {
        $scope.openDetail($routeParams.route_id);
        // return true;
      }
      RouteList.all($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.$on('new_data', function () {
      $scope.new();
      $('#newRoute').modal('show');
    }, true);

    $scope.$on('edit_data', function () {
      $scope.editWorker(data);
      $('#newRoute').modal('show');
    }, true);

    $scope.editRoute = function (route) {
      $rootScope.route = route;
      $rootScope.route.date = moment(route.scheduled_start).format('YYYY-MM-DD');
      $rootScope.route.scheduled_start = moment(route.scheduled_start).format('hh:mm');
      $rootScope.route.scheduled_end = moment(route.scheduled_end).format('hh:mm');
      $('#newRoute').modal('show');
      $('#routesDetail').modal('hide');
      RouteList.new().success(function (data, status, headers, config) {
        $rootScope.stations = data.stations;
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
        jQuery('#start').datetimepicker({
          format: 'g:i A',
          formatTime: 'g:i A',
          datepicker: false,
          step: 30,
        });
        jQuery('#end').datetimepicker({
          format: 'g:i A',
          formatTime: 'g:i A',
          datepicker: false,
          step: 30,
        })
      });
    }

    $scope.getPage = function (page) {
      $scope.filterData.page = page;
      $scope.all();
    }

    $scope.get = function () {
      RouteList.get($routeParams.id).success(function (data, status, headers, config) {
        $scope.data = data;
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
        jQuery('#start').datetimepicker({
          format: 'g:i A',
          formatTime: 'g:i A',
          datepicker: false,
          step: 30,
        });
        jQuery('#end').datetimepicker({
          format: 'g:i A',
          formatTime: 'g:i A',
          datepicker: false,
          step: 30,
        });
      });
    }

    $scope.new = function () {
      RouteList.new().success(function (data, status, headers, config) {
        $rootScope.stations = data.stations;
        $scope.route = {
          scheduled_start: '4:30 AM',
          scheduled_end: '8:30 AM',
        }
        $rootScope.route = {
          scheduled_start: '4:30 AM',
          scheduled_end: '8:30 AM',
        }
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
        jQuery('#start').datetimepicker({
          format: 'g:i A',
          formatTime: 'g:i A',
          datepicker: false,
          step: 30,
        });
        jQuery('#end').datetimepicker({
          format: 'g:i A',
          formatTime: 'g:i A',
          datepicker: false,
          step: 30,
        })
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        RouteList.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.routes.data.indexOf($filter('filter')($scope.data.routes.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.routes.data.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $rootScope.saveRoute = function (route) {
      if (route.id) {
        $scope.update();
      } else {
        RouteList.save(route).success(function (data, status, headers, config) {
          $scope.data = data;
          setTimeout(() => {
            $scope.$apply()
          }, 300);
          Messenger().post(data.message);
          $('#newRoute').modal('hide');
          $scope.$broadcast('refresh_data');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.update = function () {
      RouteList.update($scope.route).success(function (data, status, headers, config) {
        $scope.data = data;
        $scope.openDetail(data.data)
        Messenger().post(data.message);
        $('#newRoute').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    // if ($routeParams.route_id) {
    //   const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
    //   console.log('interval_id: ', interval_id);
    //   for (let i = 1; i < interval_id; i++) {
    //     window.clearInterval(i);
    //   }
    //   liveInterval = setInterval(() => {
    //     $scope.openDetail($routeParams.route_id)
    //   }, 12000);
    //   localStorage.setItem('liveInterval', liveInterval);
    // }

    $scope.openDetail = function (route, tab) {
      if (tab) {
        var url = 'routes?route_id=' + route.id;
        if ($scope.filterData.term) {
          url += '&term=' + $scope.filterData.term;
        }
        if ($scope.filterData.qr) {
          url += '&qr=' + $scope.filterData.qr;
        }
        window.open(url, '_blank');
        return true;
      }
      RouteList.get(route && route.id ? route.id : route).success(function (data, status, headers, config) {
        $scope.route = data.route;
        $scope.workers = data.workers;
        $rootScope.workers = data.workers;
        $scope.oldRoute = data.route;
        $scope.steps = data.steps;
        $('#routesDetail').modal('show');
        $('.rtstops').click();

        var bounds = new google.maps.LatLngBounds();
        if (!$scope.map) {
          $scope.map = new google.maps.Map(document.getElementById('routeMap'), {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            rotateControl: true,
            scaleControl: true,
            center: new google.maps.LatLng(Number(42.11), Number(27.11)),
            zoom: 13,
            styles: [{
              "elementType": "geometry",
              "stylers": [{
                "color": "#f5f5f5"
              }]
            },
            {
              "elementType": "labels.icon",
              "stylers": [{
                "visibility": "off"
              }]
            },
            {
              "elementType": "labels.text.fill",
              "stylers": [{
                "color": "#616161"
              }]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [{
                "color": "#f5f5f5"
              }]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels.text.fill",
              "stylers": [{
                "color": "#bdbdbd"
              }]
            },
            {
              "featureType": "landscape.natural",
              "elementType": "geometry.stroke",
              "stylers": [{
                "color": "#4247bd"
              }]
            },
            {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [{
                "color": "#eeeeee"
              }]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.fill",
              "stylers": [{
                "color": "#757575"
              }]
            },
            {
              "featureType": "poi.business",
              "stylers": [{
                "visibility": "off"
              }]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [{
                "color": "#e5e5e5"
              }]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text",
              "stylers": [{
                "visibility": "off"
              }]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text.fill",
              "stylers": [{
                "color": "#9e9e9e"
              }]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [{
                "color": "#ffffff"
              }]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.text.fill",
              "stylers": [{
                "color": "#757575"
              }]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [{
                "color": "#dadada"
              }]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels.text.fill",
              "stylers": [{
                "color": "#616161"
              }]
            },
            {
              "featureType": "road.local",
              "elementType": "labels.text.fill",
              "stylers": [{
                "color": "#9e9e9e"
              }]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry",
              "stylers": [{
                "color": "#e5e5e5"
              }]
            },
            {
              "featureType": "transit.station",
              "elementType": "geometry",
              "stylers": [{
                "color": "#eeeeee"
              }]
            },
            {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [{
                "color": "#c9c9c9"
              }]
            },
            {
              "featureType": "water",
              "elementType": "geometry.fill",
              "stylers": [{
                "color": "#bdd1e8"
              }]
            },
            {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [{
                "color": "#9e9e9e"
              }]
            }
            ]
          });
        }
        $scope.orderMarkers = [];
        $scope.route.orders.forEach(element => {
          console.log($scope.orderMarkers[element.id])
          if (typeof $scope.orderMarkers[element.id] === 'undefined') {
            $scope.createOrderMarker(element);
            bounds.extend($scope.orderMarkers[element.id].getPosition());
          }
        });

        if ($scope.route.worker) {
          $scope.createWorkerMarker($scope.route.worker);
        }

        if (!$scope.stationMarker) {
          $scope.stationMarker = new SlidingMarker({
            position: new google.maps.LatLng(
              Number($scope.route.station.latitude),
              Number($scope.route.station.longitude)
            ),
            map: $scope.map,
            icon: {
              url: "/img/map-pin-red.svg",
              scaledSize: new google.maps.Size(48, 48),
            },
            title: "station_location",
            visible: true
          });
          var content = '<div id="iw-container">' +
            '<div class="iw-content"> ' +
            '<strong>#' + $scope.route.station.name + '</strong></div></div>';
          var infowindow = new google.maps.InfoWindow({
            content: content,
            maxWidth: 100
          });
          $scope.stationMarker.addListener('click', function () {
            infowindow.open($scope.map, $scope.stationMarker);
          });
          var label = {
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '10px',
            text: $scope.route.station.name
          };
          $scope.stationMarker.setLabel(label);
          bounds.extend($scope.stationMarker.getPosition());
        }

        $scope.map.fitBounds(bounds);
        if (!$scope.directionsService) {
          renderRoute($scope.route, '#FF6633');
        }
        setTimeout(() => {
          google.maps.event.trigger($scope.map, 'resize');
        }, 1000);

        $scope.searchTerms = '';
        $scope.searchIdTerms = '';
        if ($routeParams.term) {
          $scope.searchIdTerms = $routeParams.term;
        }
        if ($routeParams.qr) {
          $scope.searchTerms = $routeParams.qr;
        }
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    function renderRoute(data, color) {
      var waypts = [];
      if (data.orders.length == 0) {
        return false;
      }
      data.orders.forEach((element, index) => {
        var lat = element.location.latitude;
        var lng = element.location.longitude;
        waypts.push({
          location: {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
          },
          stopover: true
        });

        var label = {
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '14px',
          text: (index + 1).toString()
        };
        $scope.orderMarkers[element.id].setLabel(label);
        // data.orders[element].newSort = index;
      });

      $scope.directionsService = new google.maps.DirectionsService();
      $scope.directionsDisplay = new google.maps.DirectionsRenderer({
        map: $scope.map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#19BFCA',
          strokeOpacity: 1.0,
          strokeWeight: 6
        }
      });
      var request = {
        origin: {
          lat: parseFloat(data.station.latitude),
          lng: parseFloat(data.station.longitude)
        },
        destination: {
          lat: parseFloat(data.orders[0].location.latitude),
          lng: parseFloat(data.orders[0].location.longitude)
        },
        waypoints: waypts,
        travelMode: 'DRIVING',
        optimizeWaypoints: false,
        provideRouteAlternatives: false,
      };

      var markers = [];
      $scope.directionsService.route(request, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          $scope.directionsDisplay.setDirections(response);
          if (response.routes.length == 0) {
            return true;
          }
          var my_route = response.routes[0];
          var totalDistance = 0;
          var totalDuration = 0;
          // console.log(my_route)
          for (var i = 0; i < my_route.legs.length; i++) {
            // console.log(my_route)
            totalDistance += my_route.legs[i].distance.value;
            totalDuration += my_route.legs[i].duration.value;
            // totalDuration += (data.stop_time * 60)
            // var label = { color: '#333', fontWeight: 'bold', fontSize: '16px', text: 123 }; 
            // marker.setLabel(label);
            // console.log(my_route.legs[i])
            // markers[i] = new SlidingMarker({
            //   position: my_route.legs[i].start_location,
            //   icon: pinSymbol(color),
            //   label: {
            //     text: "" + (i + 1),
            //     color: "white"
            //   },
            //   map: $scope.map,
            //   visible : true
            // });
            // google.maps.event.addListener(markers[i], 'click', function () {
            //   $scope.selectedMarkers.push(markers[i])
            //   console.log(markers[i])
            //   markers[i].setIcon('https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=%7C333333');
            // });

          }
          // my_route.waypoint_order.forEach((element, index) => {
          //   var label = {
          //     color: '#fff',
          //     fontWeight: 'bold',
          //     fontSize: '14px',
          //     text: (index + 1).toString()
          //   };
          //   $scope.orderMarkers[data.orders[element].id].setLabel(label);
          //   data.orders[element].newSort = index;
          // });
          data.distance = (parseFloat(totalDistance) / 1610).toFixed(2);
          var totalRouteTime = totalDuration * 1000; // ms
          var totalStopTime = data.stop_time * 60 * 1000 * (data.order_count - 1); // ms
          var totalTime = Number(totalRouteTime) + Number(totalStopTime);
          data.time = $scope.humanizeDuration(totalTime);
          setTimeout(() => {
            $scope.$apply();
          }, 300);
        }
      });
    }

    function pinSymbol(order) {
      if (order.status && order.status.value == 'complete') {
        return {
          url: "/img/pin.svg",
          scaledSize: new google.maps.Size(36, 36),
        };
      } else {
        return {
          url: "/img/map-pin-orange.svg",
          scaledSize: new google.maps.Size(36, 36),
        };
      }
    }

    $scope.createOrderMarker = function (order) {
      if ($scope.orderMarkers.indexOf(order.id) > -1) {
        $scope.orderMarkers[order.id].setIcon(icon);
      } else if (order.location) {
        var location = new google.maps.LatLng(
          Number(order.location.latitude),
          Number(order.location.longitude)
        );
        if (order.status && order.status.value == 'complete') {
          var pinImage = {
            url: "/img/pin.svg",
            scaledSize: new google.maps.Size(36, 36),
          };
        } else if (order.status && (order.status.value == 'fail' || order.status.value == 'cancel' || order.status.value == 'failed')) {
          var pinImage = {
            url: "/img/map-pin-red.svg",
            scaledSize: new google.maps.Size(36, 36),
          };
        } else {
          var pinImage = {
            url: "/img/map-pin-orange.svg",
            scaledSize: new google.maps.Size(36, 36),
          };
        }
        var marker = new SlidingMarker({
          position: location,
          map: $scope.map,
          icon: pinImage,
          title: "order_location",
          visible: true
        });
        // marker.addListener('click', function () {
        //   console.log(order.id)
        //   // $scope.getOrderDetails(order.id)
        // });

        var content = '<div id="iw-container">' +
          '<div class="iw-content"> ' +
          '<strong>#' + order.full_unique_id + '</strong>';

        if (order.name && order.name != null)
          content += ' - ' + order.name;

        if (order.special_value)
          content += ' - <strong>' + order.special_value + '</strong>';

        content += '<hr style="margin:17px 0">' +
          '<p><strong>Partner</strong> : ' + order.customer.customer_name + ' </p>' +
          '<p><strong>Customer </strong>: ' + order.location.name + ' </p>' +
          '<p><strong>Address </strong>:' + order.location.address + '</p>' +
          '<div class="row"><div class="col-md-6 pull-left">' +
          '<a href="#" onclick="angular.element(this).scope().zoomIn(' + order.location.latitude + ',' + order.location.longitude + ')" class="hod-button blue btn-xs">Zoom In</a> </div>' +
          '<div class="col-md-6 pull-left"><a href="#" onclick="angular.element(this).scope().getOrderDetails(' + order.id + ')" class="hod-button blue btn-xs pull-right">Details</a> </div></div>'

        if (order.worker)
          content += '<p><strong>Driver : </strong> ' + order.worker.full_name + '</p>';
        content += '</div></div>';
        var infowindow = new google.maps.InfoWindow({
          content: content,
          maxWidth: 360
        });
        marker.addListener('click', function () {
          infowindow.open($scope.map, marker);
        });
        // marker.setOpacity(0);
        $scope.orderMarkers[order.id] = marker;
      }
    }

    $scope.createWorkerMarker = function (worker) {
      if (typeof $scope.workerMarker !== 'undefined') {
        var location = new google.maps.LatLng(Number(worker.lat), Number(worker.lon));
        $scope.workerMarker.setDuration(1000);
        $scope.workerMarker.setEasing("swing");
        $scope.workerMarker.setPosition(location);
        infoWindow(worker);
      } else {
        if (worker.lat.length == 0) return false;

        var marker = new SlidingMarker({
          position: new google.maps.LatLng(Number(worker.lat), Number(worker.lon)),
          map: $scope.map,
          icon: {
            url: "/img/map-pin-green.svg",
            scaledSize: new google.maps.Size(48, 48),
          },
          label: {
            text: worker.full_name.match(/\b(\w)/g).join(''),
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '13px',
          },
          title: 'location',
          visible: true,
          optimized: false
        });
        $scope.workerMarker = marker;
        infoWindow(worker);
      }
    }

    $scope.$on('location_update', function (event, data, nextParams, fromState) {
      if ($scope.route.worker) {
        if (data.worker.id == $scope.route.worker.id) {
          $scope.route.worker = data.worker
          $scope.createWorkerMarker(data.worker);
        }
      }
    });

    function infoWindow(data) {
      if ($scope.workerMarker) {

        try {
          var extra = angular.fromJson(data.location_info);
        } catch (error) {
          var extra = {
            accuracy: '-',
            timestamp: '-',
          };
        }
        var content = '<div id="iw-container">' +
          '<div class="iw-content">' +
          '<div class="iw-subTitle" style="display: contents;">' + data.full_name + '</div>' +
          '<img src="https://s3.us-west-2.amazonaws.com/hodxpress/' + data.profile_photo + '" class="img-circle" height="44" width="44">' +
          // '<p><strong> Şarj : </strong>'+Math.round(extra.battery * 100 )+' %</p>' +
          '<p><strong> Accuary : </strong>' + Math.round(extra.accuracy) + ' Meter</p>' +
          '<p><strong> Last Location Update : </strong>' + moment(extra.timestamp).format('D MMM, HH:mm') + '</p>' +
          // '<hr style="margin:17px 0">' +
          // '<div class="row"><div class="col-md-6 pull-left">' +
          // '<a href="#" onclick="angular.element(this).scope().zoomIn(' + data.lat + ',' + data.lon + ')" class="btn btn-xs btn-warning">Zoom In</a> </div>' +
          // '<div class="col-md-6 pull-left"><a href="#" onclick="angular.element(this).scope().getWorkerDetails(' + data.id + ')" class="btn btn-xs btn-primary">Details</a> </div>'
          '</div></div></div>'
        var infowindow = new google.maps.InfoWindow({
          content: content,
          maxWidth: 360
        });

        if (typeof $scope.workerInfoWindow === 'undefined') {
          $scope.workerInfoWindow = infowindow;
          $scope.workerMarker.addListener('click', function () {
            $scope.workerInfoWindow.open($scope.map, $scope.workerMarker);
          });
        } else {
          $scope.workerInfoWindow.setContent(content);
        }

      }
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);