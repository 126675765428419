teleskop.controller('BillingController', ['$scope', 'Billing', '$location', '$routeParams', '$filter',
  function ($scope, Billing, $location, $routeParams, $filter) {
    $scope.deleted = [];

    $scope.selectAll = function (select) {
      if (select) {
        $scope.deleted = [];
        for (let i = 0; i < $scope.data.invoices.data.length; i++) {
          $scope.deleted.push($scope.data.invoices.data[i].id);
        }
      } else {
        $scope.deleted = [];
      }
    };

    $scope.paymentTypes = [{
      name: 'Debt',
      id: 0
    },
    {
      name: 'Credit',
      id: 1
    }
    ];

    $scope.filterData = {
      station_id: [],
      price: '',
      vehicle: [],
      page: 1,
      date: {
        startDate: null,
        endDate: null
      }
    }

    $scope.clearFilter = function (page) {
      $scope.filterData = {
        station_id: [],
        price: '',
        vehicle: [],
        page: 1,
        date: {
          startDate: null,
          endDate: null
        }
      }
      $scope.all();
    }

    $scope.$on('refresh_data', function () {
      console.log('refreshing map...')
      $scope.all();
    }, true);

    $scope.$on('new_data', function () {
      $scope.invoice = {
        invoice_type: 'order_based',
        subInvoices: []
      }
      $scope.new();
      $('#invoiceDetail').modal('hide');
      $('#newInvoice').modal('show');
    }, true);

    $scope.editInvoice = function (page) {
      $scope.new();
      $('#invoiceDetail').modal('hide');
      $('#newInvoice').modal('show');
    }

    $scope.getPage = function (page) {
      $scope.filterData.page = page;
      $scope.all();
    }

    $scope.invoiceTypes = [
      {
        name: 'Order Based',
        id: 'order_based'
      },
      {
        name: 'Comission Based',
        id: 'comission_based'
      }
    ]

    $scope.all = function (type) {
      Billing.all($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;

        if (type && type == 'open') {
          var tempInvoice = $scope.data.invoices.data.find(function (element) {
            return element.id == $scope.invoice.id;
          })
          $scope.openDetail(tempInvoice);
        }
      });
    }

    $scope.get = function () {
      Billing.get($routeParams.id).success(function (data, status, headers, config) {
        $scope.data = data;
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
      });
    }

    $scope.new = function () {
      Billing.new().success(function (data, status, headers, config) {
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Billing.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.invoices.data.indexOf($filter('filter')($scope.data.invoices.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.invoices.data.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.save = function () {
      if ($scope.invoice.id) {
        $scope.update();
      } else {
        Billing.save($scope.invoice).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post(data.message);
          $('#newInvoice').modal('hide');
          $scope.all();
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.update = function () {
      Billing.update($scope.invoice).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post(data.message);
        $('#newInvoice').modal('hide');
        $scope.all('open');

      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.openDetail = function (data) {
      $scope.invoice = data;
      $('#invoiceDetail').modal('show');
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };
    $scope.invoice = {
      count: 3,
      invoices: []
    }
    jQuery('#date').datetimepicker({
      timepicker: false,
      datepicker: true,
      format: 'Y-m-d'
    });

    $scope.showCompletedOrders = function (invoice) {
      var data = {
        invoice_id: invoice.id,
        scheduled_time: {
          startDate: moment(invoice.date).startOf('day'),
          endDate: moment(invoice.date).endOf('day'),
        },
        // workSteps: [$scope.data.completed.id.toString()]
      }
      var url = 'orders?data=' + JSON.stringify(data);
      window.open(url, '_blank');
    }

    $scope.openPayment = function (payment) {
      $scope.payment = payment;
      jQuery('#payment_date').datetimepicker({
        timepicker: false,
        datepicker: true,
        format: 'Y-m-d'
      });
      $('#invoiceDetail').modal('hide');
      $('#newInvoicePayment').modal('show');
    }

    $scope.newPayment = function () {
      $scope.payment = {};
      jQuery('#payment_date').datetimepicker({
        timepicker: false,
        datepicker: true,
        format: 'Y-m-d'
      });
      $('#invoiceDetail').modal('hide');
      $('#newInvoicePayment').modal('show');
    }

    $scope.addPayment = function () {
      if ($scope.payment.id) {
        $scope.updatePayment();
      } else {
        $scope.payment.invoice_id = $scope.invoice.id;
        $scope.payment.price = $scope.payment.rate * $scope.payment.qty;
        Billing.addPayment($scope.payment).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post(data.message);
          $('#newInvoicePayment').modal('hide');
          $scope.all('open');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.updatePayment = function () {
      $scope.payment.price = $scope.payment.rate * $scope.payment.qty;
      Billing.updatePayment($scope.payment).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post(data.message);
        $('#newInvoicePayment').modal('hide');
        $scope.all('open');
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }


    $scope.deletePayment = function (payment) {
      if (confirm('This payment will be deleted. do you confirm?')) {
        Billing.deletePayment(payment.id).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $scope.all('open');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.getPrice = function (payment) {
      try {
        $scope.payment.price = $scope.payment.rate * $scope.payment.qty;
      } catch (error) {
      }
    }

    $scope.getComissionRate = function () {
      try {
        if ($scope.invoice.invoice_type == 'comission_based') {
          var customer = $scope.data.customers.find(i => i.id == $scope.invoice.company_customer_id);
          $scope.invoice.qty = customer.comission_rate;
        }
      } catch (error) {
      }
    }

    $scope.getTotalNumber = function (elem, index) {
      try {
        if ($scope.invoice.invoice_type == 'comission_based') {
          $scope.invoice.total = Number($scope.invoice.price) * (Number($scope.invoice.qty) / 100);
          
          if(elem) {
            elem.total = Number(elem.price) * (Number(elem.qty) / 100);
          }
        }
      } catch (error) {
      }
    }


    $scope.addItem = function () {
      $scope.invoice.subInvoices.push({
        qty: $scope.invoice.qty,
      });
    }

    $scope.removeItem = function (index) {
      $scope.invoice.subInvoices.splice(index, 1);
    }

    $scope.changeStatus = function (status) {
      Billing.changeStatus({ status: status, id: $scope.invoice.id }).success(function (data, status, headers, config) {
        $scope.invoice.status = status;
        Messenger().post(data.message);
        $scope.all('open');
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

  }
]);