teleskop.controller('CustomerHistoryController', ['$scope', 'Order', 'CustomerOrder', '$location', '$filter', '$routeParams', '$rootScope', 'Analytic', 'ngAudio',
    function ($scope, Order, CustomerOrder, $location, $filter, $routeParams, $rootScope, Analytic, ngAudio) {

        $scope.filterProp = [];
        $scope.selectedItems = [];
        $scope.isEmpty = false;
        var liveInterval = null;

		var newOrderSound = new Audio('sounds/new-order.mp3');

        $scope.selectAllOrder = function (select) {
            if (select) {
                // $scope.selectedItems = [];
                // for (let i = 0; i < $scope.data.orders.data.length; i++) {
                //     if (!$scope.data.orders.data[i].status) {
                //         // if ($scope.data.orders.data[i].status.id !== $scope.data.success.id && $scope.data.orders.data[i].status.id !== $scope.data.fail.id) {
                //             $scope.selectedItems.push($scope.data.orders.data[i].id);
                //         // }
                //     } else {
                //         // $scope.selectedItems.push($scope.data.orders.data[i].id);
                //     }
                // }
                $scope.filterData.selectall = true;
                $scope.customerOrders()

            } else {
                $scope.selectedItems = [];
            }
        };

        $scope.selectAllOrderCheck = function (select) {
            if (select) {
                $scope.selectedItems = [];
                for (let i = 0; i < $scope.data.orders.data.length; i++) {
                    console.log($scope.data.orders.data[i])
                    $scope.selectedItems.push($scope.data.orders.data[i].id);
                }
            } else {
                $scope.selectedItems = [];
            }
        };

        $scope.addSelectedItem = function (item) {
            if ($scope.selectedItems.indexOf(item) > -1) {
                $scope.selectedItems.splice($scope.selectedItems.indexOf(item), 1);
            } else {
                $scope.selectedItems.push(item);
            }
        };

        $scope.checklistOpts = {
            autoApply: false,
            minDate: moment(),
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Done",
                cancelLabel: "",
                fromLabel: "From",
                toLabel: "/",
                format: "MM/DD/YY",
                customRangeLabel: 'Custom Range'
            },
            ranges: {
                // 'All': [moment().subtract(2, 'years'), moment().add(2, 'years')],
                'Today': [moment(), moment()],
                'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')]
            }
        }

        $scope.opts = {
            autoApply: false,
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Done",
                cancelLabel: "",
                fromLabel: "From",
                toLabel: "/",
                format: "MM/DD/YY",
                customRangeLabel: 'Custom Range'
            },
            ranges: {
                // 'All': [],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Today': [moment(), moment()],
                'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')]
            }
        }

        $scope.filterData = {
            customers: [],
            workers: [],
            workSteps: [],
            page: 1,
            paginate: 25,
            stations: [],
            created_at: {
                startDate: null,
                endDate: null
            },
            scheduled_time: {
                startDate: null,
                endDate: null
            }
        }

        $scope.addFilterProps = function (item) {
            if ($scope.filterProp.indexOf(item) === -1)
                $scope.filterProp.push(item);
            else {
                var index = $scope.filterProp.indexOf(item);
                $scope.filterProp.splice(index, 1);
                if (item != 'term') {
                    $scope.filterData[item] = [];
                } else {
                    $scope.filterData[item] = "";
                }
                if (item == 'date') {
                    delete $scope.filterData.startDate;
                    delete $scope.filterData.endDate;
                }
                if ($scope.filterProp.length == 0) {
                    $scope.filter('clear');
                }
            }
        }

        $scope.getPage = function (page) {
            $scope.filterData.page = page;
            $scope.filter();
        }

        $scope.sort = function (field, sort) {
            $scope.filterData.field = field;
            $scope.filterData.sort = sort;
            $scope.filter();
        }

        $scope.filter = function (type) {
            if (type == 'clear') {
                $scope.filterData = {
                    customers: [],
                    workers: [],
                    workSteps: [],
                    paginate: 25,
                    page: 1,
                    created_at: {
                        startDate: null,
                        endDate: null
                    },
                    scheduled_time: {
                        startDate: null,
                        endDate: null
                    }
                }
                $scope.filterProp = [];
            } else {
                $scope.filterData.filterProp = $scope.filterProp;
                if (typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
                    $scope.filterData.filterProp.created_at = 'created_at';
                }
                if (typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
                    $scope.filterData.filterProp.scheduled_time = 'scheduled_time';
                }
            }
            $scope.customerOrders();
        }

        $scope.clearFilter = function (page) {
            $scope.filterData = {
                workers: [],
                workSteps: [],
                paginate: 25,
                page: 1,
                created_at: {
                    startDate: null,
                    endDate: null
                },
                scheduled_time: {
                    startDate: null,
                    endDate: null
                }
            }
            $location.search('data', null)
            $scope.filterProp = [];
            $scope.customerOrders('map');
        }

        $scope.$on('refresh_data', function () {
            console.log('refreshing map...')
            $scope.customerOrders();
        }, true);

        $scope.searchOrder = function (term) {
            $scope.filterData.term = term;
            $scope.customerOrders();
        }

        $scope.exportCustomerExcel = function () {
            $scope.filterData.export = true;
            CustomerOrder.customerOrders($scope.filterData).success(function (data, status, headers, config) {
                window.location.href = data['url'];
                delete $scope.filterData.export;
            });

            // Order.generateExcelReport($scope.filterData).success(function(data, status, headers, config) {
            //     setTimeout(function(){
            //         Order.removeDownloadFile({path: data['path']}).success(function(response, status, headers, config) { 
            //             $scope.selectedItem = [];
            //         });
            //     },2000)

            // });
        };

        $scope.initPrinter = function () {
            qz.security.setCertificatePromise(function (resolve, reject) {
                resolve("-----BEGIN CERTIFICATE-----\n" +
                "MIIFHDCCAwSgAwIBAgIQNzkyMDI0MDQwNTE0MjgxNzANBgkqhkiG9w0BAQsFADCB\n" +
                "mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry\n" +
                "aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ\n" +
                "cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz\n" +
                "dHJpZXMuY29tMB4XDTI0MDQwNTE0MjgxN1oXDTI1MDQwNTIxNDYwN1owgdcxCzAJ\n" +
                "BgNVBAYMAlVTMRMwEQYDVQQIDApOZXcgSmVyc2V5MRIwEAYDVQQHDAlGYWlyIExh\n" +
                "d24xEjAQBgNVBAoMCUhPRFhwcmVzczESMBAGA1UECwwJSE9EWHByZXNzMRIwEAYD\n" +
                "VQQDDAlIT0RYcHJlc3MxJTAjBgkqhkiG9w0BCQEMFnNhdmFzLm1hemljaUBnbWFp\n" +
                "bC5jb20xPDA6BgNVBA0MM3JlbmV3YWwtb2YtZjQyZmQ1NjI1MmMzNGJiNGJhZDNi\n" +
                "NzRlOTA2ODBmYzA4N2Y5NjE2OTCCASAwCwYJKoZIhvcNAQEBA4IBDwAwggEKAoIB\n" +
                "AQDUFkQYDrqsm1NUY4AuV/kb8lRYhNSQ6UfTDf90VAMVLGyO9ShzwJ/dSMWT6SyI\n" +
                "mrIoZVVeCaWU1YJtP4ym76KEoi0ZiWwW2GG4+DUY0ogdT+dsgAvLHIhef+WtA2rN\n" +
                "SemuoblMcaGEAPFNlbErVoQj2Aa7d6/a+nmmaF02NC6Jmf//Zk457WzNfFhzMQMA\n" +
                "a8SmaFt0oZ1PuLfzTyi4m8IQuEHWIMXGYl4d8fbSPeSW8Wt9i+IsO1fuy5pVZ0Ou\n" +
                "tzVDYKwthIc2keLpugnk3cGidacQI+y1Icfx+Uy9lRaGTaDEgvX3yDacsYQdZarq\n" +
                "0mxAV/nX+erQ/c49IbGm+eR3AgMBAAGjIzAhMB8GA1UdIwQYMBaAFJCmULeE1Lnq\n" +
                "X/IFhBN4ReipdVRcMA0GCSqGSIb3DQEBCwUAA4ICAQAw1HulYbyYW53lnihQXSDc\n" +
                "PvxYBhs1xbvUU2MAMqczrj6x+yVNFXB9oImWuFfR29EjCu5AY74MzgRwlPX8gVLc\n" +
                "uTr35tlCPdbSPPbuQz41SFSid9r0dDU73EZnwC1KaMKKnz/DslKEvZyx7LAr76az\n" +
                "VH43oOnQHTzPSfTaSONWLuOCVEq9xt6EAqDNKcIIo5XgdHaCt+6S/YUnlT8dWtum\n" +
                "0jj2QGwp/xdzhHEdlq7GVWvRVYojSMXyMlroCbQOiZah9kzYCIYhwYWHbIITXY0d\n" +
                "Bbtgy0ZNVDHwhoQj7qL3v/NisO6cYStvBLscXQLuAIskjpmRGxwncpYhUzC9ybRE\n" +
                "Lkwy8GoHJYk2w6znFAYD8PHjMMA3/EIAUdyyS0tWEed2ufazAksOcN3dHm1ZJ8Ws\n" +
                "PHfafS4I0+XKkcuWNJxC5bsdgXgNtC5cSko9diqa6XfUPSiFrWuhFoI+ut27jWqT\n" +
                "nIMtweSiShZoHqM7e6nb4sMl8ls1NcH2yTRPg5u8rgG01GpF9c4bIEdAiAkE9ijn\n" +
                "yZZwz+slZAKnSUmtqNQ+zgsso1KHjQaTch/o4bBWvsxmjK/jEFOwRu8t1LXeeVT0\n" +
                "BR0ifqX0an8uqdIKH3K6vIe4494j8/dBcKydz3HdaJtchKrCKiuuLyK0/8rRW7wJ\n" +
                "M6zK5MFz6HMeLe6yYnAQqg==\n" +
                "-----END CERTIFICATE-----\n" +
                "--START INTERMEDIATE CERT--\n" +
                "-----BEGIN CERTIFICATE-----\n" +
                "MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT\n" +
                "MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ\n" +
                "bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG\n" +
                "A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx\n" +
                "emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow\n" +
                "gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0\n" +
                "cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM\n" +
                "EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1\n" +
                "c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU\n" +
                "iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H\n" +
                "YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM\n" +
                "GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH\n" +
                "EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh\n" +
                "0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX\n" +
                "xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1\n" +
                "9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE\n" +
                "ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD\n" +
                "2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX\n" +
                "6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY\n" +
                "5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ\n" +
                "plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S\n" +
                "BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ\n" +
                "eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k\n" +
                "gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT\n" +
                "x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu\n" +
                "pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw\n" +
                "2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp\n" +
                "rbO7BnjW\n" +
                "-----END CERTIFICATE-----");
            });
        }

        $scope.type = 'order';
        if ($location.path() == '/live-orders') {
            $scope.initPrinter();
            $scope.type = 'live';
            const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
            console.log('interval_id: ', interval_id);
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }
            liveInterval = setInterval(() => {
                $scope.customerOrders();
            }, 12000);
            localStorage.setItem('liveInterval', liveInterval);
        }

        if ($location.path() == '/customer-orders') {
            $scope.type = 'live_list';
            const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
            console.log('interval_id: ', interval_id);
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }
            liveInterval = setInterval(() => {
                $scope.customerOrders();
            }, 12000);
            localStorage.setItem('liveInterval', liveInterval);
        }

        $scope.$on('$destroy', function () {
            window.clearInterval(liveInterval);
            liveInterval = null
        });

        $scope.grouppedOrders = [];
        $scope.customerOrders = function () {
            if ($routeParams.invoice_id) {
                $scope.filterData.invoice_id = $routeParams.invoice_id;
            }
            if (IsJsonString($routeParams.data)) {
                filter = JSON.parse($routeParams.data);
                if (typeof filter == 'object') {
                    angular.merge($scope.filterData, filter);
                }
            }
            if ($scope.type == 'live') {
                $scope.filterData.paginate = 1000;
            }
            $scope.filterData.type = $scope.type;
            CustomerOrder.customerOrders($scope.filterData).success(function (data, status, headers, config) {
                if ($scope.filterData.selectall) {
                    $scope.selectedItems = data.orders;
                    delete $scope.filterData.selectall;
                    return true;
                }
                $scope.data = data;
                $scope.data.steps.unshift({
                    id: 0,
                    name: 'Created'
                });
                $scope.data.stations.unshift({
                    id: 0,
                    code: 'All'
                });

                $scope.cancelledOrders = data.cancelled_orders;
                setTimeout(() => {
                    $scope.$apply();
                }, 300);

                $scope.data.orders.data.forEach(element => {
                    $scope.getReaminingTime(element);
                });

                if ($scope.type == 'live') {
                    $scope.data.orders.data = $scope.data.orders.data.sort(function (a, b) {
                        return moment(a.scheduled_time) - moment(b.scheduled_time);
                    });

                    var groupData = [
                        {
                            name: 'Scheduled Orders',
                            show: $scope.grouppedOrders.find(i => i.name == 'Scheduled Orders') ?  $scope.grouppedOrders.find(i => i.name == 'Scheduled Orders').show : true,
                            data: $scope.data.orders.data.filter(i => moment(i.scheduled_time).diff(moment(), 'minutes') > 59)
                        },
                        {
                            name: 'In progress',
                            show: $scope.grouppedOrders.find(i => i.name == 'In progress') ?  $scope.grouppedOrders.find(i => i.name == 'In progress').show : true,
                            data: $scope.data.orders.data.filter(i => moment(i.scheduled_time).diff(moment(), 'minutes') < 60 && !i.status)
                        },
                        {
                            name: 'Ready for pickup',
                            show: $scope.grouppedOrders.find(i => i.name == 'Ready for pickup') ?  $scope.grouppedOrders.find(i => i.name == 'Ready for pickup').show : true,
                            data: $scope.data.orders.data.filter(i => moment(i.scheduled_time).diff(moment(), 'minutes') < 60 && i.status && i.status.value === 'prepared')
                        },
                        {
                            name: 'On Delivery',
                            show: $scope.grouppedOrders.find(i => i.name == 'On Delivery') ?  $scope.grouppedOrders.find(i => i.name == 'On Delivery').show : true,
                            data: $scope.data.orders.data.filter(i => moment(i.scheduled_time).diff(moment(), 'minutes') < 60 && i.status && i.status.id === 128)
                        }
                    ];
                    $scope.grouppedOrders = groupData;
                    $scope.printOrders(data.orders.data);
                }

                if (data.orders.data.length === 0) {
                    $scope.isEmpty = true;
                } else {
                    $scope.isEmpty = false;
                }
            });
        };
        
        $scope.changeGroupShowStatus = function (group) {
            group.show = !group.show;
        }

        $scope.getPickupCount = function (status) {
            return status.data.filter(function (i) {
                return (i.uber_order_map && i.uber_order_map.order_type === 'PICK_UP') || (i.imap_order_map && i.imap_order_map.order_type === 'PICK_UP')
            }).length
        }

        $scope.closeCancelled = function() {
            CustomerOrder.closeCancelledOrders().success(function (data, status, headers, config) {
                $scope.cancelledOrders = [];
            });
        }

        $scope.printOrders = function (orderData) {
            if (!orderData) {
                return;
            }

            newOrderSound.play();
            if (!qz.websocket.isActive()) {
                console.log('Bekleniyor...', 'default');

                qz.websocket.connect().then(function () {
                    console.log('Active', 'success');
                    $scope.printOrderData(orderData)
                }).catch(function (err) {
                    console.log('Bağlantı Hatası', err);
                    if (err.target != undefined) {
                        if (err.target.readyState >= 2) { //if CLOSING or CLOSED
                            console.log("QZ bağlantısı sonlandırıldı.");
                        } else {
                            console.log("Bağlantı sağlanırken bir hata oluştu.");
                            console.error(err);
                        }
                    } else {
                        console.log(err);
                    }
                });
            } else {
                console.log('Bağlantı aktif.', 'alert-warning');
                console.log('Active', 'success');
                $scope.printOrderData(orderData)
            }
        };

        $scope.printOrderData = function (orders) {
            var ids = [];
            console.log(orders);
            orders.forEach(element => {
                if (element.print_status === 'not_print') {
                    ids.push(element.id);
                }
            });
            if (ids.length > 0) {
                CustomerOrder.printZpl(ids).success(function (data, status, headers, config) {
                    if (!localStorage.getItem("printerName")) {
                        // alert('Please set printer!');
                        return true;
                    }

                    var config = qz.configs.create(localStorage.getItem("printerName"));
                    var zpls = [];

                    data.zpls.forEach(element => {
                        zpls.push(element.replace(/\/\//g, "/"))
                    });
                    console.log(zpls);
                    qz.print(config, zpls).then(function () {
                        console.log("Sent data to printer");
                        CustomerOrder.updatePrintStatus(ids).success(function (data, status, headers, config) {
                            console.log('success')
                        });
                    }).catch(function (err) {
                        console.log('Bağlantı Hatası', err);
                    });
                });
            } else {
                console.log('order not found');
            }
        }

        $scope.getReaminingTime = function (item) {
            if (!item.estimated_delivery && item.status && item.status.id == 128) {
                // CustomerOrder.getReaminingTime(item.id).success(function (data, status, headers, config) {
                //     item.estimated_delivery = data.estimated_delivery;
                // });
            }
        };

        $scope.removeNotDelivery = function () {
            CustomerOrder.removeNotDelivery().success(function (data, status, headers, config) {
                $scope.data.not_delivery_zone = null;
            });
        };

        $scope.downloadZip = function () {
            Order.generateTotalOrdersReportInZip({
                orders: $scope.selectedItems
            }).success(function (data, status, headers, config) {
                window.location.href = data['url'];
                setTimeout(function () {
                    Order.removeDownloadFile({
                        path: data['path']
                    }).success(function (response, status, headers, config) {
                        $scope.selectedItems = [];
                    });
                }, 2000)
            });
        };

        $scope.changeStatus = function (status, id) {
            // if (confirm('Are you sure you want to do?')) {
            Order.changeStatus({
                order_id: id,
                status: status
            }).success(function (data, status, headers, config) {
                $scope.customerOrders();
                // Messenger().post('Successful');
            });
            // }
        };

        $scope.changePackageType = function (package_type, id) {
            // if (confirm('Are you sure you want to do?')) {
            Order.changePackageType({
                order_id: id,
                package_type: package_type
            }).success(function (data, status, headers, config) {
                $scope.customerOrders();
            });
            // }
        };

        $scope.changeStatusMulti = async function (status, ids) {
            // if (confirm('Are you sure you want to do?')) {
            ids.forEach(async element => {
                try {
                    const a = await Order.changeStatus({
                        order_id: element,
                        status: status
                    });
                } catch (error) {
                    console.log(error)
                }
            });
            $scope.customerOrders();
            // Messenger().post('Successful');
            $scope.selectedItems = [];
            // }
        };

        $scope.deleteOrders = function (send_cancel_sms) {
            Order.deleteOrders({
                orders: $scope.selectedItems,
                send_cancel_sms: send_cancel_sms
            }).success(function (response, status, headers, config) {
                $scope.customerOrders();
                $scope.selectedItems = [];
                $('#deleteOrdersModal').modal('toggle');
            });
        };

        $scope.getCustomerOrderDetails = function (work) {
            $rootScope.loadingWork = true;
            var id = work.id;
            if (typeof id == 'undefined') {
                var id = work;
            }
            CustomerOrder.customerOrder(id).success(function (data, status, headers, config) {
                $rootScope.loadingWork = false;
                $rootScope.selectedWork = data.order;
                setTimeout(() => {
                    $scope.$apply();
                }, 300);
                $('#orderDetail').modal('show');
            });
        }

        $scope.report = function (type) {
            Analytic.customerOrders($scope.filterData).success(function (data, status, headers, config) {
                $scope.data = data;
                $scope.series = ['Completed', 'Pending', 'Failed', 'Total'];
                $scope.chartColors = [
                    '#ff9235',
                    '#4a5157',
                    '#c93b2b',
                    '#2bbfc9'
                ];
                $scope.barChartData = [
                    data.chart.count.success,
                    data.chart.count.other,
                    data.chart.count.fail,
                    data.chart.count.total
                ];
                $scope.chartData = [data.count.success, data.count.other, data.count.fail, data.count.total];
                $('#reportModal').modal('show');
            });
        };

        $scope.selectDate = {
            page: 1,
            created_at: {
                startDate: moment(),
                endDate: moment()
            }
        }

        $scope.optsCheckOrder = {
            date: {
                startDate: moment(),
                endDate: moment()
            }
        };

        $scope.checkOrder = function (id) {
            $('#checkboxDefault-' + id).prop("disabled", true).closest('tr').addClass('checked');
            CustomerOrder.checkOrder({
                id: id
            }).success(function (data, status, headers, config) {
                Messenger().post(data.message);
            });
        };

        $scope.$watchCollection('filterData.scheduled_time', function () {
            $scope.refreshData('map');
        });

        $scope.selectedDate = 'All';
        $scope.setDate = function (date) {
            $scope.selectedDate = date;
            $scope.filterData.weekDay = date;
            $scope.filterData.checklist = true;
            $scope.filterData.no_paginate = true;
            $scope.filterData.stations = [0];

            setTimeout(() => {
                $('.selected-item').click();
                $('.filter-option').html('By Station')
            }, 1000);
        }

        $scope.clearCheckFilter = function (page) {
            $scope.filterData = {
                workers: [],
                workSteps: [],
                paginate: 25,
                page: 1,
                stations: [],
                checklist: true,
                created_at: {
                    startDate: null,
                    endDate: null
                },
                scheduled_time: {
                    startDate: null,
                    endDate: null
                }
            }
            $('.selectpicker').selectpicker('val', []);
            $location.search('data', null)
            $scope.filterProp = [];
            $scope.customerOrders('map');
        }

        function IsJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

    }
]);