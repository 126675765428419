teleskop.controller('SettingController', ['$scope', 'Setting', '$location','$routeParams','$filter',
  function ($scope, Setting, $location, $routeParams, $filter) {
    $scope.deleted = [];
    $scope.mapProp = {};
    $scope.textOptions = [
      ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'quote'],
      ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
      ['html', 'insertImage','insertLink',]
    ];

    $scope.getSetting = function () {
      Setting.getSetting().success(function(data, status, headers, config) {
        $scope.setting = data.setting;
        $scope.order_settings = data.order_settings
        
        var location = new google.maps.LatLng(39.1731399,34.8864061);
        $scope.map = new google.maps.Map(document.getElementById('settings-map'), {
					zoom: 5,
					center: location,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
				});

        if(!data.company.latitude && !data.company.longitude && !data.company.zoom) {
          if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(function(position){
                $scope.map.setCenter(new google.maps.LatLng(position.coords.latitude,position.coords.longitude));
              });
          }
        }else {
          $scope.map.setCenter(new google.maps.LatLng(data.company.latitude,data.company.longitude));
          $scope.map.setZoom(data.company.zoom);
        }

        google.maps.event.addListener($scope.map, 'idle', function(){
          $scope.mapProp.zoom = $scope.map.getZoom();
          $scope.mapProp.latitude = $scope.map.getCenter().lat();
          $scope.mapProp.longitude = $scope.map.getCenter().lng();
        });
      });
    }

    $scope.updateCenter = function() {
      Setting.updateCenter($scope.mapProp).success(function(data, status, headers, config) {
        Messenger().post(data.message);
        $scope.authenticatedUser.location.lat = data.company.latitude;
        $scope.authenticatedUser.location.lon = data.company.longitude;
        $scope.authenticatedUser.location.zoom = data.company.zoom;
			});
    }

		$scope.updateSetting = function (setting) {
			Setting.updateSetting(setting).success(function(data, status, headers, config) {
        Messenger().post(data.message);
        $scope.authenticatedUser.setting = data.setting;
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"}); 
      });
    }

		$scope.updateOrderSetting = function (setting) {
			Setting.updateOrderSetting(setting).success(function(data, status, headers, config) {
        //Messenger().post(data.message);
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"}); 
      });
		}

    $scope.getSms = function () {
      Setting.getSms().success(function(data, status, headers, config) {
        $scope.data = data.sms;
      });
    }

    $scope.saveSms = function (item) {
      item.sms.work_step_id = item.id;
      Setting.saveSms(item.sms).success(function(data, status, headers, config) {
        //$scope.data = data.data;
      });
    }

    $scope.addAttrTitle = function (attr,item) {
      if(typeof item !== 'undefined') {
        item.title = item.title + ' ' + attr;
      }else {
        item = {};
        item.title = attr;
      }
    }

    $scope.addAttr = function (attr,item) {
      if(typeof item !== 'undefined') {
        item.content = item.content + ' ' + attr;
      }else {
        item = {};
        item.content = attr;
      }
    }

    $scope.addAttrMailTitle = function (attr,item) {
      if(typeof item !== 'undefined') {
        item.mail_title = item.mail_title + ' ' + attr;
      }else {
        item = {};
        item.mail_title = attr;
      }
    }

    $scope.addAttrMailContent = function (attr,item) {
      if(typeof item !== 'undefined') {
        item.mail_content = item.mail_content + ' ' + attr;
      }else {
        item = {};
        item.mail_content = attr;
      }
    }

    $scope.getWorkSteps = function () {
      Setting.getWorkSteps().success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.sortableOptions = {
      update: function(e, ui) {
        Setting.sortWorkSteps($scope.data.steps);
      }
    };

    $scope.sortableOptionsTask = {
      update: function(e, ui) {
        Setting.sortTaskSteps($scope.data.task_steps);
      }
    };

    $scope.newStep = function () {
      $scope.step = {
        sms_send : 0,
        color: '#FF9800',
        sms : { 
          content : ''
        }
      };
      $('#newStep').modal('show');
    }

		$scope.saveStep = function () {
			Setting.saveStep($scope.step).success(function(data, status, headers, config) {

        $scope.data.steps = data.steps;
        $scope.data.fixed_steps = data.fixed_steps;
        $scope.step = {
          sms_send : 0,
          color: '#FF9800',
          sms : { 
            content : ''
          }
        };

        Messenger().post(data.message);
        $('#newStep').modal('hide');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

    $scope.editStep = function (item) {
      $scope.step = item;
      $('#newStep').modal('show');
    }

    $scope.deleteStep = function (id,type) {
			if(type == 'set') {
        $('#delete').modal('show');
				$scope.deleteStepId = id;
      }else if(type == 'delete') {
				Setting.deleteStep($scope.deleteStepId).success(function(data, status, headers, config) {
          Messenger().post(data.message);
					$scope.data.steps = data.steps;
          $scope.data.fixed_steps = data.fixed_steps;
          $('#delete').modal('hide');
				}).error(function(data) {
          Messenger().post({message: data.message,type: "error"});
				});
			} 
		}
    $scope.forms = function () {
      Setting.forms().success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.getForm = function () {
      Setting.getForm($routeParams.id).success(function(data, status, headers, config) {
        $scope.form = data.form;
      });
    }

    $scope.newForm = function () {
      $scope.form = {
        fields : [{
          'field_name': '',
          'field_type': 'text',
          'field_value': '',
          'field_reqiured': '1',
          'permission': '1'
        }]
      }
    }

    $scope.addFormField = function (type) {
      $scope.form.fields.push({ 
          field_name: '',
          field_type: type,
          field_value: '',
          field_reqiured: '1',
          permission: '1'
			});
    }

    $scope.editFormField = function (item) {
      $scope.edit = item;
    }

    $scope.deleteFormData = function (index) {
			$scope.form.fields.splice(index, 1);
		}

    $scope.delete = function (type) {
			if(type == 'set') {
        $('#delete').modal('show');
      } else if(type == 'delete') {
				Setting.deleteForm($scope.deleted).success(function(data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.forms.indexOf($filter('filter')($scope.data.forms, {id: $scope.deleted[i] })[0]);
            if (index > -1)
              $scope.data.forms.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
				}).error(function(data) {
          Messenger().post({message: data.message,type: "error"});
				});
			} 
		}

		$scope.saveForm = function () {
			Setting.saveForm($scope.form).success(function(data, status, headers, config) {
				$scope.data = data;
        Messenger().post(data.message);
        $location.path('/formlar');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

		$scope.updateForm = function () {
			Setting.updateForm($scope.form).success(function(data, status, headers, config) {
				$scope.data = data;
        Messenger().post(data.message);
        $location.path('/formlar');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

    $scope.goToForm = function(id) {
      $location.path('/form/' + id);
    }
      
    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      }
      else {
        $scope.deleted.push(id);
      }
    };

  }
]);
