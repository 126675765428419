teleskop.
factory('Task', ['$http', function($http) {
    return {
      projects: function(data) {
        return $http.get('api/projects');
      },
      dashboard: function(data) {
        return $http.post('api/dashboard-task',data);
      },
      saveProject: function(data) {
        return $http.post('api/save-project',data);
      },
      workers: function(e) {
        return $http.get('api/dashboard-workers',{params: e,ignoreLoadingBar: true});
      },
      task: function(id) {
        return $http.get('api/task/' + id);
      },
      newTaskData: function(id) {
        return $http.get('api/new-task/'+id);
      },
      newTask: function(e) {
        return $http.post('api/new-task',e);
      },
      sendComment: function(data) {
        return $http.post('api/send-comment',data);
      },
      updateTask: function(data) {
        return $http.post('api/update-task',data);
      },
      getActivities: function(id) {
        return $http.get('api/get-activities/'+id);
      },
      changeTaskStatus: function(data) {
        return $http.post('api/change-task-status',data);
      },
      changeNotification: function(data) {
        return $http.post('api/change-task-notification-status',data);
      },
      changeTaskStepOrders: function(data) {
        return $http.post('api/change-task-steps-orders',data);
      },
      changeWorkStatusOffice: function(data) {
        return $http.post('api/change-work-status',data, {ignoreLoadingBar: true});
      },
      deleteTask: function(data) {
        return $http.post('api/delete-task',data);
      },
      saveTaskStep: function(data) {
        return $http.post('api/save-task-step',data);
      },
      sortTaskSteps: function(data) {
        return $http.post('api/sort-task-step',data);
      },
      deleteTaskStep: function(id) {
        return $http.delete('api/task-step/' + id);
      },
      deleteProject: function(id) {
        return $http.delete('api/project/' + id);
      },
      deleteTaskStep: function(id) {
        return $http.delete('api/task-step/' + id);
      },
      
    };
}]);