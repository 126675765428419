teleskop.controller('UberMenuController', ['$scope', 'UberMenu', '$location', 'FileUploader', '$localStorage', '$filter', '$routeParams', '$rootScope',
  function ($scope, UberMenu, $location, FileUploader, $localStorage, $filter, $routeParams, $rootScope) {
    $scope.address = 0;
    $scope.deleted = [];
    $scope.update = {};
    $scope.edit = [];
    $scope.sortReverse = false;
    $scope.selectedItem = null;

    $scope.init = function (type) {
      UberMenu.init($routeParams.id).success(function (data, status, headers, config) {
        $scope.data = data;
        if ($scope.data.menu) {
          if (!$scope.data.menu.modifier_groups) {
            $scope.data.menu.modifier_groups = []
          }
        }
      });
    };

    $scope.changeStoreStatus = function (type) {
      UberMenu.changeStoreStatus({ store_id: $routeParams.id }).success(function (data, status, headers, config) {
        $scope.init();
        Messenger().post({
          message: data.message,
        });
      });
    };

    $scope.sortableOptions = {
      update: function (e, ui) {
        console.log(e)
        console.log(ui)
        // Setting.sortWorkSteps($scope.data.steps);
      }
    };

    $scope.getCategoryName = function (id) {
      var find = $scope.data.menu.categories.find(i => i.id == id);
      if (find) {
        return find.title.translations.en
      }
      return id;
    }

    $scope.inCategory = function (item) {
      var find = $scope.selectedCategory.entities.findIndex(i => i.id == item.id);
      if (find > -1) {
        return true
      }
      return false;
    }

    $scope.newUberMenu = function () {
      $scope.uberMenu = {
        type: 'Car',
        icon: 'Car',
        icon_color: '#000000'
      };
      UberMenu.newUberMenu().success(function (data, status, headers, config) {
        $scope.stations = data.stations;
      })
    };

    $scope.saveAllMenu = function (uberMenu) {
      $scope.loading = true;
      if (confirm('Are you sure you want to do?')) {
        var formData = {
          menu: $scope.data.menu,
          store_id: $scope.data.store.store_id
        }

        $scope.data.menu.items.forEach((element, index) => {
          if (!element.bundled_items) {
            delete $scope.data.menu.items[index].bundled_items;
            // console.log('bundled_items not found : ', element, index)
          }
          if (!element.tax_info) {
            // console.log('tax_info not found : ', element, index)
          }
          if (!element.price_info) {
            // console.log('price_info not found : ', element, index)
          } else {
            element.price_info.price = parseInt(element.price_info.price);
          }

        });

        if (!$scope.data.menu.modifier_groups) {
          $scope.data.menu.modifier_groups = [];
        }

        console.log($scope.data.menu);
        formData.menu = JSON.stringify($scope.data.menu);
        formData.pure_menu = ($scope.data.menu);

        UberMenu.update(formData).success(function (data, status, headers, config) {
          console.log(data);
          if (data && data.data && data.data.error) {
            Messenger().post({ message: data.error, type: 'error' });
          } else if (data && data.data && data.data.message) {
            Messenger().post({ message: data.data.message, type: 'error' });
          } else {
            Messenger().post(data.message);
          }
          $scope.uberMenu = data.data;
          $scope.init('show');
          $scope.loading = false;
        }).error(function (data) {
          $scope.loading = false;
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    };

    $scope.edit = function (id) {
      UberMenu.show(id).success(function (data, status, headers, config) {
        $scope.uberMenu = data.uberMenu;
        $scope.stations = data.stations;
        $scope.uberMenu.first_name = $scope.uberMenu.user.first_name;
        $scope.uberMenu.last_name = $scope.uberMenu.user.last_name;
        $scope.uberMenu.email = $scope.uberMenu.user.email;
        $('#uberMenuDetail').modal('hide');
        $('#uberMenuItem').modal('show');
      });
    }

    $scope.update = function (uberMenu) {
      $scope.loading = true;
      var data = {
        menu: $scope.data.menu,
        store_id: $scope.data.store.store_id
      }

      $scope.data.menu.items.forEach((element, index) => {
        if (!element.bundled_items) {
          delete $scope.data.menu.items[index].bundled_items;
          console.log('bundled_items not found : ', element, index)
        }
        if (!element.tax_info) {
          console.log('tax_info not found : ', element, index)
        }
        if (!element.price_info) {
          console.log('price_info not found : ', element, index)
        }

      });

      data.menu = JSON.stringify($scope.data.menu);
      UberMenu.update(data).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $('#uberMenuItem').modal('hide');
        $scope.loading = false;
        $scope.init('show');
        $scope.openItem(uberMenu)
      }).error(function (data) {
        $scope.loading = false;
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    };

    $scope.show = function () {
      UberMenu.show($scope.uberMenu.id).success(function (data, status, headers, config) {
        $scope.uberMenu = data.uberMenu;
        $scope.stations = data.stations;
        $('#uberMenuDetail').modal('show');
      });
    };

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

    $scope.removeImage = function () {
      $scope.selectedItem.image_url = "";
    }

    $scope.generalType = 'items';
    $scope.setGeneralType = function (type) {
      $scope.generalType = type;
      $scope.filterData = {
        term: ''
      };
    }

    // Items
    $scope.selectedData = {
      categories: [],
      modifiers: [],
      category_items: [],
      modifier_items: [],
    };

    $scope.openItem = function (item) {
      $scope.selectedItem = item;

      // Categories
      $scope.selectedData.categories = [];
      $scope.data.menu.categories.forEach(element => {
        if (element.entities.findIndex(i => i.id === $scope.selectedItem.id) > -1) {
          $scope.selectedData.categories.push(element);
        }
      });

      // Modifier Groups
      $scope.selectedData.modifiers = [];
      $scope.data.menu.modifier_groups.forEach(element => {
        if (element.modifier_options) {
          if (element.modifier_options.findIndex(i => i.id === $scope.selectedItem.id) > -1) {
            $scope.selectedData.modifiers.push(element);
          }
        }
      });
      $scope.modalType = 'edit_item';
      $('#uberMenuItem').modal('show');
    };

    $scope.addItem = function () {
      $scope.selectedItem = {
        "id": 'ITEM_' + Math.random().toString(36).substr(2, 5),
        "title": {
          "translations": {
            "en": ""
          }
        },
        "description": {
          "translations": {
            "en": ""
          }
        },
        "image_url": "",
        "price_info": {
          "price": 0,
          "overrides": []
        },
        "tax_info": {},
        "dish_info": {
          "classifications": {
            "ingredients": null,
            "additives": null
          }
        },
        "tax_label_info": {
          "default_value": {
            "labels": [
            ],
            "source": "DEFAULT"
          }
        },
        "product_info": {
          "product_traits": null,
          "countries_of_origin": null
        },
        "bundled_items": null
      }
      $scope.selectedData.categories = [];
      $scope.selectedData.modifiers = [];
      $scope.modalType = 'new_item';
      $('#uberMenuItem').modal('show');
    }

    $scope.saveItem = function (item) {
      var tempCategories = $scope.selectedData.categories;
      var tempModifiers = $scope.selectedData.modifiers;

      if ($scope.modalType == 'new_item') {
        $scope.data.menu.items.push($scope.selectedItem);
      } else {

      }

      // Categories
      tempCategories.forEach(element => {
        var cat = $scope.data.menu.categories.find(i => i.id === element.id);
        var exist = cat.entities.findIndex(i => i.id === $scope.selectedItem.id);
        if (exist === -1) {
          cat.entities.push({
            id: $scope.selectedItem.id
          })
        }
      });
      if (tempCategories.length === 0) {
        $scope.data.menu.categories.forEach(element => {
          var index = element.entities.findIndex(i => i.id === $scope.selectedItem.id);
          if (index > -1) {
            element.entities.splice(index, 1);
          }
        });
      }

      // Modifiers
      tempModifiers.forEach(element => {
        var cat = $scope.data.menu.modifier_groups.find(i => i.id === element.id);
        var exist = cat.modifier_options.findIndex(i => i.id === $scope.selectedItem.id);
        if (exist === -1) {
          cat.modifier_options.push({
            id: $scope.selectedItem.id
          })
        }
      });
      if (tempModifiers.length === 0) {
        $scope.data.menu.modifier_groups.forEach(element => {
          var index = element.modifier_options.findIndex(i => i.id === $scope.selectedItem.id);
          if (index > -1) {
            element.modifier_options.splice(index, 1);
          }
        });
      }

      $scope.selectedData.categories = [];
      $scope.selectedData.modifiers = [];
      $scope.selectedItem = {};

      $('#uberMenuItem').modal('hide');
    };

    $scope.deleteItem = function (item) {
      if (confirm('Are you sure you delete this item ?')) {
        var index = $scope.data.menu.items.findIndex(i => i.id === item.id);
        if (index > -1) {

          // Delete in Categories
          var deletedCategoryEntity = [];
          $scope.data.menu.categories.forEach(element => {
            deletedCategoryEntity = [];
            element.entities.forEach((entityItem, index) => {
              if (entityItem.id == item.id) {
                deletedCategoryEntity.push(index);
              }
            });
            deletedCategoryEntity.forEach(deleteItem => {
              element.entities.splice(deleteItem, 1);
            });
          });

          // Delete in Modifier Groups
          var deletedModifierGroups = [];
          $scope.data.menu.modifier_groups.forEach(element => {
            deletedModifierGroups = [];
            if (element.modifier_options) {
              element.modifier_options.forEach((entityItem, index) => {
                if (entityItem.id == item.id) {
                  deletedModifierGroups.push(index);
                }
              });
            }
            deletedModifierGroups.forEach(deleteItem => {
              element.modifier_options.splice(deleteItem, 1);
            });
          });

          $scope.data.menu.items.splice(index, 1);

        }
      }
    };

    // Menus 
    $scope.getCategories = function (category_ids) {
      if (!category_ids || category_ids.length === 0) {
        return [];
      }
      return $scope.data.menu.categories.filter(i => category_ids.indexOf(i.id) > -1);
    }

    $scope.openMenu = function (item) {
      $scope.selectedMenu = item;
      $scope.modalType = 'edit_menu';
      $('#uberMenuMenu').modal('show');
    };

    $scope.addMenu = function () {
      $scope.selectedMenu = {
        "id": 'MENU_' + Math.random().toString(36).substr(2, 5),
        "title": {
          "translations": {
            "en": ""
          }
        },
        "service_availability": [
          {
            "day_of_week": "monday",
            "time_periods": [
              {
                "start_time": "00:00",
                "end_time": "23:59"
              }
            ]
          },
          {
            "day_of_week": "tuesday",
            "time_periods": [
              {
                "start_time": "00:00",
                "end_time": "23:59"
              }
            ]
          },
          {
            "day_of_week": "wednesday",
            "time_periods": [
              {
                "start_time": "00:00",
                "end_time": "23:59"
              }
            ]
          },
          {
            "day_of_week": "thursday",
            "time_periods": [
              {
                "start_time": "00:00",
                "end_time": "23:59"
              }
            ]
          },
          {
            "day_of_week": "friday",
            "time_periods": [
              {
                "start_time": "00:00",
                "end_time": "23:59"
              }
            ]
          },
          {
            "day_of_week": "saturday",
            "time_periods": [
              {
                "start_time": "00:00",
                "end_time": "23:59"
              }
            ]
          },
          {
            "day_of_week": "sunday",
            "time_periods": [
              {
                "start_time": "00:00",
                "end_time": "23:59"
              }
            ]
          }
        ],
        "category_ids": [
        ]
      }
      $scope.modalType = 'new_menu';
      $('#uberMenuMenu').modal('show');
    }

    $scope.saveMenu = function (item) {
      if ($scope.modalType == 'new_menu') {
        $scope.data.menu.menus.push($scope.selectedMenu);
        $scope.selectedMenu = {};
      } else {

      }
      $('#uberMenuMenu').modal('hide');
    };

    $scope.deleteMenu = function (item) {
      if (confirm('Are you sure you delete this menu ?')) {
        var index = $scope.data.menu.menus.findIndex(i => i.id === item.id);
        if (index > -1) {
          $scope.data.menu.menus.splice(index, 1);
        }
      }
    };

    $scope.parseStartTime = function (item) {
      if (!item.start_time) {
        return;
      }
      item.start_time = item.start_time.substr(0, 2) + ":" + item.start_time.substr(2)
    };

    $scope.parseEndTime = function (item) {
      if (!item.end_time) {
        return;
      }
      item.end_time = item.end_time.substr(0, 2) + ":" + item.end_time.substr(2)
    };

    // Categories
    $scope.openCategory = function (item) {
      $scope.selectedCategory = item;
      $scope.selectedData.category_items = [];
      $scope.selectedCategory.entities.forEach(element => {
        var item = $scope.data.menu.items.find(i => i.id === element.id);
        $scope.selectedData.category_items.push(item);
      });
      $scope.modalType = 'edit_category';
      $('#uberMenuCategory').modal('show');
    };

    $scope.addCategory = function () {
      $scope.selectedCategory = {
        "id": 'CATEGORY_' + Math.random().toString(36).substr(2, 5),
        "title": {
          "translations": {
            "en": ""
          }
        },
        "entities": []
      }
      $scope.selectedData.category_items = [];
      $scope.modalType = 'new_category';
      $('#uberMenuCategory').modal('show');
    }

    $scope.saveCategory = function (item) {
      var tempItems = $scope.selectedData.category_items;

      if ($scope.modalType == 'new_category') {
        $scope.data.menu.categories.push($scope.selectedCategory);
      } else {

      }

      $scope.selectedCategory.entities = [];
      tempItems.forEach(element => {
        if (element.id) {
          var item = $scope.data.menu.items.find(i => i.id === element.id);
        } else {
          var item = $scope.data.menu.items.find(i => i.id === element);
        }
        var exist = $scope.selectedCategory.entities.findIndex(i => i.id === item.id);
        if (exist === -1) {
          $scope.selectedCategory.entities.push({
            id: item.id
          })
        }
      });

      if (tempItems.length === 0) {
        $scope.selectedCategory.entities = []
      }

      $scope.selectedData.category_items = [];
      $scope.selectedCategory = {};

      $('#uberMenuCategory').modal('hide');
    };

    $scope.deleteCategory = function (item) {
      if (confirm('Are you sure you delete this category ?')) {
        var index = $scope.data.menu.categories.findIndex(i => i.id === item.id);
        if (index > -1) {
          $scope.data.menu.categories.splice(index, 1);

          // Delete in Menus
          var deletedMenu = [];
          $scope.data.menu.menus.forEach(element => {
            deletedMenu = [];
            element.category_ids.forEach((entityItem, index) => {
              if (entityItem === item.id) {
                deletedMenu.push(index);
              }
            });
            deletedMenu.forEach(deleteItem => {
              element.category_ids.splice(deleteItem, 1);
            });
          });

        }
      }
    };

    // Modifier Groups
    $scope.openModifier = function (item) {
      $scope.selectedModifier = item;
      $scope.selectedData.modifier_items = [];
      $scope.selectedModifier.modifier_options.forEach(element => {
        var item = $scope.data.menu.items.find(i => i.id === element.id);
        $scope.selectedData.modifier_items.push(item);
      });
      $scope.modalType = 'edit_modifier';
      $('#uberMenuModifier').modal('show');
    };

    $scope.addModifier = function () {
      $scope.selectedModifier = {
        "id": 'MODIFIER_' + Math.random().toString(36).substr(2, 5),
        "title": {
          "translations": {
            "en": ""
          }
        },
        "modifier_options": []
      }
      $scope.selectedData.modifier_items = [];
      $scope.modalType = 'new_modifier';
      $('#uberMenuModifier').modal('show');
    }

    $scope.saveModifier = function (item) {
      var tempItems = $scope.selectedData.modifier_items;
      console.log(tempItems)

      if ($scope.modalType == 'new_modifier') {
        $scope.data.menu.modifier_groups.push($scope.selectedModifier);
      } else {

      }

      $scope.selectedModifier.modifier_options = [];
      tempItems.forEach(element => {
        var item = $scope.data.menu.items.find(i => i.id === element);
        var exist = $scope.selectedModifier.modifier_options.findIndex(i => i.id === item.id);
        if (exist === -1) {
          $scope.selectedModifier.modifier_options.push({
            id: item.id
          })
        }
      });

      if (!tempItems || tempItems.length === 0) {
        $scope.selectedModifier.modifier_options = []
      }

      $scope.selectedModifier = {};
      $scope.selectedData.modifier_items = [];
      $('#uberMenuModifier').modal('hide');
    };

    $scope.deleteModifier = function (item) {
      if (confirm('Are you sure you delete this category ?')) {
        var index = $scope.data.menu.modifier_groups.findIndex(i => i.id === item.id);
        if (index > -1) {
          $scope.data.menu.modifier_groups.splice(index, 1);
        }
      }
    };

    var logo = $scope.logo = new FileUploader({
      url: '/api/upload-image-uber',
      headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
      autoUpload: true
    });

    logo.filters.push({
      name: 'formatFilter',
      fn: function (item /*{File|FileLikeObject}*/, options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|webp|'.indexOf(type) !== -1;
      }
    });

    logo.onAfterAddingFile = function (fileItem) {
      $scope.proccessProfile = true;
      $scope.pending = true;
    };

    logo.onCompleteItem = function (item, response, status, headers) {
      $scope.proccessProfile = false;
      $scope.selectedItem.logo = response.file;
      $scope.selectedItem.image_url = 'https://s3.us-west-2.amazonaws.com/hodxpress/' + response.file;
      if (response.status == 'success')
        $scope.pending = false;
    }

    $scope.saveMenuOld = function (uberMenu) {
      $scope.loading = true;
      if (confirm('Are you sure you want to do?')) {
        var data = {
          menu: $scope.data.menu,
          store_id: $scope.data.store.store_id
        }

        var ids = [];
        var dublicates = [];
        $scope.data.menu.items.forEach((element, index) => {
          if (!element.bundled_items) {
            delete $scope.data.menu.items[index].bundled_items;
            // console.log('bundled_items not found : ', element, index)
          }
          if (!element.tax_info) {
            // console.log('tax_info not found : ', element, index)
          }
          if (!element.price_info) {
            // console.log('price_info not found : ', element, index)
          } else {
            element.price_info.price = parseInt(element.price_info.price);
          }
          var find = ids.findIndex(i => i == element.id);
          if (find > -1) {
            dublicates.push(element.id);
          } else {
            ids.push(element.id);
          }
        });

        dublicates.forEach((element, index) => {
          var find = $scope.data.menu.items.findIndex(i => i.id == element);
          if (find > -1) {
            $scope.data.menu.items.splice(find, 1);
          }
        });

        // Clean Dublicate Categories
        var categoryIds = [];
        var dublicateCategories = [];
        $scope.data.menu.categories.forEach((element, index) => {
          var find = categoryIds.findIndex(i => i == element.id);
          if (find > -1) {
            dublicateCategories.push(element.id);
          } else {
            categoryIds.push(element.id);
          }
        });
        dublicateCategories.forEach((element, index) => {
          var find = $scope.data.menu.categories.findIndex(i => i.id == element);
          if (find > -1) {
            $scope.data.menu.categories.splice(find, 1);
          }
        });

        // Clean Dublicate Menus
        var menuIds = [];
        var dublicateMenus = [];
        $scope.data.menu.menus.forEach((element, index) => {
          var find = menuIds.findIndex(i => i == element.id);
          if (find > -1) {
            dublicateMenus.push(element.id);
          } else {
            menuIds.push(element.id);
          }
        });
        dublicateMenus.forEach((element, index) => {
          var find = $scope.data.menu.menus.findIndex(i => i.id == element);
          if (find > -1) {
            $scope.data.menu.menus.splice(find, 1);
          }
        });

        // Clean Dublicate Modifiers
        var modifierIds = [];
        var dublicateModifiers = [];
        $scope.data.menu.modifier_groups.forEach((element, index) => {
          var find = modifierIds.findIndex(i => i == element.id);
          if (find > -1) {
            dublicateModifiers.push(element.id);
          } else {
            modifierIds.push(element.id);
          }
        });
        dublicateModifiers.forEach((element, index) => {
          var find = $scope.data.menu.modifier_groups.findIndex(i => i.id == element);
          if (find > -1) {
            $scope.data.menu.modifier_groups.splice(find, 1);
          }
        });


        // Clean Dublicate Categories Subsection
        var sectionIds = [];
        var dublicateSections = [];
        $scope.data.menu.categories.forEach((element, index) => {
          sectionIds[element.id] = [];
          dublicateSections[element.id] = [];
          element.entities.forEach(subItem => {
            var find = sectionIds[element.id].findIndex(i => i == subItem.id);
            if (find > -1) {
              dublicateSections[element.id].push(subItem.id);
            } else {
              sectionIds[element.id].push(subItem.id);
            }
          });
        });

        Object.entries(dublicateSections).forEach((element, index) => {
          element[1].forEach((dItem) => {
            var findCategory = $scope.data.menu.categories.findIndex(i => i.id == element[0]);
            console.log('findCategory', findCategory)
            if (findCategory > -1) {
              var find = $scope.data.menu.categories[findCategory].entities.findIndex(i => i.id == dItem);
              if (find > -1) {
                $scope.data.menu.categories[findCategory].entities.splice(find, 1);
              }
            }
          });
        });

        console.log($scope.data.menu)

        // return;

        data.menu = JSON.stringify($scope.data.menu);
        data.pure_menu = ($scope.data.menu);
        console.log($scope.data.menu)

        // return;

        UberMenu.update(data).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $scope.uberMenu = data.data;
          $scope.init('show');
          $scope.loading = false;
        }).error(function (data) {
          $scope.loading = false;
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    };

  }
]);