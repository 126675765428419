teleskop.controller('CustomerExpenseController', ['$scope', 'Analytic', '$location', '$http',
    function($scope, Analytic, $location, $http) {

        $scope.filterProp = [];

        $scope.filterData = {
            export: false,
            customers: [],
            workers: [],
            workSteps: [],
            page: 1,
            date: 'week',
            created_at: {
                startDate: moment().startOf('week'),
                endDate: moment().endOf('week'),
            },
        }

        $scope.selectedItems = [];

        $scope.$on('refresh_data', function() {
            $scope.init();
        }, true);

        // $scope.$watch('filterData', function() {
        //  $scope.init();
        // }, true);

        $scope.printInvoice = function(item) {
            // if($scope.selectedItem) {
            var win = window.open('/print-invoice?start=' + item.start + '&end=' + item.end, '_blank');
            win.focus();
            // }else {
            //  alert('Please select row!');
            // }
        };

        $scope.init = function(type) {
            Analytic.customerAccounting($scope.filterData).success(function(data, status, headers, config) {
                $scope.data = data;
            });
        };

        $scope.accountingOrders = function(item) {
            $scope.selectedItem = item;
            Analytic.accountingOrders(item).success(function(data, status, headers, config) {
                $scope.data.orders = data;
            });
        };

        // billing card check or uncheck
        $scope.checkBilling = function({ $$hashKey: itemId }) {
            if ($scope.selectedItems.indexOf(itemId) === -1) {
                $scope.selectedItems.push(itemId);
            } else {
                $scope.selectedItems = $scope.selectedItems.filter(id => id !== itemId);
            }
        };

        $scope.selectAllBilling = function(selectAllBilling) {
            if (selectAllBilling) {
                $scope.selectedItems = $scope.data.counts.map((count) => count.$$hashKey);
            } else {
                $scope.selectedItems = [];
            }
        }


        $scope.setDate = function(date) {
            $scope.filterData.date = date;
            $scope.init();
        }

        $scope.filter = function(type) {
            if (type == 'clear') {
                $scope.filterData = {
                    created_at: {
                        startDate: moment().startOf('week'),
                        endDate: moment().endOf('week'),
                    },
                }
            }
            $scope.init();
        }

    }
]);