teleskop.
factory('WorkType', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/work-types');
      },
      get: function(id) {
        return $http.get('api/work-type/' + id);
      },
      delete: function(id) {
        return $http.delete('api/delete-work-type/' + id);
      },
      save: function(data) {
        return $http.post('api/save-work-type',data);
      },
      update: function(data) {
        return $http.post('api/update-work-type',data);
      }
    };
}]);