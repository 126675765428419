teleskop.
factory('CustomerCard', ['$http', function($http) {
    return{
        get: function() {
            return $http.get('api/customer-card');
        },
        add: function(data) {
            return $http.post('api/customer-card',data);
        },
        primary: function(data) {
            return $http.post('api/primary-customer-card',data);
        },
        delete: function(data) {
            return $http.delete('api/customer-card/'+data);
        }
    };
}]);