teleskop.
factory('StockStore', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/stock-stores');
      },
      get: function(id) {
        return $http.get('api/stock-store/' + id);
      },
      new: function(id) {
        return $http.get('api/new-stock-store');
      },
      delete: function(id) {
        return $http.delete('api/delete-stock-store/' + id);
      },
      save: function(data) {
        return $http.post('api/save-stock-store',data);
      },
      update: function(data) {
        return $http.post('api/update-stock-store',data);
      }
    };
}]);