teleskop.controller('CustomerOrderAnalyticController', ['$scope', 'Analytic', 'Account', '$location', 'FileUploader', '$http',
	function ($scope, Analytic, Account, $location, FileUploader, $http) {

		$scope.filterProp = [];

		$scope.filterData = {
			export: false,
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at: {
				startDate: null,
				endDate: null
			},
			scheduled_time: {
				startDate: null,
				endDate: null
			}
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing order reports...')
			$scope.init();
		}, true);

		// $scope.$watch('filterData', function () {
		// 	$scope.init();
		// }, true);

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				export: false,
				customers: [],
				workers: [],
				workSteps: [],
				page: 1,
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
			$scope.init();
		}

		$scope.init = function (type) {
			Analytic.customerOrders($scope.filterData).success(function (data, status, headers, config) {
				if (typeof type !== undefined && type == 'export') {
					$scope.filterData.export = false;
					window.location.href = data.url;
					return;
				} else {
					$scope.data = data;
					// $scope.data.workSteps.unshift({
					// 	id: 0,
					// 	name: 'Created'
					// });
					$scope.data.steps = data.workSteps;
					$scope.barChartData = [];
					$scope.data.workSteps.forEach(element => {
						$scope.barChartData.push(data.chart.count[element.id]);
					});
					$scope.series = $scope.data.workSteps.map(i => i.name); //['Completed', 'On Way', 'İptal Edilen', 'Toplam'];
					$scope.chartData = data.count.chart;
				}
			});
		};

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function (page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					export: false,
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
			}
			$scope.init();
		}

		$scope.exportCustomerExcel = function () {
			$scope.filterData.export = true;
			$scope.init('export')
		};


	}
]);
