teleskop.config(['$routeProvider', '$locationProvider', '$httpProvider',
function ($routeProvider, $locationProvider, $httpProvider) {
$routeProvider
	.when('/login', {title: 'Login', templateUrl: 'modules/auth/login.html'})
	.when('/register', {title: 'Register', templateUrl: 'modules/auth/register.html'})
	.when('/merchant/register', {title: 'Merchant Register', templateUrl: 'modules/auth/merchant_register.html'})
	.when('/sales/register', {title: 'Sales Register', templateUrl: 'modules/auth/sales_register.html'})
	.when('/kayit-tamamlandi', {title: 'Kayıt Tamamlandı', templateUrl: 'modules/auth/register-success.html'})
	.when('/forgot', {title: 'Şifremi Unuttum', templateUrl: 'modules/auth/forgot.html'})
	.when('/reset-password?:data?', {title: 'Şifre Sıfırla', templateUrl: 'modules/auth/password.html'})

	/** Maps */
	.when('/order-report', {title: 'Order Reports', templateUrl: 'modules/company/analytics/orders.html',resolve : reports})
	// .when('/', {title: 'Özet ', templateUrl: 'modules/company/dashboard/dashboard.html'})
	.when('/map', {title: 'Map ', templateUrl: 'modules/company/dashboard/map.html',resolve : maps})
	.when('/routing', {title: 'Route ', templateUrl: 'modules/company/routing/index.html',resolve : maps})
	.when('/', {title: 'Order List', templateUrl: 'modules/company/dashboard/list.html',resolve : maps})
	.when('/orders?:filter', {title: 'Order List', templateUrl: 'modules/company/dashboard/list.html',resolve : maps})
	.when('/takvim?:filter', {title: 'Order List', templateUrl: 'modules/company/dashboard/calendar.html',resolve : maps})
	.when('/bildirimler?:filter', {title: 'Notifications', templateUrl: 'modules/company/dashboard/notification.html',resolve : maps})
	.when('/new-order?', {title: 'New Order', templateUrl: 'modules/company/dashboard/new_order.html',resolve : customer})

	.when('/ozel-alanlar', {title: 'Kargo Özel Alan Yönetimi', templateUrl: 'modules/company/order-field/list.html',resolve : maps})
	.when('/ozel-alanlar/yeni', {title: 'Yeni Kargo Özel Alanı', templateUrl: 'modules/company/order-field/new.html',resolve : maps})
	.when('/ozel-alanlar/:id', {title: 'Kargo Özel Alanı', templateUrl: 'modules/company/order-field/edit.html',resolve : maps})

	.when('/stations', {title: 'Stations', templateUrl: 'modules/company/stations/list.html',resolve : maps})
	.when('/stations/new', {title: 'New Station', templateUrl: 'modules/company/stations/new.html',resolve : maps})
	.when('/stations/:id', {title: 'Station', templateUrl: 'modules/company/stations/edit.html',resolve : maps})

	.when('/offers', {title: 'Offers', templateUrl: 'modules/company/offers/list.html',resolve : maps})
	.when('/offers/new', {title: 'New Offer', templateUrl: 'modules/company/offers/new.html',resolve : maps})
	.when('/offers/:id', {title: 'Offer', templateUrl: 'modules/company/offers/edit.html',resolve : maps})

	.when('/blocks', {title: 'Blocks', templateUrl: 'modules/company/blocks/list.html',resolve : maps})

	.when('/chats', {title: 'Chats', templateUrl: 'modules/company/chat/index.html',resolve : maps})

	.when('/billing', {title: 'Invoices', templateUrl: 'modules/company/billing/list.html',resolve : maps})
	.when('/billing/new', {title: 'New Invoice', templateUrl: 'modules/company/billing/new.html',resolve : maps})
	.when('/billing/:id', {title: 'Invoice', templateUrl: 'modules/company/billing/edit.html',resolve : maps})

	.when('/earning', {title: 'Earnings', templateUrl: 'modules/company/earning/list.html',resolve : maps})
	.when('/earning/new', {title: 'New Earning', templateUrl: 'modules/company/earning/new.html',resolve : maps})
	.when('/earning/:id', {title: 'Earning', templateUrl: 'modules/company/earning/edit.html',resolve : maps})

	.when('/routes', {title: 'Routes', templateUrl: 'modules/company/routes/list.html',resolve : maps})
	.when('/routes/new', {title: 'New Route', templateUrl: 'modules/company/routes/new.html',resolve : maps})
	.when('/routes/:id', {title: 'Route', templateUrl: 'modules/company/routes/edit.html',resolve : maps})
	.when('/route-activity?:filter', {title: 'Route Activity', templateUrl: 'modules/company/routes/activity.html',resolve : maps})

	.when('/is-tipleri', {title: 'Kargo Tipleri', templateUrl: 'modules/company/work-types/all.html',resolve : maps})
	.when('/is-tipleri/yeni', {title: 'Yeni Kargo Tipi', templateUrl: 'modules/company/work-types/new.html',resolve : maps})
	.when('/is-tipleri/:id', {title: 'Kargo Tipi', templateUrl: 'modules/company/work-types/edit.html',resolve : maps})

	.when('/formlar', {title: 'Formlar', templateUrl: 'modules/company/forms/list.html',resolve : maps})
	.when('/form/yeni', {title: 'Yeni Form', templateUrl: 'modules/company/forms/new.html',resolve : maps})
	.when('/form/:id', {title: 'Form', templateUrl: 'modules/company/forms/edit.html',resolve : maps})

	.when('/work-steps', {title: 'Sms/Mail Content', templateUrl: 'modules/company/work-steps/all.html',resolve : maps})
	.when('/mesajlar', {title: 'Sms Mesajları', templateUrl: 'modules/company/sms/index.html',resolve : maps})

	.when('/checklist-sablonlar', {title: 'Checklist Şablon Yönetimi', templateUrl: 'modules/company/checklist-template/list.html',resolve : maps})
	.when('/checklist-sablonlar/yeni', {title: 'Yeni Checklist Şablonu', templateUrl: 'modules/company/checklist-template/new.html',resolve : maps})
	.when('/checklist-sablonlar/:id', {title: 'Checklist Şablonu', templateUrl: 'modules/company/checklist-template/edit.html',resolve : maps})

	.when('/ayarlar', {title: 'Ayarlar', templateUrl: 'modules/company/settings/plugins.html',resolve : maps})
	
	/** Office */
	.when('/projeler', {title: 'Kargo Takip ', templateUrl: 'modules/company/tasks/projects.html',resolve : office})
	.when('/proje/:id', {title: 'Kargo Takip ', templateUrl: 'modules/company/tasks/task-list.html',resolve : office})
	
	/** Reports */
	.when('/raporlama', {title: 'Raporlama', templateUrl: 'modules/company/analytics/index.html',resolve : reports})
	.when('/invoice-reports', {title: 'Invoice Reports', templateUrl: 'modules/company/analytics/invoice.html',resolve : reports})
	.when('/earning-reports', {title: 'Earning Reports', templateUrl: 'modules/company/analytics/earning.html',resolve : reports})
	.when('/partner-report', {title: 'Müşteri Raporları', templateUrl: 'modules/company/analytics/customers.html',resolve : reports})
	.when('/gelir-gider', {title: 'Gelir Gider Raporları', templateUrl: 'modules/company/analytics/profit.html',resolve : reports})
	.when('/iade-teslim', {title: 'Iade Teslim Formu', templateUrl: 'modules/company/analytics/fail.html',resolve : reports})
	.when('/form-raporlari', {title: 'Müşteri Form Raporları', templateUrl: 'modules/company/analytics/forms.html',resolve : reports})
	.when('/driver-reports', {title: 'Personel Raporları', templateUrl: 'modules/company/analytics/workers.html',resolve : reports})
	
	/** Customers */
	.when('/musteri-yonetimi', {title: 'Müşteri Yönetimi', templateUrl: 'modules/company/customers/all.html',resolve : customers})
	.when('/musteri-yonetimi-map', {title: 'Müşteri Yönetimi', templateUrl: 'modules/company/customers/map.html',resolve : customers})
	.when('/musteri-yonetimi/detay/:id', {title: 'Müşteri Detayı', templateUrl: 'modules/company/customers/show.html',resolve : customers})
	.when('/musteri-yonetimi/detay/:id/duzenle', {title: 'Müşteri Düzenle', templateUrl: 'modules/company/customers/edit.html',resolve : customers})
	.when('/yeni-musteri', {title: 'Yeni Müşteri', templateUrl: 'modules/company/customers/new.html',resolve : customers})
	
	.when('/musteri-yonetimi/ozel-alanlar', {title: 'Müşteri Özel Alan Yönetimi', templateUrl: 'modules/company/customer-field/list.html',resolve : customers})
	.when('/musteri-yonetimi/ozel-alanlar/yeni', {title: 'Yeni Müşteri Özel Alanı', templateUrl: 'modules/company/customer-field/new.html',resolve : customers})
	.when('/musteri-yonetimi/ozel-alanlar/:id', {title: 'Müşteri Özel Alanı', templateUrl: 'modules/company/customer-field/edit.html',resolve : customers})

	/** Users */
	.when('/calisan-yonetimi', {title: 'Çalışan Yönetimi', templateUrl: 'modules/company/workers/all.html',resolve : users})
	.when('/calisan-yonetimi/detay/:id', {title: 'Çalışan Detayı', templateUrl: 'modules/company/workers/show.html',resolve : users})
	.when('/calisan-yonetimi/detay/:id/duzenle', {title: 'Çalışan Düzenle', templateUrl: 'modules/company/workers/edit.html',resolve : users})			
	.when('/yeni-calisan', {title: 'Yeni Çalışan', templateUrl: 'modules/company/workers/new.html',resolve : users})
	
	.when('/ekip-yonetimi', {title: 'Ekip Yönetimi', templateUrl: 'modules/company/team/all.html',resolve : users})
	.when('/ekip-yonetimi/detay/:id', {title: 'Ekip Detayı', templateUrl: 'modules/company/team/show.html',resolve : users})
	.when('/yeni-ekip', {title: 'Yeni Ekip', templateUrl: 'modules/company/team/new.html',resolve : users})
	
	.when('/users', {title: 'Yönetici', templateUrl: 'modules/company/users/all.html',resolve : users})
	.when('/users/detay/:id', {title: 'Yönetici Detayı', templateUrl: 'modules/company/users/show.html',resolve : users})
	.when('/yeni-yetkili', {title: 'Yeni Yönetici', templateUrl: 'modules/company/users/new.html',resolve : users})
	
	/** Settings */
	.when('/odeme-bilgileri?:filter', {title: 'Ödeme Bilgileri', templateUrl: 'modules/company/settings/payments.html',resolve : settings})
	.when('/hesap-bilgileri:filter?', {title: 'Hesap Bilgileri', templateUrl: 'modules/company/package/index.html',resolve : settings})
	
	.when('/profile', {title: 'Şirket Bilgileri', templateUrl: 'modules/company/settings/company.html',resolve : settings})
	.when('/change-password', {title: 'Şifre Değiştir', templateUrl: 'modules/company/settings/password.html',resolve : settings})
	.when('/sms-entegrasyonu', {title: 'Sms Sağlayıcı Entegrasyonu', templateUrl: 'modules/company/settings/sms_provider.html',resolve : settings})
	.when('/webhook', {title: 'WEBHOOK', templateUrl: 'modules/company/settings/webhook.html',resolve : settings})

	/* Day End */
	.when('/gun-sonu', {title: 'Gün Sonu Yönetimi', templateUrl: 'modules/company/dayend/list.html',resolve : maps})
	.when('/gun-sonu/yeni', {title: 'Yeni Gün Sonu', templateUrl: 'modules/company/dayend/new.html',resolve : maps})
	.when('/gun-sonu/:id', {title: 'Gün Sonu', templateUrl: 'modules/company/dayend/edit.html',resolve : maps})

	/** Stock Systems */

	/** Field Projects*/
	.when('/stok/projeler', {title: 'Proje Yönetimi', templateUrl: 'modules/company/field-projects/list.html',resolve : stocks})
	.when('/stok/projeler/yeni', {title: 'Yeni Proje', templateUrl: 'modules/company/field-projects/new.html',resolve : stocks})
	.when('/stok/projeler/:id', {title: 'Proje', templateUrl: 'modules/company/field-projects/edit.html',resolve : stocks})

	.when('/stok/depolar', {title: 'Depo Yönetimi', templateUrl: 'modules/company/stock-store/list.html',resolve : stocks})
	.when('/stok/depo/yeni', {title: 'Yeni Depo', templateUrl: 'modules/company/stock-store/new.html',resolve : stocks})
	.when('/stok/depo/:id', {title: 'Depo', templateUrl: 'modules/company/stock-store/edit.html',resolve : stocks})

	.when('/stok/urunler', {title: 'Stok Yönetimi', templateUrl: 'modules/company/stock/list.html',resolve : stocks})
	.when('/stok/urun/yeni', {title: 'Yeni Stok', templateUrl: 'modules/company/stock/new.html',resolve : stocks})
	.when('/stok/urun/:id', {title: 'Stok', templateUrl: 'modules/company/stock/edit.html',resolve : stocks})

	.when('/stok/hareketler', {title: 'Stok Hareketleri', templateUrl: 'modules/company/stock-movement/list.html',resolve : stocks})
	.when('/stok/hareket/yeni', {title: 'Yeni Stok Hareketi', templateUrl: 'modules/company/stock-movement/new.html',resolve : stocks})
	.when('/stok/hareket/:id', {title: 'Stok Hareketi', templateUrl: 'modules/company/stock-movement/edit.html',resolve : stocks})
	
	//Customer
	.when('/customer-new-order?', {title: 'New Order', templateUrl: 'modules/customer/new_order.html',resolve : customer})
	.when('/customer-orders', {title: 'Orders', templateUrl: 'modules/customer/orders.html',resolve : customer})
	.when('/live-orders', {title: 'Live Orders', templateUrl: 'modules/customer/live_orders.html',resolve : customer})
	.when('/live-test', {title: 'Live Orders', templateUrl: 'modules/customer/live_orders_test.html',resolve : customer})
	.when('/customer-reports', {title: 'Order Report', templateUrl: 'modules/customer/order_reports.html',resolve : customer})
	.when('/customer-account', {title: 'Account', templateUrl: 'modules/customer/account.html',resolve : customer})
	.when('/customer-settings', {title: 'Setttings', templateUrl: 'modules/customer/settings.html',resolve : customer})
	.when('/customer-integrations', {title: 'Integrations', templateUrl: 'modules/customer/integrations.html',resolve : customer})
	.when('/customer-password', {title: 'Şifre Değiştir', templateUrl: 'modules/customer/password.html',resolve : customer})
	.when('/customer-tracking', {title: 'Order Track', templateUrl: 'modules/customer/tracking.html',resolve : customer})
	.when('/customer-accounting', {title: 'Accounting', templateUrl: 'modules/customer/accounting.html',resolve : customer})
	.when('/customer-billing', {title: 'Billing', templateUrl: 'modules/customer/billing.html',resolve : customer})
	.when('/customer-feedback', {title: 'Feedback', templateUrl: 'modules/customer/feedback.html',resolve : customer})
	.when('/customer-check-orders', {title: 'Check Orders', templateUrl: 'modules/customer/check_orders.html',resolve : customer})
	.when('/uber-menu/:id', {title: 'Uber Menu', templateUrl: 'modules/customer/uber/menu/list.html',resolve : customer})

	// Sales
	.when('/sales-dashboard', {title: 'Dashboard', templateUrl: 'modules/sales/dashboard.html',resolve : sales})
	.when('/sales-settings', {title: 'Setttings', templateUrl: 'modules/sales/settings.html',resolve : sales})
	.when('/sales-members', {title: 'Members', templateUrl: 'modules/sales/members.html',resolve : sales})
	.when('/sales-tree', {title: 'Tree', templateUrl: 'modules/sales/tree.html',resolve : sales})
	.when('/sales-payments', {title: 'Payments', templateUrl: 'modules/sales/payments.html',resolve : sales})
	.when('/sales-feedback', {title: 'Feedback', templateUrl: 'modules/sales/feedback.html',resolve : sales})

	
	.when('/satin-al:filter?', {title: 'Satın Al', templateUrl: 'modules/customer/package/index.html',resolve : settings})
	.otherwise({redirectTo: '/'});

		$locationProvider.html5Mode(true);
		$httpProvider.interceptors.push(['$rootScope', '$q', '$localStorage',

			function ($rootScope, $q, $localStorage) {
				$httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
				
				return {
					'responseError': function(response) {
						if(response.status === 401 || response.status === 400) {
							$rootScope.$broadcast('unauthorized');
							window.location = '/login';
						} else if(response.status === 404) {
							//window.location = '/';
						} else if(response.status === 403) {
							window.location = '/hesap-bilgileri';
						}
						return $q.reject(response);
					}
				};
			}
		]);
	}
]);

teleskop.run(['$rootScope', '$location', function ($rootScope, $location) {
	
	$rootScope.$on('$routeChangeError', function(event, current, previous, rejection){
		console.log(rejection);
		if(rejection === 'Unauthorized') {
			$('#notPermission').modal('show');
		}
	});
}])
