teleskop.
factory('Sales', ['$http', function($http) {
    return {
      init: function(data) {
        return $http.post('api/sales', data);
      },
      dashboard: function(data) {
        return $http.post('api/analytic/dashboard',data);
      },
      payments: function(data) {
        return $http.post('api/sales/payments',data);
      },
    };
}]);