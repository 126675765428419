teleskop.
factory('Chat', ['$http', function($http) {
    return {
      all: function(data) {
        return $http.post('api/chats', data, { ignoreLoadingBar: true });
      },
      get: function(id) {
        return $http.get('api/chat/' + id);
      },
      new: function(id) {
        return $http.get('api/new-chat');
      },
      delete: function(id) {
        return $http.delete('api/delete-chat/' + id);
      },
      save: function(data) {
        return $http.post('api/save-chat',data);
      },
      update: function(data) {
        return $http.post('api/update-chat',data);
      },
      sendMessage: function(data) {
        return $http.post('api/send-message',data);
      }
    };
}]);