teleskop.controller('CustomerMapController', 
  ['$scope', 'Customer', '$location','FileUploader','$localStorage','$filter','$routeParams','$http',
  function ($scope, Customer, $location, FileUploader, $localStorage, $filter, $routeParams, $http) {
    $scope.address = 0;
    $scope.deleted = [];
    $scope.update = {};
    $scope.edit = [];
    $scope.newAdress = {};
    var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
    };
    var mapping = {
        street_number: 'number',
        route: 'street',
        locality: 'state',
        administrative_area_level_1: 'city',
        administrative_area_level_2: 'district',
        country: 'country',
        postal_code: 'zip'
    };
    $scope.filterProp = [];
		$scope.filterData = {
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: null, 
				endDate: null 
			}
		}

		$scope.filter = function (type) {
			if(type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
				$scope.filterProp = [];
			}else {
				$scope.filterData.filterProp = $scope.filterProp;
				if(typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
					$scope.filterData.filterProp.created_at = 'created_at';
				}
				if(typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
					$scope.filterData.filterProp.scheduled_time = 'scheduled_time';
				}
			}
			$scope.init();
    }

    $scope.$on('refresh_data', function() {
			console.log('refreshing map customers...')
			$scope.init();
		}, true);
    
    $scope.$watch('filterData', function() {
			$scope.init();
		}, true);


    $scope.init = function () {
      Customer.initMap($scope.filterData).success(function(data, status, headers, config) {
        $scope.data = data;
        mapInit(data);
      });
    };

    function mapInit(data) {
      $scope.map = new google.maps.Map(document.getElementById('customer_map'), {
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      var bounds = new google.maps.LatLngBounds();
      data.customers.forEach(function(element) {
       var color = element.color.replace('#', '%7C');
        var pinImage = new google.maps.MarkerImage("https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=" +color);
        var marker = new SlidingMarker({
          position: new google.maps.LatLng(Number(element.latitude),Number(element.longitude)),
          map: $scope.map,
          icon: pinImage,
          visible: true,
          draggable: false
        });
        bounds.extend(marker.getPosition());
        var content = '<div id="iw-container">' +
          '<div class="iw-content"> <div class="btn-group pull-right">' +
          '<a href="#" onclick="angular.element(this).scope().zoomIn('+element.latitude+','+element.longitude+')" class="btn btn-xs btn-warning">Yaklaş</a>' + 
          '<a href="#" onclick="angular.element(this).scope().getCustomerDetails('+element.id+')" class="btn btn-xs btn-primary">Detaylar</a> </div>' + 
          '<p><strong>'+element.customer_name+' </strong></p>' +
          '<hr style="margin:17px 0">' +
          '<p>'+element.address+'</p>' +
          '</div></div>';
          var infowindow = new google.maps.InfoWindow({
            content: content,
            maxWidth: 360
          });
          marker.addListener('click', function() {
            infowindow.open($scope.map, marker);
          });
      }, this);
      $scope.map.fitBounds(bounds);
    }

		$scope.zoomIn = function(lat,lng) {
			$scope.map.panTo(new google.maps.LatLng(Number(lat),Number(lng)));
			$scope.map.setZoom(16);
    }

    $scope.newCustomer = function () {
      Customer.newCustomer().success(function(data, status, headers, config) {
        $scope.data = data;
        $scope.customer = {};
        var center = new google.maps.LatLng(Number($scope.authenticatedUser.location.lat),Number($scope.authenticatedUser.location.lon));
        $scope.map = new google.maps.Map(document.getElementById('mapCustomer'), {
					zoom: 13,
					center: center,
					mapTypeId: google.maps.MapTypeId.ROADMAP
				});

        $scope.marker = new SlidingMarker({
					position: center,
					map: $scope.map,
					visible: true,
          draggable: true
				});

        $scope.marker.addListener('dragend', function(event) { 
          var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
          var geocoder = new google.maps.Geocoder();
          
          geocoder.geocode({
            'latLng': latlng
          }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                var place = results[0];
                var details = place.geometry && place.geometry.location ? {
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng()
                } : {};
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (componentForm[addressType]) {
                        var val = place.address_components[i][componentForm[addressType]];
                        details[mapping[addressType]] = val;
                    }
                }
                details.formatted = place.formatted_address;
                details.placeId = place.place_id;
                details.address = place.formatted_address;
                angular.merge($scope.customer, details);
                $scope.$apply();
              }
            }
          });

          $scope.customer.latitude = event.latLng.lat();
          $scope.customer.longitude = event.latLng.lng();
        });
      });
    };

    $scope.getAddress = function(address) {
      if(typeof address.latitude !== 'undefined') 
      {
        var location = new google.maps.LatLng(address.latitude,address.longitude);
        $scope.map.setCenter(location);
        $scope.marker.setDuration(1000);
        $scope.marker.setEasing("swing");
        $scope.marker.setPosition(location);
        angular.merge($scope.customer, address);
      }
    } 

    $scope.saveCustomer = function () {
      Customer.saveCustomer($scope.customer).success(function(data, status, headers, config) {
         Messenger().post(data.message);
        $location.path('musteri-yonetimi');
      }).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
    };

    $scope.delete = function (type) {
      if(type == 'set') {
        $('#delete').modal('show');
      } else if(type == 'delete') {
				Customer.deleteCustomer($scope.deleted).success(function(data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.customers.data.indexOf($filter('filter')($scope.data.customers.data, {id: $scope.deleted[i]})[0]);
            if (index > -1)
              $scope.data.customers.data.splice(index, 1);
          };
          $scope.deleted = [];
        }).error(function(data){
          Messenger().post({message: data.message,type: "error"});
        });
			}
    };

    $scope.deleteAddress = function (id) {
      Customer.deleteAddress({id: id}).success(function(data, status, headers, config) {
        Messenger().post(data.message);
        var index = $scope.customer.addresses.indexOf($filter('filter')($scope.customer.addresses, {id: id})[0]);
        if (index > -1)
          $scope.customer.addresses.splice(index, 1);
      }).error(function(data){
        Messenger().post({message: data.message,type: "error"});
      })
    };

    $scope.updateAddress = function () {
      Customer.updateAddress($scope.update).success(function(data, status, headers, config) {
         Messenger().post(data.message);
        $scope.update = {};
        $scope.edit = [];
      }).error(function(data){
        Messenger().post({message: data.message,type: "error"});
      })
    };

    $scope.updateCustomer = function () {
      Customer.updateCustomer($scope.customer).success(function(data, status, headers, config) {
        Messenger().post(data.message);
        $location.path('musteri-yonetimi');
        $scope.getCustomerDetails($scope.customer.id);
      }).error(function(data){
        Messenger().post({message: data.message,type: "error"});
      })
    };

    $scope.addAddress = function () {
      $scope.newAdress.company_customer_id = $scope.customer.id;
      Customer.addAddress($scope.newAdress).success(function(data, status, headers, config) {
        Messenger().post(data.message);
        $scope.customer = data[0];
        $scope.newAdress = {};
      }).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
    };

    $scope.show = function (type) {
      Customer.show($routeParams.id).success(function(data, status, headers, config) {
        $scope.customer = data.customer;
        $scope.chart = data.chart;
        $scope.fields = data.fields;

        if(typeof type !== 'undefined') {
          $scope.map = new google.maps.Map(document.getElementById('mapCustomer'), {
            zoom: 13,
            center: new google.maps.LatLng(Number(data.customer.latitude),Number(data.customer.longitude)),
            mapTypeId: google.maps.MapTypeId.ROADMAP
          });
          $scope.marker = new SlidingMarker({
            position: new google.maps.LatLng(Number(data.customer.latitude),Number(data.customer.longitude)),
            map: $scope.map,
            visible: true,
            draggable: true
          });
          $scope.marker.addListener('dragend', function(event) { 
            var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
            var geocoder = new google.maps.Geocoder();
            
            geocoder.geocode({
              'latLng': latlng
            }, function (results, status) {
              if (status === google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                  var place = results[0];
                  var details = place.geometry && place.geometry.location ? {
                      latitude: place.geometry.location.lat(),
                      longitude: place.geometry.location.lng()
                  } : {};
                  for (var i = 0; i < place.address_components.length; i++) {
                      var addressType = place.address_components[i].types[0];
                      if (componentForm[addressType]) {
                          var val = place.address_components[i][componentForm[addressType]];
                          details[mapping[addressType]] = val;
                      }
                  }
                  details.formatted = place.formatted_address;
                  details.placeId = place.place_id;
                  details.address = place.formatted_address;
                  angular.merge($scope.customer, details);
                  $scope.$apply();
                }
              }
            });

            $scope.customer.latitude = event.latLng.lat();
            $scope.customer.longitude = event.latLng.lng();
          });
        }

      });
    }

    $scope.$on('new_order', function (event,data,nextParams,fromState) {
      var index = $scope.customer.orders.indexOf($filter('filter')($scope.customer.orders, {id: data.order.id})[0]);
			if (index > -1)
				var order = $scope.customer.orders[index];
      if(typeof order !== 'undefined')
        angular.merge(order, data.order);
      else
        $scope.customer.orders.unshift(data.order);
		});

    $scope.$on('updateOrder', function (event,data,nextParams,fromState) {
      var index = $scope.customer.orders.indexOf($filter('filter')($scope.customer.orders, {id: data.id})[0]);
			if (index > -1)
				var order = $scope.customer.orders[index];
			angular.merge(order, data);
		});

		$scope.$on('deleteOrder', function (event,data,nextParams,fromState) {
			var index = $scope.customer.orders.indexOf($filter('filter')($scope.customer.orders, {id: data.id})[0]);
			if (index > -1) 
				$scope.customer.orders.splice(index, 1);
		});

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      }
      else {
        $scope.deleted.push(id);
      }
    };

    $scope.donwloadSampleExcel = function () {
      Customer.donwloadSampleExcel().success(function(data, status, headers, config) {
        window.location.href = data.url;
      });
    };

    $scope.importCustomerList = function () {
      $scope.upload = true;
      var fd = new FormData();
      var imgBlob = dataURItoBlob($scope.uploadme);
      fd.append('file', imgBlob);
      $http.post(
          'api/import-customer-list',
          fd, 
          {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined,
              'X-CSRF-TOKEN': CSRF_TOKEN
            }
          }
        )
      .success(function(response) {
        Messenger().post(response.message);
        setTimeout(function() {
          location.reload();
        }, 800);

      })
      .error(function(response) {
        Messenger().post({message: response.message,type: "error"});
      })
      .finally(function(data){
        $scope.upload = false;
      });

    };

    $scope.uploadme;

    $scope.uploadImageCustomer = function (customer) {
      $scope.upload = true;
      var fd = new FormData();
      var imgBlob = dataURItoBlob(customer.imageUp);
      fd.append('file', imgBlob);
      fd.append('customer', customer.id);
      $http.post(
          'api/upload-image-customer',
          fd, 
          {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined,
              'X-CSRF-TOKEN': CSRF_TOKEN
            }
          }
        )
      .success(function(response) {
        $scope.customer.logo = response.logo;
        console.log($scope.customer);
      });
    };

    function dataURItoBlob(dataURI) {
      var binary = atob(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {
        type: mimeString
      });
    };

    $scope.downloadExcel = function() {

      Customer.generateExcelReport({customer:$scope.deleted}).success(function(data, status, headers, config) {

        window.location.href = data['url'];

        Customer.removeDownloadFile({path: data['path']}).success(function(response, status, headers, config) {  
          $scope.deleted = [];
        });
      });
      

    };

    $scope.toggleStatus = function toggleStatus(event) {
          
      if(event.target.checked){

        $scope.deleted = [];

        for (var i = 0; i < $scope.data.customers.data.length ; i++) {
          
          $scope.deleted.push($scope.data.customers.data[i].id);
        }
      }else{

        $scope.deleted = [];
        
      }
    };
  }
]);