teleskop.controller('CustomerAccountController', ['$scope', 'CustomerAccount', '$location', 'FileUploader', '$localStorage',
  function ($scope, CustomerAccount, $location, FileUploader, $localStorage) {
    $scope.password = {};

    $scope.init = function () {
      CustomerAccount.init().success(function (data, status, headers, config) {
        $scope.customer = data;
        $scope.grubhub = data.grubhub;
        $scope.uberStores = data.uber_stores;
        $scope.password = {};

        // $scope.uberOAuthUrl = "https://login.uber.com/oauth/v2/authorize?response_type=code&client_id=sUK0dJ0NklV3J_0LNJoWEIG29PilKuIr&scope=&redirect_uri=http://localhost:8000/oauth/uber?customer_id=" + $scope.customer.customer.id
        $scope.uberOAuthUrl = "https://login.uber.com/oauth/v2/authorize?response_type=code&client_id=wxCheS_NoyNmk_kKuZXzhPRTW1fdE1Xz&scope=eats.pos_provisioning&redirect_uri=https://hodxpress.com/oauth/uber?customer_id=" + $scope.customer.customer.id

        $scope.findPrinters();
      });
    };

    $scope.updateCustomerInfo = function () {
      CustomerAccount.updateCustomerInfo($scope.customer).success(function (data, status, headers, config) {
        $scope.authenticatedUser.full_name = $scope.customer.user.first_name + ' ' + $scope.customer.user.last_name;
        $scope.customer.customer = data.customer;
        $scope.customer.user = data.user;
        Messenger().post('Your information has been updated!');
      }).error(function (data) {
        Messenger().post({ message: data.message, type: "error" });
      });
    };

    $scope.grubhubIntegration = function () {
      CustomerAccount.grubhubIntegration($scope.grubhub).success(function (data, status, headers, config) {
        Messenger().post('Grubhub integration has been updated!');
      }).error(function (data) {
        Messenger().post({ message: data.message, type: "error" });
      });
    };

    $scope.updateStoreAutoAccept = function (id, status) {
      if (confirm("Do you want to change this option?")) {
        CustomerAccount.updateStoreAutoAccept({ store_id: id, status: status }).success(function (data, status, headers, config) {
          Messenger().post('Uber Auto Accept setting has been successfully updated!');
          $scope.init();
        }).error(function (data) {
          Messenger().post({ message: data.message, type: "error" });
        });
      }
    };

    $scope.removeUberConnect = function (id, status) {
      if (confirm("Do you want to remove this integration?")) {
        CustomerAccount.removeUberConnect({ store_id: id }).success(function (data, status, headers, config) {
          Messenger().post('Uber integration has been successfully removed!');
          $scope.init();
        }).error(function (data) {
          Messenger().post({ message: data.message, type: "error" });
        });
      }
    };

    $scope.updatePassword = function () {
      CustomerAccount.updatePassword($scope.password).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $scope.password = {};
      }).error(function (data) {
        Messenger().post({ message: data.message, type: "error" });
      })
    };

    $scope.printerName = localStorage.getItem("printerName") ? localStorage.getItem("printerName") : '';
    $scope.printerList = [];
    $scope.savePrinter = function () {
      localStorage.setItem("printerName", $scope.printerName);
      Messenger().post('Successfull!');
    };

    $scope.findPrinters = function () {
      qz.security.setCertificatePromise(function (resolve, reject) {
        resolve("-----BEGIN CERTIFICATE-----\n" +
        "MIIFHDCCAwSgAwIBAgIQNzkyMDI0MDQwNTE0MjgxNzANBgkqhkiG9w0BAQsFADCB\n" +
        "mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry\n" +
        "aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ\n" +
        "cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz\n" +
        "dHJpZXMuY29tMB4XDTI0MDQwNTE0MjgxN1oXDTI1MDQwNTIxNDYwN1owgdcxCzAJ\n" +
        "BgNVBAYMAlVTMRMwEQYDVQQIDApOZXcgSmVyc2V5MRIwEAYDVQQHDAlGYWlyIExh\n" +
        "d24xEjAQBgNVBAoMCUhPRFhwcmVzczESMBAGA1UECwwJSE9EWHByZXNzMRIwEAYD\n" +
        "VQQDDAlIT0RYcHJlc3MxJTAjBgkqhkiG9w0BCQEMFnNhdmFzLm1hemljaUBnbWFp\n" +
        "bC5jb20xPDA6BgNVBA0MM3JlbmV3YWwtb2YtZjQyZmQ1NjI1MmMzNGJiNGJhZDNi\n" +
        "NzRlOTA2ODBmYzA4N2Y5NjE2OTCCASAwCwYJKoZIhvcNAQEBA4IBDwAwggEKAoIB\n" +
        "AQDUFkQYDrqsm1NUY4AuV/kb8lRYhNSQ6UfTDf90VAMVLGyO9ShzwJ/dSMWT6SyI\n" +
        "mrIoZVVeCaWU1YJtP4ym76KEoi0ZiWwW2GG4+DUY0ogdT+dsgAvLHIhef+WtA2rN\n" +
        "SemuoblMcaGEAPFNlbErVoQj2Aa7d6/a+nmmaF02NC6Jmf//Zk457WzNfFhzMQMA\n" +
        "a8SmaFt0oZ1PuLfzTyi4m8IQuEHWIMXGYl4d8fbSPeSW8Wt9i+IsO1fuy5pVZ0Ou\n" +
        "tzVDYKwthIc2keLpugnk3cGidacQI+y1Icfx+Uy9lRaGTaDEgvX3yDacsYQdZarq\n" +
        "0mxAV/nX+erQ/c49IbGm+eR3AgMBAAGjIzAhMB8GA1UdIwQYMBaAFJCmULeE1Lnq\n" +
        "X/IFhBN4ReipdVRcMA0GCSqGSIb3DQEBCwUAA4ICAQAw1HulYbyYW53lnihQXSDc\n" +
        "PvxYBhs1xbvUU2MAMqczrj6x+yVNFXB9oImWuFfR29EjCu5AY74MzgRwlPX8gVLc\n" +
        "uTr35tlCPdbSPPbuQz41SFSid9r0dDU73EZnwC1KaMKKnz/DslKEvZyx7LAr76az\n" +
        "VH43oOnQHTzPSfTaSONWLuOCVEq9xt6EAqDNKcIIo5XgdHaCt+6S/YUnlT8dWtum\n" +
        "0jj2QGwp/xdzhHEdlq7GVWvRVYojSMXyMlroCbQOiZah9kzYCIYhwYWHbIITXY0d\n" +
        "Bbtgy0ZNVDHwhoQj7qL3v/NisO6cYStvBLscXQLuAIskjpmRGxwncpYhUzC9ybRE\n" +
        "Lkwy8GoHJYk2w6znFAYD8PHjMMA3/EIAUdyyS0tWEed2ufazAksOcN3dHm1ZJ8Ws\n" +
        "PHfafS4I0+XKkcuWNJxC5bsdgXgNtC5cSko9diqa6XfUPSiFrWuhFoI+ut27jWqT\n" +
        "nIMtweSiShZoHqM7e6nb4sMl8ls1NcH2yTRPg5u8rgG01GpF9c4bIEdAiAkE9ijn\n" +
        "yZZwz+slZAKnSUmtqNQ+zgsso1KHjQaTch/o4bBWvsxmjK/jEFOwRu8t1LXeeVT0\n" +
        "BR0ifqX0an8uqdIKH3K6vIe4494j8/dBcKydz3HdaJtchKrCKiuuLyK0/8rRW7wJ\n" +
        "M6zK5MFz6HMeLe6yYnAQqg==\n" +
        "-----END CERTIFICATE-----\n" +
        "--START INTERMEDIATE CERT--\n" +
        "-----BEGIN CERTIFICATE-----\n" +
        "MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT\n" +
        "MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ\n" +
        "bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG\n" +
        "A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx\n" +
        "emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow\n" +
        "gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0\n" +
        "cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM\n" +
        "EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1\n" +
        "c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU\n" +
        "iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H\n" +
        "YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM\n" +
        "GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH\n" +
        "EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh\n" +
        "0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX\n" +
        "xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1\n" +
        "9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE\n" +
        "ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD\n" +
        "2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX\n" +
        "6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY\n" +
        "5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ\n" +
        "plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S\n" +
        "BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ\n" +
        "eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k\n" +
        "gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT\n" +
        "x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu\n" +
        "pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw\n" +
        "2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp\n" +
        "rbO7BnjW\n" +
        "-----END CERTIFICATE-----");
      });
      if (!qz.websocket.isActive()) {
        qz.websocket.connect().then(function () {
          console.log('Active', 'success');
          qz.printers.find().then(function (data) {
            console.log(data)
            $scope.printerList = data;
          }).catch(function (e) { console.error(e); });
        }).catch(function (err) {
          console.log('Bağlantı Hatası', 'danger');
          if (err.target != undefined) {
            if (err.target.readyState >= 2) { //if CLOSING or CLOSED
              console.log("QZ bağlantısı sonlandırıldı.");
            } else {
              console.log("Bağlantı sağlanırken bir hata oluştu.");
              console.error(err);
            }
          } else {
            console.log(err);
          }
        });
      } else {
        console.log('Active', 'success');
        qz.printers.find().then(function (data) {
          console.log(data)
          $scope.printerList = data;
        }).catch(function (e) { console.error(e); });
      }
    }

    var logo = $scope.logo = new FileUploader({
      url: '/api/upload-customer-logo',
      headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
      autoUpload: true
    });

    logo.filters.push({
      name: 'formatFilter',
      fn: function (item /*{File|FileLikeObject}*/, options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
      }
    });

    logo.onAfterAddingFile = function (fileItem) {
      $scope.proccessProfile = true;
      $scope.pending = true;
    };

    logo.onCompleteItem = function (item, response, status, headers) {
      $scope.proccessProfile = false;
      $scope.customer.customer.logo = response.file;
      if (response.status == 'success')
        $scope.pending = false;
    }
  }
]);
