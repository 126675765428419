teleskop.
factory('CustomerAccount', ['$http', function($http) {
    return{
        init: function() {
            return $http.get('api/customer-info');
        },
        updateCustomerInfo: function(data) {
            return $http.post('api/update-customer-info',data);
        },
        updatePassword: function(data) {
            return $http.post('api/update-customer-password',data);
        },
        grubhubIntegration: function(data) {
            return $http.post('api/grubhub-integration',data);
        },
        updateStoreAutoAccept: function(data) {
            return $http.post('api/update-uber-store-auto-accept',data);
        },
        removeUberConnect: function(data) {
            return $http.post('api/remove-uber-store',data);
        },
    };
}]);