teleskop.
factory('Setting', ['$http', function($http) {
    return {
      getWorkSteps: function() {
        return $http.get('api/work-steps');
      },
      getSetting: function() {
        return $http.get('api/setting');
      },
      updateSetting: function(data) {
        return $http.post('api/setting',data);
      },
      updateOrderSetting: function(data) {
        return $http.post('api/update-order-setting',data);
      },
      updateCenter: function(data) {
        return $http.post('api/update-center',data);
      },
      getSms: function() {
        return $http.get('api/sms');
      },
      saveSms: function(data) {
        return $http.post('api/save-sms',data);
      },
      saveStep: function(data) {
        return $http.post('api/save-step',data);
      },
      saveTaskStep: function(data) {
        return $http.post('api/save-task-step',data);
      },
      sortWorkSteps: function(data) {
        return $http.post('api/sort-step',data);
      },
      sortTaskSteps: function(data) {
        return $http.post('api/sort-task-step',data);
      },
      deleteStep: function(id) {
        return $http.delete('api/step/' + id);
      },
      forms: function() {
        return $http.get('api/forms');
      },
      getForm: function(id) {
        return $http.get('api/form/' + id);
      },
      deleteForm: function(id) {
        return $http.delete('api/form/' + id);
      },
      saveForm: function(data) {
        return $http.post('api/save-form',data);
      },
      updateForm: function(data) {
        return $http.post('api/update-form',data);
      }
    };
}]);