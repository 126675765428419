teleskop.
  factory('UberMenu', ['$http', function ($http) {
    return {
      init: function (id) {
        return $http.get('api/uber-menus?store_id=' + id);
      },
      new: function () {
        return $http.get('api/new-uber-menu');
      },
      show: function (id) {
        return $http.get('api/uber-menu/' + id);
      },
      save: function (data) {
        return $http.post('api/save-uber-menu', data);
      },
      delete: function (data) {
        return $http.post('api/delete-uber-menu', data);
      },
      update: function (data) {
        return $http.post('api/update-uber-menu', data);
      },
      changeStoreStatus: function (data) {
        return $http.post('api/update-uber-status', data);
      },
    };
  }]);