teleskop.controller('OrderController', ['$scope', 'Order', '$location', '$filter', '$interval',
	'ngAudio', '$routeParams', '$rootScope', '$window', '$http', '$timeout', 'RouteList',
	function ($scope, Order, $location, $filter, ngAudio, $routeParams, $rootScope, $window, $http, $interval, $timeout, RouteList) {
		var liveInterval = null;

		// var successSound = ngAudio.load("sounds/success.mp3");
		var audio = new Audio('sounds/success.mp3');

		var routeOptimizationDirectionsDisplay = [];
		var routeOptimizationWorkerOrders = [];

		$scope.statuses = {
			work: 0,
			worker: 0
		};
		$scope.filterPanel = false;
		$scope.tab = 0;

		$scope.filterData = {
			searchTerm: '',
			workers: [],
			workSteps: [],
			workTypes: localStorage.getItem('filter_workType') ? [localStorage.getItem('filter_workType')] : [],
			created_at: {
				startDate: null,
				endDate: null
			},
			scheduled_time: {
				startDate: null,
				endDate: null
			}
		}

		var calculatedOrder = [];

		if (window.localStorage.getItem('map_detail') !== null) {
			$scope.mapDetail = JSON.parse(window.localStorage.getItem('map_detail'));
		} else {
			$scope.mapDetail = {
				traffic: false,
				detail_name: true,
				darkColor: false
			}
		}
		var geocoder, interval;

		$scope.getWorkerOrders = function (item) {
			return item.worker_id === $scope.sWorker.id;
		}

		$scope.$on('teamChange', function (event, data, nextParams, fromState) {
			$scope.filterData.team = data !== null ? data.id : null;
			$scope.init();
		});

		$scope.export = function (page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.searchOrder = function (term) {
			$scope.filterData.term = term;
			$scope.init();
		}

		$scope.newRouteData = function () {
			$location.path('routes');
			setTimeout(() => {
				$scope.newData();
			}, 300);
		}

		if ($location.path() == '/map') {
			const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
			console.log('interval_id: ', interval_id);
			for (let i = 1; i < interval_id; i++) {
				window.clearInterval(i);
			}
			liveInterval = setInterval(() => {
				$scope.init('interval');
			}, 12000);
			localStorage.setItem('liveInterval', liveInterval);
		}

		$scope.$on('$destroy', function () {
			window.clearInterval(liveInterval);
			liveInterval = null
		});

		$scope.markers = {
			'order': [],
			'worker': [],
			'customers': []
		};

		$scope.infowindowsOrder = [];
		$scope.infowindows = [];
		$scope.orderCount = 0;
		$scope.init = function (type) {
			if (typeof type !== 'undefined' && type == 'clear') {
				$scope.filterData = {
					term: '',
					workers: [],
					workSteps: [],
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
			} else {
				localStorage.setItem('filter_workType', $scope.filterData.workTypes);
			}

			$scope.getPage = function (page) {
				$scope.filterData.page = page;
				$scope.init();
			}

			Order.dashboard($scope.filterData).success(function (data, status, headers, config) {
				if (typeof type !== undefined && type == 'export') {
					$scope.filterData.export = false;
					window.location.href = data.url;
				} else {

					$scope.data = data;
					$scope.paginate = {
						current_page: data.orders.current_page,
						from: data.orders.from,
						last_page: data.orders.last_page,
						per_page: data.orders.per_page,
						to: data.orders.to,
						total: data.orders.total
					};
					$scope.data.orders = data.orders.data;
					$scope.data.steps.unshift({
						id: 0,
						name: 'Created'
					});

					// if($scope.orderCount === 0) {
					// 	$scope.orderCount = data.orders.total;
					// }

					var bounds = new google.maps.LatLngBounds();
					if (!$scope.map) {
						$scope.map = new google.maps.Map(document.getElementById('map'), { //this is map
							mapTypeId: google.maps.MapTypeId.ROADMAP,
							center: new google.maps.LatLng(40.6971494, -74.1966941),
							mapTypeControl: true,
							rotateControl: true,
							scaleControl: true,
							fullscreenControl: true,
							streetViewControl: false,
							zoomControlOptions: {
								position: google.maps.ControlPosition.LEFT_BOTTOM,
							},
							fullscreenControlOptions: {
								position: google.maps.ControlPosition.LEFT_CENTER,
							},
							styles: [{
								"elementType": "geometry",
								"stylers": [{
									"color": "#f5f5f5"
								}]
							},
							{
								"elementType": "labels.icon",
								"stylers": [{
									"visibility": "off"
								}]
							},
							{
								"elementType": "labels.text.fill",
								"stylers": [{
									"color": "#616161"
								}]
							},
							{
								"elementType": "labels.text.stroke",
								"stylers": [{
									"color": "#f5f5f5"
								}]
							},
							{
								"featureType": "administrative.land_parcel",
								"elementType": "labels.text.fill",
								"stylers": [{
									"color": "#bdbdbd"
								}]
							},
							{
								"featureType": "landscape.natural",
								"elementType": "geometry.stroke",
								"stylers": [{
									"color": "#4247bd"
								}]
							},
							{
								"featureType": "poi",
								"elementType": "geometry",
								"stylers": [{
									"color": "#eeeeee"
								}]
							},
							{
								"featureType": "poi",
								"elementType": "labels.text.fill",
								"stylers": [{
									"color": "#757575"
								}]
							},
							{
								"featureType": "poi.business",
								"stylers": [{
									"visibility": "off"
								}]
							},
							{
								"featureType": "poi.park",
								"elementType": "geometry",
								"stylers": [{
									"color": "#e5e5e5"
								}]
							},
							{
								"featureType": "poi.park",
								"elementType": "labels.text",
								"stylers": [{
									"visibility": "off"
								}]
							},
							{
								"featureType": "poi.park",
								"elementType": "labels.text.fill",
								"stylers": [{
									"color": "#9e9e9e"
								}]
							},
							{
								"featureType": "road",
								"elementType": "geometry",
								"stylers": [{
									"color": "#ffffff"
								}]
							},
							{
								"featureType": "road.arterial",
								"elementType": "labels.text.fill",
								"stylers": [{
									"color": "#757575"
								}]
							},
							{
								"featureType": "road.highway",
								"elementType": "geometry",
								"stylers": [{
									"color": "#dadada"
								}]
							},
							{
								"featureType": "road.highway",
								"elementType": "labels.text.fill",
								"stylers": [{
									"color": "#616161"
								}]
							},
							{
								"featureType": "road.local",
								"elementType": "labels.text.fill",
								"stylers": [{
									"color": "#9e9e9e"
								}]
							},
							{
								"featureType": "transit.line",
								"elementType": "geometry",
								"stylers": [{
									"color": "#e5e5e5"
								}]
							},
							{
								"featureType": "transit.station",
								"elementType": "geometry",
								"stylers": [{
									"color": "#eeeeee"
								}]
							},
							{
								"featureType": "water",
								"elementType": "geometry",
								"stylers": [{
									"color": "#c9c9c9"
								}]
							},
							{
								"featureType": "water",
								"elementType": "geometry.fill",
								"stylers": [{
									"color": "#bdd1e8"
								}]
							},
							{
								"featureType": "water",
								"elementType": "labels.text.fill",
								"stylers": [{
									"color": "#9e9e9e"
								}]
							}]
						});

						google.maps.event.addListener($scope.map, "click", function (event) {
							$scope.infowindowsOrder.forEach(element => {
								element.close();
							});
						});
					}

					$scope.data.customers.forEach(element => {
						var findOrder = $scope.data.orders.findIndex(o => o.company_customer_id == element.id);
						if (findOrder > -1) {
							$scope.createCustomerMarker(element);
							if (element.latitude.length > 1) {
								bounds.extend($scope.markers['customers'][element.id].getPosition());
							}
						}

					});

					$scope.data.workers.forEach(element => {
						$scope.createWorkerMarker(element);
						if (!$scope.authenticatedUser.setting.dashboard_map && typeof $scope.markers['worker'][element.id] !== 'undefined') {
							if (element.lat.length > 1 || !element.status) {
								bounds.extend($scope.markers['worker'][element.id].getPosition());
							}
						}
					});

					for (var i = $scope.data.orders.length - 1; i >= 0; i--) {
						var orderItem = $scope.data.orders[i];
						// if (!orderItem.worker_id && (!orderItem.status || (orderItem.status && orderItem.status.value !== 'complete'))) {
						// assigned order
						// } else {
						if (!$scope.data.orders[i].worker) {
							$scope.createOrderMarker($scope.data.orders[i]);
							if (!$scope.authenticatedUser.setting.dashboard_map && typeof $scope.markers['order'][$scope.data.orders[i].id] !== 'undefined') {
								bounds.extend($scope.markers['order'][$scope.data.orders[i].id].getPosition());
							}
						}
					}

					$scope.createHeatmap($scope.data.customers);

					if (
						($scope.data.orders.length > 0)
					) {
						if (type !== 'interval') {
							$scope.map.fitBounds(bounds);
						}

						// if($scope.orderCount != data.orders.total) {
						// 	$scope.map.fitBounds(bounds);
						// 	$scope.orderCount = data.orders.total
						// }
					} else {
						$scope.map.setCenter(new google.maps.LatLng(40.6971494, -74.1966941));
						$scope.map.setZoom(10);
					}

					var trafficLayer = new google.maps.TrafficLayer();
					$scope.$watch('mapDetail', function (value) {
						if (value.traffic)
							trafficLayer.setMap($scope.map);
						else
							trafficLayer.setMap(null);
						window.localStorage.setItem('map_detail', JSON.stringify(value));
					}, true);

					if (type !== 'interval') {
						var mcOptions = {
							styles: [{
								url: "/img/cluster.png",
								width: 100,
								height: 100,
								textSize: 16,
								textColor: "white",
							}],
							maxZoom: 10,
							gridSize: 30,
						};
						var markers = [];
						$scope.markers['order'].forEach(element => {
							markers.push(element)
						});
						new MarkerClusterer(
							$scope.map,
							markers,
							mcOptions
						);
					}

					/*
						interval = setInterval(function( ) {
							Order.workers({team: $scope.selectedTeamName.id }).success(function(data, status, headers, config) {
								for (var i = $scope.data.workers.length - 1; i >= 0; i--) {
									if($scope.data.workers[i].lat.length != 0) 
										$scope.createWorkerMarker($scope.data.workers[i]);
								}
							});
						}, 15000);
					*/
				}

			});
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing map...')
			$scope.init();
		}, true);

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				workers: [],
				workSteps: [],
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
			$scope.filterProp = [];
			$scope.refreshData('map');
		}

		$scope.$on('$routeChangeStart', function (next, current) {
			//clearInterval(interval);
		});

		$scope.$on('new_order', function (event, data, nextParams, fromState) {
			if (typeof data[0] !== 'undefined') {
				data.orders.forEach(function (element) {
					addOrder(element);
				}, this);
			} else {
				if (typeof data.order !== 'undefined') {
					addOrder(data.order);
				} else {
					data.orders.forEach(function (element) {
						addOrder(element);
					}, this);
				}
			}
			audio.play();
		});

		function addOrder(data) {
			$scope.infowindowsOrder.forEach(function (element) {
				element.close();
			}, this);
			if ($scope.filterData.scheduled_time.startDate == null) {
				var order = find($scope.data.orders, data.id);
				if (typeof order !== 'undefined') {
					angular.merge(order, data);
				} else {
					order = data;
					$scope.data.orders.unshift(data);
				}

				$scope.createOrderMarker(order);
				// $scope.infowindowsOrder[order.id].open($scope.map, $scope.markers['order'][order.id]);
			} else {
				var date1 = moment(data.scheduled_time).format('MM/DD/YYYY'),
					date2 = moment($scope.filterData.scheduled_time.startDate).format('MM/DD/YYYY');
				if (date1 == date2) {
					var order = find($scope.data.orders, data.id);
					if (typeof order !== 'undefined') {
						angular.merge(order, data);
					} else {
						order = data;
						$scope.data.orders.unshift(data);
					}

					$scope.createOrderMarker(order);
					// $scope.infowindowsOrder[order.id].open($scope.map, $scope.markers['order'][order.id]);
				}
			}
		}

		$scope.$on('updateOrder', function (event, data, nextParams, fromState) {
			var order = find($scope.data.orders, data.id);
			angular.merge(order, data);
			$scope.createOrderMarker(order);
		});

		$scope.$on('refreshDashboard', function (event, data, nextParams, fromState) {
			$scope.init();
		});

		$scope.$on('deleteOrder', function (event, data, nextParams, fromState) {
			var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {
				id: data.id
			})[0]);
			if (index > -1)
				$scope.data.orders.splice(index, 1);
			$scope.markers['order'][data.order.id].setMap(null);
		});

		$scope.disableEnter = function (e) {
			if (e.keyCode == 13) {
				return false;
			}
		}

		$scope.getWorkerStatus = function (status) {
			if (typeof $scope.data !== 'undefined') {
				if (status == 1) {
					return $scope.data.workers.filter(function (item) { return item.status && item.orders?.length > 0 })?.length;
				} else if (status == 0) {
					return $scope.data.workers.filter(function (item) { return item.status && item.orders?.length === 0 })?.length;
				} else if (status == 2) {
					return $scope.data.workers?.length;
				}
			}
		}

		$scope.getSidebarWorkers = function (status, search) {
			var data = [];
			if (typeof $scope.data !== 'undefined' && $scope.data.workers) {
				// 0 free
				// 1 bussy
				// 2 all 
				if (search) {
					data = $scope.data.workers.filter(function (item) { return item.full_name.toUpperCase().includes(search.toUpperCase()) });
				} else {
					if (status == 1) {
						data = $scope.data.workers.filter(function (item) { return item.status && item.on_way_orders.length > 0 });
					} else if (status == 0) {
						data = $scope.data.workers.filter(function (item) { return item.status && item.on_way_orders.length === 0 });
					} else if (status == 2) {
						data = $scope.data.workers;
					}
				}
			}

			return data;
		}

		$scope.getSidebarOrders = function (status, searchOrderTerm) {
			var data = [];
			if (typeof $scope.data !== 'undefined' && $scope.data.orders) {

				if (searchOrderTerm) {
					data = $scope.data.orders.filter(function (item) { return (item.location.name.toUpperCase().includes(searchOrderTerm.toUpperCase())) || (item.location.address.toUpperCase().includes(searchOrderTerm.toUpperCase())) });
				} else {
					if (status === 0) {
						data = $scope.data.orders.filter(function (item) { return !item.worker_id });
					} else if (status === 1) {
						data = $scope.data.orders.filter(function (item) { return item.worker_id && (!item.status || (item.status && item.status.value !== 'complete')) });
					} else if (status === 2) {
						data = $scope.data.orders.filter(function (item) { return item.status && (!item.status || (item.status && item.status.value === 'complete')) });
					}
				}

			}

			return data.sort(function (a, b) {
				return moment(a.scheduled_time) - moment(b.scheduled_time);
			});
		}

		$scope.setStatus = function (status, type) {
			$scope.statuses[type] = status;
		}

		$rootScope.modalKey = 0;
		$scope.assignMultiOrder = function () {
			$scope.assignWorkers = $scope.data.workers;
			++$rootScope.modalKey;
			$scope.assigned_worker_id = null;
			$('#assignMultiOrder').modal('show');
		}

		$scope.assignMultiOrderDriver = function () {
			var data = {
				worker_id: $scope.assigned_worker_id,
				orders: $scope.selectedRouteOrder
			};
			Order.assignOrdersToWorker(data).success(function (data, status, headers, config) {
				$('#assignMultiOrder').modal('hide');

				$scope.selectedRouteOrder.forEach(element => {
					$scope.markers['order'][element].setMap(null);
				});
				Messenger().post('Order(s) has been assigned successfully!');
				$scope.selectedRouteOrder = [];

				setTimeout(() => {
					// $scope.init('assign');
				}, 500);
			}).error(function (data) {
				alert(data)
				$('#orderInfo').modal('show');
			});
		}

		$scope.optimizeStep = false;
		$scope.selectedRouteOrder = [];
		$scope.addSelectedOrderForOptimization = function (item) {
			if ($scope.selectedRouteOrder.indexOf(item) > -1) {
				$scope.selectedRouteOrder.splice($scope.selectedRouteOrder.indexOf(item), 1);
			} else {
				$scope.selectedRouteOrder.push(item);
			}
		}

		$scope.optimizeStep = false;
		$scope.selectedWorker = [];
		$scope.addSelectedWorker = function (item) {
			if ($scope.selectedWorker.indexOf(item) > -1) {
				$scope.selectedWorker.splice($scope.selectedWorker.indexOf(item), 1);
			} else {
				$scope.selectedWorker.push(item);
			}
		}

		$scope.makeArrived = function () {
			var data = {
				workers: $scope.selectedWorker
			};
			Order.makeArrivedWorker(data).success(function (data, status, headers, config) {
				$scope.init('assign');
				Messenger().post('Worker(s) has been arrived successfully!');
				$scope.selectedWorker = [];
			}).error(function (data) {
				alert(data)
			});
		}

		$scope.checkLastLocationTime = function (worker) {
			if (worker.location_info && worker.location_info.length > 0) {
				var extra = angular.fromJson(worker.location_info);
				var now = moment(new Date());
				var end = moment(extra.timestamp);
				var duration = moment.duration(now.diff(end));
				var hours = duration.asHours();
				return hours > 12;
			}
		}

		$scope.detailsWorker = function (worker) {
			$scope.sWorker = worker;
			if ($scope.sWorker.lat.length != 0) {
				Order.getLatAndLngOfWorkerOrders({
					workerID: worker['id'],
					workerLatAndLng: Number($scope.sWorker.lat) + ',' + Number($scope.sWorker.lon)
				}).success(function (data, status, headers, config) {
					// if (typeof data['latAndLng'] != "undefined" && typeof data['ids'] != "undefined") {

					// 	var start = data['latAndLng'][0];
					// 	var end = data['latAndLng'][data['latAndLng'].length - 1];
					// 	var waypts = [];
					// 	for (var i = 1; i < data['latAndLng'].length - 1; i++) {
					// 		token = data['latAndLng'][i].split(',');
					// 		var point = new google.maps.LatLng(Number(token[0]), Number(token[1]));
					// 		waypts.push({
					// 			'location': point,
					// 			"stopover": true
					// 		});
					// 	}
					// 	makeRoute(start, end, waypts, data['ids']);

					// } else if (typeof data['latAndLng'] === "undefined" && typeof data['ids'] === "undefined" && routeOptimizationWorkerOrders.length > 0) {

					// 	for (var i = 1; i < routeOptimizationWorkerOrders.length; i++) {
					// 		$scope.markers['order'][routeOptimizationWorkerOrders[i]].setLabel({
					// 			text: " ",
					// 			color: 'white',
					// 			fontSize: "10px",
					// 			fontWeight: 'bold'
					// 		});
					// 	}
					// 	routeOptimizationDirectionsDisplay.setMap(null);
					// }

					var location = new google.maps.LatLng(Number($scope.sWorker.lat), Number($scope.sWorker.lon));
					$scope.map.panTo(location);
					$scope.map.setCenter(location);
					$scope.infowindows.forEach(function (element) {
						element.close();
					}, this);
					infoWindow(worker, 'open');

				});
			}
		}

		$scope.clickWork = function (work) {
			if (!$scope.infowindowsOrder[work.id]) {
				$scope.getOrderDetails(work)
				return;
			}
			$scope.selectedOrderId = work.id;
			$scope.infowindowsOrder.forEach(function (element) {
				element.close();
			}, this);
			$scope.infowindowsOrder[work.id].open($scope.map, $scope.markers['order'][work.id]);
			$scope.map.panTo(new google.maps.LatLng(Number(work.location.latitude), Number(work.location.longitude)));
			$scope.map.setZoom(13);
			google.maps.event.addListener($scope.infowindowsOrder[work.id], 'closeclick', function () {
				$scope.selectedOrderId = 0;
			});
		}

		$scope.closeWorker = function () {
			delete $scope.sWorker;
			for (var i = 1; i < routeOptimizationWorkerOrders.length; i++) {
				$scope.markers['order'][routeOptimizationWorkerOrders[i]].setLabel({
					text: " ",
					color: 'white',
					fontSize: "10px",
					fontWeight: 'bold'
				});
			}
			if (routeOptimizationDirectionsDisplay.length > 0)
				routeOptimizationDirectionsDisplay.setMap(null);
		}

		$scope.filterWorker = function (worker) {
			if ($scope.statuses['worker'] == 0)
				return worker.status === 0;
			else if ($scope.statuses['worker'] == 1)
				return worker.status === 1;
			else
				return true;
		}

		$scope.getWorkStatus = function (status) {
			if (typeof $scope.data !== 'undefined' && typeof $scope.data.orders !== 'undefined') {
				if (status == 'continue') {
					return $filter('filter')($scope.data.orders, function (value) {
						return value.status == null || (value.status.id != $scope.data.complete && value.status.id != $scope.data.fail);
					}).length;
				} else if (status == 'cancel') {
					return $filter('filter')($scope.data.orders, function (value) {
						return value.status != null && value.status.id == $scope.data.fail;
					}).length;
				} else if (status == 'success') {
					return $filter('filter')($scope.data.orders, function (value) {
						return value.status != null && value.status.id == $scope.data.complete;
					}).length;
				} else if (status == 'assign') {
					return $filter('filter')($scope.data.orders, function (value) {
						return value.worker_id != 0;
					}).length;
				} else if (status == 'not-assign') {
					return $filter('filter')($scope.data.orders, function (value) {
						return value.worker_id == 0;
					}).length;
				} else if (status == 'other') {
					return $filter('filter')($scope.data.orders, function (value) {
						return value.status != null && (value.status.id == $scope.data.complete || value.status.id == $scope.data.fail);
					}).length;
				}
			}
		}

		$scope.filterWork = function (work) {
			if ($scope.statuses['work'] == 1) {
				return work.status == null || (work.status.id != $scope.data.complete && work.status.id != $scope.data.fail);
			} else if ($scope.statuses['work'] == 0) {
				return work.status != null && (work.status.id == $scope.data.fail);
			} else if ($scope.statuses['work'] == 2) {
				return work.status != null && (work.status.id == $scope.data.complete);
			}
		}

		$scope.refreshOrders = function () {
			Order.refreshOrders().success(function (data, status, headers, config) {
				$scope.data.orders = data.orders;
			});
		};

		$scope.$on('worker_answer', function (event, data, nextParams, fromState) {
			var order = find($scope.data.orders, data.order.id);
			angular.merge(order, data.order);
			$scope.createOrderMarker(order);
			Messenger().post(data.order.location.name + ' order ' + order.status.name + ' by ' + data.order.worker.full_name, 'Order Status!');
			Push.create('Order Status!', {
				body: data.order.location.name + ' order ' + order.status.name + ' by ' + data.order.worker.full_name,
				icon: {
					x32: 'https://s3.us-west-2.amazonaws.com/hodxpress/' + $scope.authenticatedUser.logo
				},
				timeout: 3000
			});
		});

		$scope.$on('location_update', function (event, data, nextParams, fromState) {
			var workerFind = find($scope.data.workers, data.worker.id);
			$scope.createWorkerMarker(data.worker);
			angular.merge(workerFind, data.worker);
		});

		$scope.$on('delete_order', function (event, data, nextParams, fromState) {
			$scope.refreshOrders();
		});

		$scope.$on('update_order', function (event, data, nextParams, fromState) {
			$scope.refreshOrders();
		});

		$scope.$on('worker_status', function (event, data, nextParams, fromState) {
			if ($scope.selectedTeamName.id != null && $scope.selectedTeamName.id != data.worker.worker.team_id) return false;

			if (data.status == 1) {
				var message = data.worker.full_name + ' is Online!';
				Messenger().post(message);
				Push.create('Driver Status!', {
					body: message,
					icon: {
						x32: 'https://s3.us-west-2.amazonaws.com/hodxpress/' + $scope.authenticatedUser.logo
					},
					timeout: 3000
				});
			} else if (data.status == 0) {
				var message = data.worker.full_name + ' is Offline!';
				Messenger().post({
					message: message,
					type: 'error',
					showCloseButton: true
				});
				Push.create('Driver Status!', {
					body: message,
					icon: {
						x32: 'https://s3.us-west-2.amazonaws.com/hodxpress/' + $scope.authenticatedUser.logo
					},
					timeout: 3000
				});
			} else if (data.status == 3) {
				if (data.gps) {
					var message = data.worker.full_name + ' GPS\'ni Açtı!';
					Messenger().post(message);
				} else {
					var message = data.worker.full_name + ' GPS\'ni Kapattı!';
					Messenger().post({
						message: message,
						type: 'error',
						showCloseButton: true
					});
				}
			} else if (data.status == 'note') {
				var order = find($scope.data.orders, data.log.order_id);
				if (typeof order !== 'undefined') {
					if (typeof order.notes === 'undefined') order.notes = [];
					order.notes.push(data.log);
				}
				Messenger().post({
					message: data.worker.full_name + ',  #' + data.log.order_id + ' nolu işe "' + data.log.reason + '" notunu ekledi!',
					type: 'success',
					showCloseButton: true
				});
				Push.create('İş Notu!', {
					body: data.worker.full_name + ',  #' + data.log.order_id + ' nolu işe "' + data.log.reason + '" notunu ekledi!',
					icon: {
						x32: 'https://s3.us-west-2.amazonaws.com/hodxpress/' + $scope.authenticatedUser.logo
					},
					timeout: 3000
				});
			}
			if (data.status != 'note') {
				var worker = find($scope.data.workers, data.worker.worker.id);
				worker.status = data.status;
				worker.location_info = data.worker.worker.location_info;
				$scope.changeWorkerMarker(worker);
			}
		});

		function find(data, find) {
			var index = data.indexOf($filter('filter')(data, {
				id: find
			})[0]);
			if (index > -1)
				return data[index];
		}

		$scope.updateOrder = function (e) {
			Order.updateOrder($scope.editOrderData).success(function (data, status, headers, config) {
				var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {
					id: $scope.editOrderData.id
				})[0]);
				if (index > -1) {
					$scope.data.orders[index] = data;
					$('#orderEdit').modal('hide');
				}
			}).error(function () {
				alert('Şuan bir hata oluştu lütfen daha sonra yeniden deneyiniz.');
			});
		};

		$scope.calculateDiff = function (start, end) {
			return moment(end).diff(moment(start), 'minute');
		}

		$scope.getBatteryInfo = function (worker) {
			if (worker.location_info.length != 0) {
				var extra = angular.fromJson(worker.location_info);
				if (typeof extra.battery !== 'undefined')
					return parseFloat(extra.battery * 100).toFixed(0);
			} else {
				return 'Bilgi Yok';
			}
		}

		$scope.getLastLocationTime = function (worker) {
			if (worker.location_info.length > 0) {
				var extra = angular.fromJson(worker.location_info);
				return moment(extra.timestamp).format('D MMM, HH:mm');
			} else {
				return 'Bilgi Yok';
			}
		}

		function infoWindow(data, type) {
			if ($scope.markers['worker'][data.id]) {

				var extra = {};
				try {
					extra = angular.fromJson(data.location_info)
				} catch (error) {

				}
				var content = '<div id="iw-container">' +
					'<div class="iw-content" style="display: flex;align-items: center;justify-content: space-between;">' +
					'<img src="https://s3.us-west-2.amazonaws.com/hodxpress/' + data.profile_photo + '" class="img-circle" height="36" width="36">' +
					'<div class="iw-subTitle">' + data.full_name +
					'<p><strong> Last Location :</strong>' + moment(extra.timestamp).format('D MMM, HH:mm') + '</p>' +

					'<a href="#" onclick="angular.element(this).scope().zoomIn(' + data.lat + ',' + data.lon + ')" class="btn btn-xs btn-primary">Zoom</a>' +
					'<a href="#" onclick="angular.element(this).scope().getWorkerDetails(' + data.id + ')" class="btn btn-xs btn-primary">Details</a> </div>';


				// '<div class="btn-group pull-right">' +
				// '<a href="#" onclick="angular.element(this).scope().zoomIn(' + data.lat + ',' + data.lon + ')" class="btn btn-xs btn-primary">Zoom</a>' +
				// '<a href="#" onclick="angular.element(this).scope().getWorkerDetails(' + data.id + ')" class="btn btn-xs btn-primary">Details</a> </div>';
				//'<p><strong> Şarj : </strong>'+Math.round(extra.battery * 100 )+' %</p>' +
				// '<p><strong> Accuracy : </strong>' + Math.round(extra?.accuracy) + ' Metre</p>';
				// '<p><strong> Hareket Durumu : </strong>'+getActivity(extra.activity)+'</p>';

				// if ($scope.authenticatedUser.setting.last_location_time) {
				// content += '<p><strong> Last Location Date :</strong>' + moment(extra.timestamp).format('D MMM, HH:mm');
				// }
				content += '</div></div>';
				var infowindow = new google.maps.InfoWindow({
					content: content,
					maxWidth: 440
				});
				// google.maps.event.addListener(infowindow, 'closeclick', function () {
				// 	if (routeOptimizationWorkerOrders.length > 0) {
				// 		/* for (var i = 1; i < routeOptimizationWorkerOrders.length; i++) {
				// 			$scope.markers['order'][routeOptimizationWorkerOrders[i]].setLabel({
				// 				text: " ",
				// 				color: 'white',
				// 				fontSize: "10px",
				// 				fontWeight: 'bold'
				// 			});
				// 		} */
				// 		routeOptimizationDirectionsDisplay.setMap(null);
				// 	}
				// });

				if (typeof $scope.infowindows[data.id] === 'undefined') {
					$scope.infowindows[data.id] = infowindow;
					$scope.markers['worker'][data.id].addListener('click', function () {
						infowindow.open($scope.map, $scope.markers['worker'][data.id]);
					});
				} else {
					$scope.infowindows[data.id].setContent(content);
				}

				if (type == 'open') $scope.infowindows[data.id].open($scope.map, $scope.markers['worker'][data.id]);
			}
		}

		$scope.changeWorkerMarker = function (worker) {
			if (typeof $scope.markers['worker'][worker.id] !== 'undefined') {

				var icon = getIcon(worker.icon),
					title = worker.status == 1 ? 'location' : 'offline';

				if (icon) {
					icon = {
						path: icon,
						scale: 0.5,
						scaledSize: new google.maps.Size(24, 24),
						strokeWeight: 0.2,
						strokeColor: worker.icon_color,
						strokeOpacity: 1,
						fillColor: worker.icon_color,
						fillOpacity: 0.9,
					};
					title = "offline";
				} else {
					icon = 'img/markers/worker_' + worker.status + '.png';
				}

				$scope.markers['worker'][worker.id].setIcon(icon);
				$scope.markers['worker'][worker.id].setTitle(title);
				infoWindow(worker);
			}
		}

		$scope.createWorkerMarker = function (worker) {
			if (worker.lat.length < 1) {
				return;
			}
			if (!worker.status) {
				return;
			}
			var orderCount = Number((worker.on_way_orders ? worker.on_way_orders : []).length) + Number((worker.completed_orders ? worker.completed_orders : []).length)
			if (worker.type === 'Car') {
				if (orderCount > 0) {
					var pinImage = {
						url: "/img/markers/drivers/Car_bussy.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/drivers/Car.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else if (worker.type === 'Scooter') {
				if (orderCount > 0) {
					var pinImage = {
						url: "/img/markers/drivers/Scooter_bussy.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/drivers/Scooter.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else if (worker.type === 'Bike') {
				if (orderCount > 0) {
					var pinImage = {
						url: "/img/markers/drivers/Bike_bussy.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/drivers/Bike.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else if (worker.type === 'Motorcycle') {
				if (orderCount > 0) {
					var pinImage = {
						url: "/img/markers/drivers/Motorcycle_bussy.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/drivers/Motorcycle.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else if (worker.type === 'Suv') {
				if (orderCount > 0) {
					var pinImage = {
						url: "/img/markers/drivers/Suv_bussy.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/drivers/Suv.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else if (worker.type === 'Van') {
				if (orderCount > 0) {
					var pinImage = {
						url: "/img/markers/drivers/Van_bussy.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/drivers/Van.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else if (worker.type === 'Truck') {
				if (orderCount > 0) {
					var pinImage = {
						url: "/img/markers/drivers/Truck_bussy.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/drivers/Truck.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else {
				if (orderCount > 0) {
					var pinImage = {
						url: "/img/markers/drivers/Car_bussy.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/drivers/Car.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			}

			if (typeof $scope.markers['worker'][worker.id] !== 'undefined') {
				var location = new google.maps.LatLng(Number(worker.lat), Number(worker.lon));
				$scope.markers['worker'][worker.id].setDuration(1000);
				$scope.markers['worker'][worker.id].setEasing("swing");
				$scope.markers['worker'][worker.id].setPosition(location);
				$scope.markers['worker'][worker.id].setIcon(pinImage);
				infoWindow(worker);
			} else {
				var marker = new SlidingMarker({
					position: new google.maps.LatLng(Number(worker.lat), Number(worker.lon)),
					map: $scope.map,
					icon: pinImage,
					visible: true,
					optimized: false,
				});

				infoWindow(worker);
				marker.addListener('click', function () {
					$scope.detailsWorker(worker);
				});
				$scope.markers['worker'][worker.id] = marker;
			}
		}

		$scope.createOrderMarker = function (order) {
			if (order.worker) {
				return;
			}
			if (!order.status) {
				if (order.worker) {
					var pinImage = {
						url: "/img/markers/new/created_assigned.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/new/created.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else if (order.status && order.status.value == 'last') {
				if (order.worker) {
					var pinImage = {
						url: "/img/markers/new/ondelivery_assigned.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/new/ondelivery.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else if (order.status && (order.status.value == 'ready' || order.status.value == 'prepared')) {
				if (order.worker) {
					var pinImage = {
						url: "/img/markers/new/ready_assigned.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/new/ready.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			} else {
				if (order.worker) {
					var pinImage = {
						url: "/img/markers/new/ondelivery_assigned.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				} else {
					var pinImage = {
						url: "/img/markers/new/ondelivery.svg",
						scaledSize: new google.maps.Size(36, 36),
					};
				}
			}

			if (typeof $scope.markers['order'][order.id] !== 'undefined') {
				if (order.worker_id) {
					$scope.markers['order'][order.id].setMap(null);
				} else {
					$scope.markers['order'][order.id].setIcon(pinImage);
					$scope.markers['order'][order.id].setTitle(moment().diff(moment(order.created_at), 'minutes') < 2 ? 'New Order Animation' : 'Old Order');
				}

				return;
			} else if (order.customer) {
				if (!order.location || !order.location.latitude) {
					return;
				}
				if (order.status && order.status.value === 'complete') {
					return;
				}
				var location = new google.maps.LatLng(Number(order.location.latitude), Number(order.location.longitude));
				var marker = new google.maps.Marker({
					position: location,
					map: $scope.map,
					icon: pinImage,
					visible: true,
					order: order,
					title: moment().diff(moment(order.created_at), 'minutes') < 2 ? 'New Order Animation' : 'Old Order',
					// label: {
					// 	color: '#333',
					// 	fontWeight: 'bold',
					// 	fontSize: '12px',
					// 	text: order.customer.customer_name,
					// 	className: 'marker-label-position',
					// }
				});
			}

			var content = '<div id="iw-container">' +
				'<div class="iw-content"> ';
			// '<strong>#' + order.full_unique_id + '</strong>';

			// if (order.name && order.name != null)
			// content += ' - ' + order.name;

			// if (order.special_value)
			// content += ' - <strong>' + order.special_value + '</strong>';

			// content += '<hr style="margin:17px 0">' +
			content += '<p><a href="#" onclick="angular.element(this).scope().getOrderDetails(' + order.id + ')"><strong>Partner</strong> : ' + order.customer.customer_name + ' </a></p>' +
				'<p><strong>Customer </strong>: ' + order.location.name + ' </p>' +
				// '<p><strong>Address </strong>:' + order.location.address + '</p>' +
				'<p><strong>Deliver by </strong>:' + moment(order.scheduled_time).format("MMMM Do, h:mm a") + '</p>';
			// '<div class="row"><div class="col-md-4 pull-left">' +
			// '<a href="#" onclick="angular.element(this).scope().zoomIn(' + order.location.latitude + ',' + order.location.longitude + ')" class="hod-button blue btn-xs">Zoom In</a> </div>' +
			// '<div class="col-md-4 pull-left"><a href="#" onclick="angular.element(this).scope().assignOrderModal(' + order.id + ')" class="hod-button blue btn-xs pull-right">Assign</a> </div>' +
			// '<div class="col-md-4 text-center"><a href="#" onclick="angular.element(this).scope().getOrderDetails(' + order.id + ')" class="hod-button blue btn-xs pull-right">Details</a> </div></div>';

			if (order.package_type === 'large') {
				content += '<p><strong>Package : </strong> LARGE</p>';
			}

			if (order.worker) {
				content += '<p><strong>Driver : </strong> ' + order.worker.full_name + '</p>';
			}
			content += '</div></div></div></div>';
			var infowindow = new google.maps.InfoWindow({
				content: content,
				maxWidth: 220
			});
			marker.addListener('click', function () {
				$scope.clickWork(marker.order);
				document.querySelector('#order-list-' + marker.order.id).scrollIntoView({ behavior: 'smooth' });
				if (pinImage.url.indexOf('ondelivery') == -1) {
					// infowindow.open($scope.map, marker);
					// marker.setMap(null);
				}
			});

			infowindow.open($scope.map, marker);
			setTimeout(() => {
				infowindow.setMap(null);
			}, 400);

			$scope.markers['order'][order.id] = marker;
			$scope.infowindowsOrder[order.id] = infowindow;
		}

		function getFirstLetters(str) {
			const firstLetters = str
				.split(' ')
				.map(word => word[0])
				.join('');

			return firstLetters.toUpperCase();
		}

		function getPoints(customers) {
			var points = [];

			customers.forEach(element => {
				if (element.latitude) {
					points.push(
						{ location: new google.maps.LatLng(Number(element.latitude), Number(element.longitude)), weight: 1.4 },
					)
				}
			});

			points.push(
				{ location: new google.maps.LatLng(42.9559997, -85.9471779), weight: 3 },
				{ location: new google.maps.LatLng(42.6269733, -116.7981091), weight: 5 },
				{ location: new google.maps.LatLng(34.019144, -118.9860932), weight: 7 },
				{ location: new google.maps.LatLng(30.8262717, -57.0550548), weight: 7 },

			)
			// $scope.data.orders.forEach(element => {
			// 	if (!element.status) {
			// 		points.push(
			// 			{ location: new google.maps.LatLng(Number(element.location.latitude), Number(element.location.longitude)), weight: 0.4 },
			// 		)
			// 	}
			// });
			return points;
		}

		$scope.heatmapStatus = false;
		var heatmapData;
		$scope.createHeatmap = function (customers) {
			if ($scope.heatmapStatus) {
				getPoints(customers);
				// heatmapData.setData(getPoints(customers));
			} else {
				$scope.heatmapStatus = true;
				heatmapData = new google.maps.visualization.HeatmapLayer({
					data: getPoints(customers),
					dissipating: false,
					opacity: 0.4,
					gradient: [
						"rgba(43, 191, 201, 0)",
						"rgba(43, 191, 201, 1)",
						"rgba(36, 205, 214, 1)",
						"rgba(26, 214, 224, 1)",
						"rgba(21, 223, 234, 1)",
						"rgba(14, 228, 239, 1)",
						"rgba(0, 242, 255, 1)",
						"rgba(0, 242, 255, 1)",
						"rgba(0, 242, 255, 1)",
						"rgba(0, 242, 255, 1)",
						"rgba(0, 242, 255, 1)",
						"rgba(0, 242, 255, 1)",
						"rgba(0, 242, 255, 1)",
						"rgba(0, 242, 255, 1)",
					],
					// gradient: [
					// 	"rgba(0, 255, 255, 0)",
					// 	"rgba(0, 255, 255, 1)",
					// 	"rgba(0, 191, 255, 1)",
					// 	"rgba(0, 127, 255, 1)",
					// 	"rgba(0, 63, 255, 1)",
					// 	"rgba(0, 0, 255, 1)",
					// 	"rgba(0, 0, 223, 1)",
					// 	"rgba(0, 0, 191, 1)",
					// 	"rgba(0, 0, 159, 1)",
					// 	"rgba(0, 0, 127, 1)",
					// 	"rgba(63, 0, 91, 1)",
					// 	"rgba(127, 0, 63, 1)",
					// 	"rgba(191, 0, 31, 1)",
					// 	"rgba(255, 0, 0, 1)",
					// ],
					map: $scope.map,
				});
			}
		}

		$scope.createCustomerMarker = function (customer) {

			if (customer.latitude.length < 1) {
				return;
			}

			var pinImage = {
				url: "/img/markers/merchant_empty.svg",
				scaledSize: new google.maps.Size(36, 36),
			};

			if (typeof $scope.markers['customers'][customer.id] !== 'undefined') {
				var location = new google.maps.LatLng(Number(customer.latitude), Number(customer.longitude));
				$scope.markers['customers'][customer.id].setDuration(1000);
				$scope.markers['customers'][customer.id].setEasing("swing");
				$scope.markers['customers'][customer.id].setPosition(location);
				$scope.markers['customers'][customer.id].setIcon(pinImage);
			} else {
				var marker = new SlidingMarker({
					position: new google.maps.LatLng(Number(customer.latitude), Number(customer.longitude)),
					map: $scope.map,
					icon: pinImage,
					visible: true,
					optimized: false,
					label: {
						color: '#333',
						fontWeight: 'bold',
						fontSize: '12px',
						text: getFirstLetters(customer.dba_name),
						className: 'customer-label-position',
					}
				});

				var content = '<div id="iw-container">' +
					'<div class="iw-content"> ' +
					'<strong>#' + customer.customer_name + '</strong>';


				content += '<hr style="margin:17px 0">' +
					'<p><strong>Name</strong> : ' + customer.customer_name + ' </p>' +
					'<p><strong>Phone </strong>: ' + customer.phone_number + ' </p>' +
					'<div class="row"><div class="col-md-4 pull-left">' +
					'<a href="#" onclick="angular.element(this).scope().zoomIn(' + customer.latitude + ',' + customer.longitude + ')" class="hod-button blue btn-xs">Zoom In</a> </div></div>';
				content += '</div></div>';

				var infowindow = new google.maps.InfoWindow({
					content: content,
					maxWidth: 360
				});

				marker.addListener('click', function () {
					infowindow.open($scope.map, marker);
				});
				$scope.markers['customers'][customer.id] = marker;
			}
		}

		$scope.openRouteDetail = function (worker) {
			console.log(worker);
			window.open('routes?route_id=' + worker.last_route_id, '_blank');
		}

		function getIcon(type) {
			return false;
			var fontawesomeIcon;
			switch (type) {
				case 'truck':
					fontawesomeIcon = "M23.04-13.751q0-1.872-1.368-3.24t-3.24-1.368-3.24 1.368-1.368 3.24 1.368 3.24 3.24 1.368 3.24-1.368 1.368-3.24zm-13.824-18.432h13.824v-9.216h-5.688q-.468 0-.792.324l-7.02 7.02q-.324.324-.324.792v1.08zm46.08 18.432q0-1.872-1.368-3.24t-3.24-1.368-3.24 1.368-1.368 3.24 1.368 3.24 3.24 1.368 3.24-1.368 1.368-3.24zm9.216-39.168v36.864q0 .54-.144.954t-.486.666-.594.414-.846.216-.81.072-.918 0-.81-.018q0 3.816-2.7 6.516t-6.516 2.7-6.516-2.7-2.7-6.516h-13.824q0 3.816-2.7 6.516t-6.516 2.7-6.516-2.7-2.7-6.516h-2.304q-.108 0-.81.018t-.918 0-.81-.072-.846-.216-.594-.414-.486-.666-.144-.954q0-.936.684-1.62t1.62-.684v-11.52q0-.288-.018-1.26t0-1.368.09-1.242.234-1.332.504-1.098.81-1.08l7.128-7.128q.684-.684 1.818-1.152t2.106-.468h5.76v-6.912q0-.936.684-1.62t1.62-.684h36.864q.936 0 1.62.684t.684 1.62z";
					break;
				case 'automobile':
					fontawesomeIcon = "M17.28-25.271q0-2.376-1.692-4.068t-4.068-1.692-4.068 1.692-1.692 4.068 1.692 4.068 4.068 1.692 4.068-1.692 1.692-4.068zm1.296-11.52h36.576l-3.204-12.852q-.072-.288-.504-.63t-.756-.342h-27.648q-.324 0-.756.342t-.504.63zm49.392 11.52q0-2.376-1.692-4.068t-4.068-1.692-4.068 1.692-1.692 4.068 1.692 4.068 4.068 1.692 4.068-1.692 1.692-4.068zm5.76-3.456v13.824q0 .504-.324.828t-.828.324h-3.456v4.608q0 2.88-2.016 4.896t-4.896 2.016-4.896-2.016-2.016-4.896v-4.608h-36.864v4.608q0 2.88-2.016 4.896t-4.896 2.016-4.896-2.016-2.016-4.896v-4.608h-3.456q-.504 0-.828-.324t-.324-.828v-13.824q0-3.348 2.358-5.706t5.706-2.358h1.008l3.78-15.084q.828-3.384 3.744-5.67t6.444-2.286h27.648q3.528 0 6.444 2.286t3.744 5.67l3.78 15.084h1.008q3.348 0 5.706 2.358t2.358 5.706z";
					break;
				case 'motorcycle':
					fontawesomeIcon = "M82.836-27.143q.432 3.708-.792 7.146t-3.564 5.886-5.706 3.816-7.074 1.116q-5.796-.396-10.062-4.5t-4.842-9.864q-.432-3.996.99-7.578t4.266-6.138l-2.556-3.852q-3.456 2.88-5.436 6.984t-1.98 8.784q0 .972-.666 1.674t-1.638.702h-11.7q-.828 5.904-5.364 9.864t-10.584 3.96q-6.66 0-11.394-4.734t-4.734-11.394 4.734-11.394 11.394-4.734q2.736 0 5.472.972l.864-1.62q-4.428-3.96-10.944-3.96h-2.304q-.936 0-1.62-.684t-.684-1.62.684-1.62 1.62-.684h4.608q2.808 0 5.22.486t4.194 1.386 2.574 1.422 1.836 1.314h22.572l-3.06-4.608h-7.992q-1.08 0-1.764-.81t-.504-1.89q.144-.828.828-1.368t1.548-.54h9.108q1.188 0 1.908 1.008l2.52 3.78 4.104-4.104q.684-.684 1.656-.684h3.636q.936 0 1.62.684t.684 1.62v4.608q0 .936-.684 1.62t-1.62.684h-6.444l4.14 6.192q4.716-2.268 9.9-1.296 5.148.936 8.784 4.842t4.248 9.126zm-66.708 13.392q4.14 0 7.308-2.61t3.996-6.606h-11.304q-1.26 0-1.98-1.116-.648-1.152-.036-2.268l5.292-9.972q-1.692-.468-3.276-.468-4.752 0-8.136 3.384t-3.384 8.136 3.384 8.136 8.136 3.384zm50.688 0q4.752 0 8.136-3.384t3.384-8.136-3.384-8.136-8.136-3.384q-2.16 0-4.356.864l6.264 9.36q.54.828.36 1.764t-.972 1.44q-.54.396-1.296.396-1.26 0-1.908-1.044l-6.264-9.36q-3.348 3.42-3.348 8.1 0 4.752 3.384 8.136t8.136 3.384z";
					break;
				case 'bicycle':
					fontawesomeIcon = "M27.432-22.967h-11.304q-1.44 0-2.07-1.26t.234-2.412l6.768-9.036q-2.34-1.116-4.932-1.116-4.752 0-8.136 3.384t-3.384 8.136 3.384 8.136 8.136 3.384q4.14 0 7.308-2.61t3.996-6.606zm-6.696-4.608h6.696q-.648-3.06-2.7-5.328zm17.28 0l10.368-13.824h-17.28l-3.564 4.752q3.78 3.708 4.536 9.072h5.94zm40.32 2.304q0-4.752-3.384-8.136t-8.136-3.384q-2.16 0-4.356.864l6.264 9.36q.54.828.36 1.764t-.972 1.44q-.54.396-1.296.396-1.26 0-1.908-1.044l-6.264-9.36q-3.348 3.42-3.348 8.1 0 4.752 3.384 8.136t8.136 3.384 8.136-3.384 3.384-8.136zm4.608 0q0 6.66-4.734 11.394t-11.394 4.734-11.394-4.734-4.734-11.394q0-3.492 1.422-6.606t3.942-5.382l-2.34-3.528-12.708 16.884q-.648.936-1.836.936h-7.092q-.828 5.904-5.364 9.864t-10.584 3.96q-6.66 0-11.394-4.734t-4.734-11.394 4.734-11.394 11.394-4.734q4.104 0 7.74 1.98l4.932-6.588h-8.064q-.936 0-1.62-.684t-.684-1.62.684-1.62 1.62-.684h13.824v4.608h15.66l-3.06-4.608h-7.992q-.936 0-1.62-.684t-.684-1.62.684-1.62 1.62-.684h9.216q1.188 0 1.908 1.008l9.612 14.4q3.276-1.584 6.912-1.584 6.66 0 11.394 4.734t4.734 11.394z";
					break;
				case 'walking':
					fontawesomeIcon = "M36.864-39.095v14.976q0 1.44-1.008 2.448t-2.448 1.008-2.448-1.008-1.008-2.448v-12.672h-2.304v32.832q0 1.656-1.188 2.844t-2.844 1.188-2.844-1.188-1.188-2.844v-16.704h-2.304v16.704q0 1.656-1.188 2.844t-2.844 1.188-2.844-1.188-1.188-2.844v-32.832h-2.304v12.672q0 1.44-1.008 2.448t-2.448 1.008-2.448-1.008-1.008-2.448v-14.976q0-2.88 2.016-4.896t4.896-2.016h23.04q2.88 0 4.896 2.016t2.016 4.896zm-10.368-16.128q0 3.348-2.358 5.706t-5.706 2.358-5.706-2.358-2.358-5.706 2.358-5.706 5.706-2.358 5.706 2.358 2.358 5.706z";
					break;
				case 'Van':
					fontawesomeIcon = "M628.88 210.65L499.19 55.03A64.006 64.006 0 0 0 450.02 32H32C14.33 32 0 46.33 0 64v288c0 17.67 14.33 32 32 32h32c0 52.93 43.06 96 96 96s96-43.07 96-96h128c0 52.93 43.06 96 96 96s96-43.07 96-96h32c17.67 0 32-14.33 32-32V241.38a48.03 48.03 0 0 0-11.12-30.73zM376 80h74.02c4.76 0 9.24 2.1 12.29 5.76L550.85 192H376V80zm-160 0h112v112H216V80zM48 80h120v112H48V80zm112 352c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm320 0c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm112-96h-29.36c-16.65-28.55-47.27-48-82.64-48s-65.99 19.45-82.64 48H242.64c-16.65-28.55-47.27-48-82.64-48s-65.99 19.45-82.64 48H48v-96h542.85l1.15 1.38V336z";
					break;
				case 'Suv':
					fontawesomeIcon = "M120.81 248c-25.96 0-44.8 16.8-44.8 39.95 0 23.15 18.84 39.95 44.8 39.95l10.14.1c39.21 0 45.06-20.1 45.06-32.08-.01-24.68-31.1-47.92-55.2-47.92zm10.14 56c-3.51 0-7.02-.1-10.14-.1-12.48 0-20.8-6.38-20.8-15.95s8.32-15.95 20.8-15.95 31.2 14.36 31.2 23.93c0 7.17-10.54 8.07-21.06 8.07zm260.24-56c-24.1 0-55.19 23.24-55.19 47.93 0 11.98 5.85 32.08 45.06 32.08l10.14-.1c25.96 0 44.8-16.8 44.8-39.95-.01-23.16-18.85-39.96-44.81-39.96zm0 55.9c-3.12 0-6.63.1-10.14.1-10.53 0-21.06-.9-21.06-8.07 0-9.57 18.72-23.93 31.2-23.93s20.8 6.38 20.8 15.95-8.32 15.95-20.8 15.95zm114.8-140.94c-7.34-11.88-20.06-18.97-34.03-18.97H422.3l-8.07-24.76C403.5 86.29 372.8 64 338.17 64H173.83c-34.64 0-65.33 22.29-76.06 55.22l-8.07 24.76H40.04c-13.97 0-26.69 7.09-34.03 18.97s-8 26.42-1.75 38.91l5.78 11.61c3.96 7.88 9.92 14.09 17 18.55-6.91 11.74-11.03 25.32-11.03 39.97V400c0 26.47 21.53 48 48 48h16c26.47 0 48-21.53 48-48v-16H384v16c0 26.47 21.53 48 48 48h16c26.47 0 48-21.53 48-48V271.99c0-14.66-4.12-28.23-11.03-39.98 7.09-4.46 13.04-10.68 17-18.57l5.78-11.56c6.24-12.5 5.58-27.05-1.76-38.92zM128.2 129.14C134.66 109.32 153 96 173.84 96h164.33c20.84 0 39.18 13.32 45.64 33.13l20.47 62.85H107.73l20.47-62.84zm-89.53 70.02l-5.78-11.59c-1.81-3.59-.34-6.64.34-7.78.87-1.42 2.94-3.8 6.81-3.8h39.24l-6.45 19.82a80.69 80.69 0 0 0-23.01 11.29c-4.71-1-8.94-3.52-11.15-7.94zM96.01 400c0 8.83-7.19 16-16 16h-16c-8.81 0-16-7.17-16-16v-16h48v16zm367.98 0c0 8.83-7.19 16-16 16h-16c-8.81 0-16-7.17-16-16v-16h48v16zm0-80.01v32H48.01v-80c0-26.47 21.53-48 48-48h319.98c26.47 0 48 21.53 48 48v48zm15.12-132.41l-5.78 11.55c-2.21 4.44-6.44 6.97-11.15 7.97-6.94-4.9-14.69-8.76-23.01-11.29l-6.45-19.82h39.24c3.87 0 5.94 2.38 6.81 3.8.69 1.14 2.16 4.18.34 7.79z";
					break;
				case 'Car':
					fontawesomeIcon = "M120.81 248c-25.96 0-44.8 16.8-44.8 39.95 0 23.15 18.84 39.95 44.8 39.95l10.14.1c39.21 0 45.06-20.1 45.06-32.08-.01-24.68-31.1-47.92-55.2-47.92zm10.14 56c-3.51 0-7.02-.1-10.14-.1-12.48 0-20.8-6.38-20.8-15.95s8.32-15.95 20.8-15.95 31.2 14.36 31.2 23.93c0 7.17-10.54 8.07-21.06 8.07zm260.24-56c-24.1 0-55.19 23.24-55.19 47.93 0 11.98 5.85 32.08 45.06 32.08l10.14-.1c25.96 0 44.8-16.8 44.8-39.95-.01-23.16-18.85-39.96-44.81-39.96zm0 55.9c-3.12 0-6.63.1-10.14.1-10.53 0-21.06-.9-21.06-8.07 0-9.57 18.72-23.93 31.2-23.93s20.8 6.38 20.8 15.95-8.32 15.95-20.8 15.95zm114.8-140.94c-7.34-11.88-20.06-18.97-34.03-18.97H422.3l-8.07-24.76C403.5 86.29 372.8 64 338.17 64H173.83c-34.64 0-65.33 22.29-76.06 55.22l-8.07 24.76H40.04c-13.97 0-26.69 7.09-34.03 18.97s-8 26.42-1.75 38.91l5.78 11.61c3.96 7.88 9.92 14.09 17 18.55-6.91 11.74-11.03 25.32-11.03 39.97V400c0 26.47 21.53 48 48 48h16c26.47 0 48-21.53 48-48v-16H384v16c0 26.47 21.53 48 48 48h16c26.47 0 48-21.53 48-48V271.99c0-14.66-4.12-28.23-11.03-39.98 7.09-4.46 13.04-10.68 17-18.57l5.78-11.56c6.24-12.5 5.58-27.05-1.76-38.92zM128.2 129.14C134.66 109.32 153 96 173.84 96h164.33c20.84 0 39.18 13.32 45.64 33.13l20.47 62.85H107.73l20.47-62.84zm-89.53 70.02l-5.78-11.59c-1.81-3.59-.34-6.64.34-7.78.87-1.42 2.94-3.8 6.81-3.8h39.24l-6.45 19.82a80.69 80.69 0 0 0-23.01 11.29c-4.71-1-8.94-3.52-11.15-7.94zM96.01 400c0 8.83-7.19 16-16 16h-16c-8.81 0-16-7.17-16-16v-16h48v16zm367.98 0c0 8.83-7.19 16-16 16h-16c-8.81 0-16-7.17-16-16v-16h48v16zm0-80.01v32H48.01v-80c0-26.47 21.53-48 48-48h319.98c26.47 0 48 21.53 48 48v48zm15.12-132.41l-5.78 11.55c-2.21 4.44-6.44 6.97-11.15 7.97-6.94-4.9-14.69-8.76-23.01-11.29l-6.45-19.82h39.24c3.87 0 5.94 2.38 6.81 3.8.69 1.14 2.16 4.18.34 7.79z";
					break;
				case 'Bike':
					fontawesomeIcon = "M400 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm-4 121a31.9 31.9 0 0 0 20 7h64a32 32 0 0 0 0-64h-52.78L356 103a31.94 31.94 0 0 0-40.81.68l-112 96a32 32 0 0 0 3.08 50.92L288 305.12V416a32 32 0 0 0 64 0V288a32 32 0 0 0-14.25-26.62l-41.36-27.57 58.25-49.92zm116 39a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64zM128 256a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64z";
					break;
				case 'Scooter':
					fontawesomeIcon = "M400 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm-4 121a31.9 31.9 0 0 0 20 7h64a32 32 0 0 0 0-64h-52.78L356 103a31.94 31.94 0 0 0-40.81.68l-112 96a32 32 0 0 0 3.08 50.92L288 305.12V416a32 32 0 0 0 64 0V288a32 32 0 0 0-14.25-26.62l-41.36-27.57 58.25-49.92zm116 39a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64zM128 256a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64z";
					break;
				case 'Scooter':
					fontawesomeIcon = "M512.238 192c-17.943-.033-35.025 3.631-50.534 10.266L435.799 160H520c13.255 0 24-10.745 24-24V88c0-13.255-10.745-24-24-24h-60a24.002 24.002 0 0 0-19.2 9.6l-31.893 42.524-27.265-44.485A16.005 16.005 0 0 0 368 64h-76c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12h67.04l39.226 64H217.584c-16.679-19.064-41.794-32-89.584-32H80.452c-8.616 0-16.029 6.621-16.433 15.227C63.586 152.416 70.907 160 80 160h48c24.268 0 40.146 8.239 51.566 19.951l-10.364 18.843a127.7 127.7 0 0 0-39.723-6.786C58.709 191.202.272 248.724.001 319.499-.27 390.422 57.141 448 128 448c59.641 0 109.745-40.795 123.956-96h84.776c9.384 0 16.781-8.057 15.936-17.438-5.123-56.79 20.187-110.805 64.631-143.511l16.791 27.395c-30.629 23.533-50.314 60.604-50.086 102.267.38 69.638 57.194 126.66 126.83 127.281 70.58.629 128.112-55.871 129.153-126.057 1.052-71.012-56.729-129.808-127.749-129.937zM462 92h54v40h-84l30-40zM128 416c-52.935 0-96-43.065-96-96s43.065-96 96-96a95.687 95.687 0 0 1 25.45 3.436L97.98 328.289C92.126 338.933 99.838 352 112 352h106.499c-13.208 37.247-48.781 64-90.499 64zm192-96H139.061l70.399-128h159.467C337.778 226.865 320 272.362 320 320zm188.206 95.926c-49.822-1.93-90.199-42.305-92.132-92.127-1.214-31.294 12.642-59.467 34.879-77.836l57.496 93.808c3.463 5.651 10.852 7.424 16.502 3.96l6.821-4.181c5.65-3.463 7.423-10.851 3.96-16.502l-57.051-93.083A95.57 95.57 0 0 1 512 224c52.935 0 96 43.065 96 96 0 54.194-45.139 98.043-99.794 95.926z";
					break;


				default:

					fontawesomeIcon = false;
					break;
			}
			return fontawesomeIcon;
		}

		$scope.updateOrderMarker = function (order) {
			if ($scope.markers['order'][order.id]) {
				var content = '<div id="iw-container">' +
					'<div class="iw-content"> <div class="btn-group pull-right">' +
					'<a href="#" onclick="angular.element(this).scope().zoomIn(' + order.location.latitude + ',' + order.location.longitude + ')" class="btn btn-xs btn-warning">Yaklaş</a>' +
					'<a href="#" onclick="angular.element(this).scope().getOrderDetails(' + order.id + ')" class="btn btn-xs btn-primary">Detaylar</a> </div>' +
					'<strong>#' + order.id + '</strong>';

				if (order.name && order.name != null)
					content += ' - ' + order.name;

				if (order.special_value)
					content += ' - <strong>' + order.special_value + '</strong>';

				content += '<hr style="margin:17px 0">' +
					'<p><strong>Partner : ' + order.customer.customer_name + ' </strong></p>' +
					'<p><strong>Customer : ' + order.location.name + ' </strong></p>' +
					'<p>' + order.customer.address + '</p>';

				if (order.worker)
					content += '<p><strong>Driver : </strong> ' + order.worker.full_name + '</p>';
				content += '</div></div>';
				$scope.infowindowsOrder[order.id].setContent(content);
			}
		}

		$scope.zoomIn = function (lat, lng) {
			$scope.map.panTo(new google.maps.LatLng(Number(lat), Number(lng)));
			$scope.map.setZoom(16);
		}

		$scope.getNumber = function (num) {
			return new Array(num);
		}

		$scope.getAddressFromCoord = function (coord) {

			if (coord.lat != '') {
				var url = "//maps.googleapis.com/maps/api/geocode/json?latlng=" + coord.lat + "," + coord.lon + "&key=AIzaSyDxjEa1-VMmyYdudc4Vmsfa8XBGlnxubco";
				Order.getAddressFromCoord(url).success(function (data, status, headers, config) {
					console.log(angular.fromJson(data));
				});
			}
		}

		$scope.sidebar = function (e) {
			if (e == 'left') {
				$scope.filterPanel = false;
				$("#sidebar_right").toggle();
				if (!$("#sidebar_right").is(":visible"))
					$(".sidebar_left_toggle").css({
						left: 0
					}).html('<i class="fa fa-arrow-right"></i>');
				else
					$(".sidebar_left_toggle").css({
						left: '310px'
					}).html('<i class="fa fa-arrow-left"></i>');
			} else {
				$(".sidebar_worker").toggle();
				if (!$(".sidebar_worker").is(":visible"))
					$(".sidebar_right_toggle").css({
						right: 0
					}).html('<i class="fa fa-arrow-left"></i>');
				else
					$(".sidebar_right_toggle").css({
						right: '260px'
					}).html('<i class="fa fa-arrow-right"></i>');
			}
		}

		var directionsService = [],
			directionsDisplay = [];

		$scope.getRemainingTime = function (id, loading, direction) {

			Order.remainingTime(id, loading).success(function (data, status, headers, config) {
				var order = find($scope.data.orders, id);

				order.dist = data.dist;

				if (calculatedOrder.indexOf(id) == -1)
					calculatedOrder.push(id);

				if (typeof direction === 'undefined') {

					directionsService[order.id] = new google.maps.DirectionsService;
					directionsDisplay[order.id] = new google.maps.DirectionsRenderer({
						suppressMarkers: true
					});

					directionsDisplay[order.id].setMap($scope.map);
					calculateAndDisplayRoute(
						directionsService,
						directionsDisplay,
						data.coords[0],
						data.coords[1],
						data.coords[2],
						data.coords[3],
						order.id
					);
				}

				if (typeof $scope.calculaterInterval == 'undefined') {
					$scope.calculaterInterval = setInterval(function () {
						calculatedOrder.forEach(function (element) {
							$scope.getRemainingTime(element, true, false);
						});
					}, 60000);
				}

			});
		};

		function calculateAndDisplayRoute(directionsService, directionsDisplay, workLat, workLon, workerLat, workerLon, orderID) {
			var org = new google.maps.LatLng(workLat, workLon),
				dest = new google.maps.LatLng(workerLat, workerLon);

			var display = directionsService[orderID].route({
				origin: org,
				destination: dest,
				travelMode: google.maps.DirectionsTravelMode.DRIVING
			}, function (response, status) {
				if (status === 'OK') {

					directionsDisplay.forEach(function (element) {
						if (element != directionsDisplay[orderID]) {
							element.setMap(null);
						}
					}, this);

					directionsDisplay[orderID].setDirections(response);
				}
			});
		}

		function makeRoute(start, end, waypts, allID) {
			console.log(allID)
			if (routeOptimizationWorkerOrders.length > 0) {

				for (var i = 1; i < routeOptimizationWorkerOrders.length; i++) {
					$scope.markers['order'][routeOptimizationWorkerOrders[i]].setLabel({
						text: " ",
						color: 'white',
						fontSize: "10px",
						fontWeight: 'bold'
					});
				}
				routeOptimizationDirectionsDisplay.setMap(null);
			}


			var directionsDisplay;
			var directionsService = new google.maps.DirectionsService();
			directionsDisplay = new google.maps.DirectionsRenderer({
				suppressMarkers: true, //false it if you want a marker from the direction service
				// polylineOptions: {
				//     strokeColor: 'green', 
				//     strokeOpacity: 1.0,
				//     strokeWeight: 3,
				//     icons:[{repeat:'300px',icon:{path:google.maps.SymbolPath.FORWARD_CLOSED_ARROW}}]
				// }
			});
			if (waypts.length > -1) {
				var request = {
					origin: start,
					destination: end,
					waypoints: waypts,
					optimizeWaypoints: true,
					travelMode: google.maps.DirectionsTravelMode.DRIVING
				};
				directionsService.route(request, function (response, status) {
					if (status == google.maps.DirectionsStatus.OK) {
						directionsDisplay.setDirections(response);
						var my_route = response.routes[0];
						var clickIcon = {};
						console.log(my_route);
						for (var i = 0; i < my_route.legs.length; i++) {
							if (i == 0) {
								continue;
							} else {
								$scope.markers['order'][allID[i]].setLabel({
									text: "" + i,
									color: 'white',
									fontSize: "10px",
									fontWeight: 'bold'
								});

								clickIcon = {
									url: $scope.markers['order'][allID[i]]['icon']['url'],
									fillColor: "#666666",
									fillOpacity: 1,
									strokeColor: "#666666",
									strokeWeight: 1,
									labelOrigin: new google.maps.Point(15, 19),
									anchor: new google.maps.Point(9, 35),
									scaledSize: new google.maps.Size(30, 46),
								};

								$scope.markers['order'][allID[i]].setIcon(clickIcon);
							}
						}

						$scope.markers['order'][allID[allID.length - 1]].setLabel({
							text: "son",
							color: 'white',
							fontSize: "9px",
							fontWeight: 'bold'
						});

						clickIcon = {
							url: $scope.markers['order'][allID[allID.length - 1]]['icon']['url'],
							fillColor: "#666666",
							fillOpacity: 1,
							strokeColor: "#666666",
							strokeWeight: 1,
							labelOrigin: new google.maps.Point(10, 10),
							anchor: new google.maps.Point(9, 35),
						};
						$scope.markers['order'][allID[allID.length - 1]].setIcon(clickIcon);
					}
				});
			}
			routeOptimizationDirectionsDisplay = directionsDisplay;
			routeOptimizationDirectionsDisplay.setMap($scope.map);
			routeOptimizationWorkerOrders = allID;
		}

		$scope.getWorkerOrderCount = function (worker) {
			return worker.on_way_orders.length + ' / ' + (Number(worker.on_way_orders.length) + Number(worker.completed_orders.length));
		}
	}
]);