teleskop.
factory('ChecklistTemplate', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/checklist-templates');
      },
      get: function(id) {
        return $http.get('api/checklist-template/' + id);
      },
      delete: function(id) {
        return $http.delete('api/delete-checklist-template/' + id);
      },
      save: function(data) {
        return $http.post('api/save-checklist-template',data);
      },
      update: function(data) {
        return $http.post('api/update-checklist-template',data);
      }
    };
}]);