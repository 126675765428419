teleskop.controller('CustomerProfitAnalyticController', ['$scope', 'Analytic','Account', '$location','FileUploader','$http',
  function ($scope, Analytic, Account, $location, FileUploader, $http) {

		$scope.filterProp = [];

		$scope.filterData = {
			export: false,
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: null, 
				endDate: null 
			}
		}

	
		$scope.$on('refresh_data', function() {
			console.log('refreshing order reports...')
			$scope.init();
		}, true);

		$scope.clearFilter = function(page) {
			$scope.filterData = {
				export: false,
				customers: [],
				workers: [],
				workSteps: [],
				page: 1,
				created_at : { 
					startDate: null, 
					endDate: null 
				},
				scheduled_time : { 
					startDate: null, 
					endDate: null 
				}
			}
		}

		$scope.init = function (type) {
			Analytic.customerProfit($scope.filterData).success(function(data, status, headers, config) {
				if(typeof type !== undefined && type == 'export') {
					$scope.filterData.export = false;
					window.location.href = data.url;
				}else {
					$scope.data = data;
					if(!$scope.filterData.customer_id)
					$scope.filterData.customer_id = $scope.data.customer_id;
					$scope.totalMoney = 0;
					$scope.data.money_by_customer.forEach(element => {
						$scope.totalMoney += element.sum;
					});
					$scope.totalMoney += $scope.data.money_by_order_payment.sum;
					$scope.totalMoney -= $scope.data.payment_by_customer.alinan;
					$scope.chartData = [data.count.success,data.count.other,data.count.fail,data.count.total];
				}
			});
		};

		// $scope.addPayment = function() {
		// 	$scope.payment.customer_id = $scope.filterData.customer_id;
		// 	Analytic.addPayment($scope.payment).success(function(data, status, headers, config) {
		// 		$scope.data.payments.push(data);
		// 		if(data.type == 0) {
		// 			$scope.data.payment_by_customer.alinan = Number(data.price) + Number($scope.data.payment_by_customer.alinan);
		// 		}else {
		// 			$scope.data.payment_by_customer.verilen = Number(data.price) + Number($scope.data.payment_by_customer.verilen);
		// 		}
		// 		$('#paymentOrdersModal').modal('toggle');
		// 	});
		// };

		$scope.getPage = function(page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function(page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.sort = function(field,sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if(type == 'clear') {
				$scope.filterData = {
          			export: false,
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
			}
			$scope.init();
		}

  }
]);
