teleskop.controller('ProfitAnalyticController', ['$scope', 'Analytic', 'Order', 'Account', '$location','FileUploader','$http',
  function ($scope, Analytic, Order, Account, $location, FileUploader, $http) {

		$scope.filterProp = [];

		$scope.filterData = {
			export: false,
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: null, 
				endDate: null 
			}
		}

	
		$scope.$on('refresh_data', function() {
			console.log('refreshing order reports...')
			$scope.init();
		}, true);

		// $scope.$watch('filterData', function() {
		// 	$scope.init();
		// }, true);

		
		$scope.updatePaymentData = function (item) {
			Order.updatePaymentData(item)
			$scope.init();
		}

		$scope.clearFilter = function(page) {
			$scope.filterData = {
				export: false,
				customers: [],
				workers: [],
				workSteps: [],
				page: 1,
				created_at : { 
					startDate: null, 
					endDate: null 
				},
				scheduled_time : { 
					startDate: null, 
					endDate: null 
				}
			}
		}

		$scope.init = function (type) {
			Analytic.profit($scope.filterData).success(function(data, status, headers, config) {
				if(typeof type !== undefined && type == 'export') {
					$scope.filterData.export = false;
					$scope.filterData.exportCari = false;
					window.location.href = data.url;
				}else {
					$scope.data = data;
					if(!$scope.filterData.customer_id)
					$scope.filterData.customer_id = $scope.data.customer_id;
					$scope.totalMoney = 0;
					$scope.totalMoney += $scope.data.money_by_order_payment.sum;
					// $scope.totalMoney -= $scope.data.payment_by_customer.alinan;
					$scope.chartData = [data.count.success,data.count.other,data.count.fail,data.count.total];
				}
			});
		};

		$scope.addPayment = function() {
			$scope.payment.customer_id = $scope.filterData.customer_id;
			Analytic.addPayment($scope.payment).success(function(data, status, headers, config) {
				$scope.data.payments.push(data);
				$scope.init();
				$('#paymentOrdersModal').modal('toggle');
			});
		};

		$scope.getPage = function(page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function(page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.exportCari = function(page) {
			$scope.filterData.exportCari = true;
			$scope.init('export');
		}

		$scope.sort = function(field,sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if(type == 'clear') {
				$scope.filterData = {
          			export: false,
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
			}
			$scope.init();
		}

		
		$scope.deletePayment = function (id) {
			if (confirm("Silmek istediğinize emin misiniz ? Bu işlem geri alınamaz bir işlemdir!")) {
				Analytic.deletePayment(id).success(function(data, status, headers, config) {
					Messenger().post('Başarıyla silindi!');
					index = $scope.data.payments.map(function(item) {
						return item.Id == id
					});
					console.log(index);
					$scope.data.payments.splice(index, 1);
					$scope.init();
				}).error(function(data){
					Messenger().post({message: data.message,type: "error"});
				});
			}
		}

  }
]);
