/*
 * JavaScript client-side example using jsrsasign
 */

// #########################################################
// #             WARNING   WARNING   WARNING               #
// #########################################################
// #                                                       #
// # This file is intended for demonstration purposes      #
// # only.                                                 #
// #                                                       #
// # It is the SOLE responsibility of YOU, the programmer  #
// # to prevent against unauthorized access to any signing #
// # functions.                                            #
// #                                                       #
// # Organizations that do not protect against un-         #
// # authorized signing will be black-listed to prevent    #
// # software piracy.                                      #
// #                                                       #
// # -QZ Industries, LLC                                   #
// #                                                       #
// #########################################################

/**
 * Depends:
 *     - jsrsasign-latest-all-min.js
 *     - qz-tray.js
 *
 * Steps:
 *
 *     1. Include jsrsasign 8.0.4 into your web page
 *        <script src="https://cdn.rawgit.com/kjur/jsrsasign/c057d3447b194fa0a3fdcea110579454898e093d/jsrsasign-all-min.js"></script>
 *
 *     2. Update the privateKey below with contents from private-key.pem
 *
 *     3. Include this script into your web page
 *        <script src="path/to/sign-message.js"></script>
 *
 *     4. Remove or comment out any other references to "setSignaturePromise"
 */
 var privateKey = "-----BEGIN PRIVATE KEY-----\n" +
 "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDUFkQYDrqsm1NU\n" +
 "Y4AuV/kb8lRYhNSQ6UfTDf90VAMVLGyO9ShzwJ/dSMWT6SyImrIoZVVeCaWU1YJt\n" +
 "P4ym76KEoi0ZiWwW2GG4+DUY0ogdT+dsgAvLHIhef+WtA2rNSemuoblMcaGEAPFN\n" +
 "lbErVoQj2Aa7d6/a+nmmaF02NC6Jmf//Zk457WzNfFhzMQMAa8SmaFt0oZ1PuLfz\n" +
 "Tyi4m8IQuEHWIMXGYl4d8fbSPeSW8Wt9i+IsO1fuy5pVZ0OutzVDYKwthIc2keLp\n" +
 "ugnk3cGidacQI+y1Icfx+Uy9lRaGTaDEgvX3yDacsYQdZarq0mxAV/nX+erQ/c49\n" +
 "IbGm+eR3AgMBAAECggEAUai/ECo/quzlQUVMWcuqkR2VZz3BoIgJjRRDKvWb+D+S\n" +
 "DtsK98KPawK1fDVX3cL+XLpji6EYPX2Vyd2hmLcEsp/E0KPOV995hOAghY2uNecJ\n" +
 "moeUwuAaC5SgCKUbrq5KSOBg+bN4tlwsjKxZum+F0k0/jlfPgHdXdoCRfyjJUFYk\n" +
 "pYXAIAnNsNVZUjkIOCyiJo6iDZyfvs318g7nqoMcv0mJZCORatcgYLea49DpzsBJ\n" +
 "PDrvOlDiS846I65g/QzdLzHIwkt5CYGkB4exSqMEtPR3nkwqXteEh2oPcL7ef7JQ\n" +
 "2BLmq28B/5UoY0CBwmH7holLUtbQHg6rz+ldxxntfQKBgQD9BhphjqlwjO+4VSjf\n" +
 "ZVyJjfzv81dZqaQM4d1aPmSdoW2ImW50SRftrEY6374ejT6wvmPLbM1hBUGMPqLY\n" +
 "1Q1ztIOjJZ8I/lLUb4SPYyET+rNoH+dk3X6tSvCnsQh02Tf2iWXnfSPBs/CUmw+S\n" +
 "JTn8HCfzX5IEsphRbaL/tDyeRQKBgQDWlOUtUlvDc95egyqINC7iwkIcympLUwjl\n" +
 "r+ouwv1OhfawUATCC/fMh2bxeXAbqsnYdkLknM19sJ00adyXfr99aXAcgPuPhxfl\n" +
 "QrZQlp94xgPj0PSuKSzy53M405xwwACXxyAiF2zZ7fYu5cQOTZcJ9+pjxlhlvLCS\n" +
 "5ViP8l3xiwKBgCwNLfaiMdnIj8vV98G9EflY4RqdkS2pHUio4jLd/gJ7omhP3i0c\n" +
 "tK/KeIezgJQtHycCXrmB/dfQZHMYTJ3XqfnlCa4T1LZqIN2fdMLKDW4/DZEpTM4A\n" +
 "yhc9ntxzM/e0AcxIa5d78Ae/XjlhLbrSBGBiPoperztmJKK4pdJC/PLJAoGAMG3E\n" +
 "V3k5+p/A9SW3SKw2VLkxAJxyZvOjk2sjeFBLqRg098/ncwAOehupESCuO6BNE8yF\n" +
 "BmGZQx/EKxslTQ+vGhfc1KILpHYB350x1nhYH4LZR+ZWAqSKX3v7Fj00FoJO3/Jx\n" +
 "/x+4UmsRX749hFjgmMBaJxCRzMxMMasPVNWHfEcCgYEA4ghYI3bOByGz5xA0hweo\n" +
 "/q/5G1r1k1WV0Jb3rykI8PJXSQgNwsqtvQtF8sp+VT8cpMRQVfI1U3AYyE96+vUK\n" +
 "uXO4GzYLMWvyaJKwHmGOftGDiquUF9PYYv4H8Y+5bTjWnAPaoqnPns0BZeZuuv4k\n" +
 "Mc+60ZCd9dvI4+uNZzfAPiY=\n" +
 "-----END PRIVATE KEY-----";

qz.security.setSignaturePromise(function(toSign) {
  return function(resolve, reject) {
	  try {
		  var pk = KEYUTIL.getKey(privateKey);
		  var sig = new KJUR.crypto.Signature({"alg": "SHA1withRSA"});
		  sig.init(pk); 
		  sig.updateString(toSign);
		  var hex = sig.sign();
		//   console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
		  resolve(stob64(hextorstr(hex)));
	  } catch (err) {
		  console.error(err);
		  reject(err);
	  }
  };
});