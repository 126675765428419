teleskop.controller('SalesMemberController', ['$scope', 'Sales', '$location', '$http',
    function ($scope, Sales, $location, $http) {

        $scope.filterProp = [];

        $scope.filterData = {
            export: false,
            term: '',
            page: 1,
            paginate: 25,
            date: {
                startDate: null,
                endDate: null,
            },
        }

        $scope.selectedItems = [];

        $scope.clearFilter = function (page) {
            $scope.filterData = {
                export: false,
                term: '',
                page: 1,
                paginate: 25,
                date: {
                    startDate: null,
                    endDate: null,
                },
            }
            $scope.filterProp = [];
            $scope.init();
        }

        $scope.$on('refresh_data', function () {
            $scope.init();
        }, true);


        $scope.init = function () {
            $scope.filterData.paginatePage = true;
            Sales.init($scope.filterData).success(function (data, status, headers, config) {
                $scope.data = data;
                if (data.users.data.length === 0) {
                    $scope.isEmpty = true;
                } else {
                    $scope.isEmpty = false;
                }
            });
        };

        $scope.tree = function () {
            Sales.init($scope.filterData).success(function (data, status, headers, config) {
                $scope.data = data;
                var chart_config = {
                    chart: {
                        container: "#treeview",
                        rootOrientation: 'NORTH', // NORTH || EAST || WEST || SOUTH
                        nodeAlign: "CENTER", // CENTER || TOP || BOTTOM
                        scrollbar: "fancy",
                        padding: 32,
                        siblingSeparation: 20,
                        subTeeSeparation: 30,
                        connectors: {
                            'style': {
                                'stroke': '#bbb',
                                'arrow-end': 'oval-wide-long',
                            },
                        },
                        node: {
                            HTMLclass: "big-commpany"
                        }
                    },
                    nodeStructure: $scope.data
                };
                setTimeout(() => {
                    new Treant(chart_config);
                }, 300);
            });
        };

        $scope.setDate = function (date) {
            $scope.filterData.date = date;
            $scope.init();
        }

        $scope.filter = function (type) {
            if (type == 'clear') {
                $scope.filterData = {
                    date: {
                        startDate: null,
                        endDate: null,
                    },
                }
            }
            $scope.init();
        }

        $scope.getPage = function (page) {
            $scope.filterData.page = page;
            $scope.init();
        }


        $scope.sort = function (field, sort) {
            $scope.filterData.field = field;
            $scope.filterData.sort = sort;
            $scope.init();
        }

        $scope.showCompletedOrders = function (invoice) {
            var data = {
                invoice_id: invoice.id,
                scheduled_time: {
                    startDate: moment(invoice.date).startOf('day'),
                    endDate: moment(invoice.date).endOf('day'),
                },
                workSteps: [$scope.data.completed.id.toString()]
            }
            var url = 'customer-orders?data=' + JSON.stringify(data);
            window.open(url, '_blank');
        }


    }
]);