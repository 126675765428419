teleskop.controller('FailAnalyticController', ['$scope', 'Analytic','Account', '$location','FileUploader','$http',
  function ($scope, Analytic, Account, $location, FileUploader, $http) {

		$scope.filterProp = [];
		$scope.filterData = {
			export: false,
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			selectedItem : [],
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: null, 
				endDate: null 
			}
		}

		$scope.$on('refresh_data', function() {
			$scope.init();
		}, true);

		$scope.clearFilter = function(page) {
			$scope.filterData = {
				export: false,
				customers: [],
				workers: [],
				workSteps: [],
				selectedItem : [],
				page: 1,
				created_at : { 
					startDate: null, 
					endDate: null 
				},
				scheduled_time : { 
					startDate: null, 
					endDate: null 
				}
			}
		}

		$scope.init = function (type) {
			Analytic.fail($scope.filterData).success(function(data, status, headers, config) {
				if(typeof type !== undefined && type == 'export') {
					$scope.filterData.export = false;
					$scope.filterData.exportReturn = false;
					window.location.href = data.url;
				}else {
					$scope.data = data;
					if(!$scope.filterData.customer_id)
					$scope.filterData.customer_id = $scope.data.customer_id;
				}
			});
		};

		$scope.getPage = function(page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function(page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.exportReturn = function(page) {
			$scope.filterData.exportReturn = true;
			$scope.init('export');
		}

		$scope.sort = function(field,sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if(type == 'clear') {
				$scope.filterData = {
          			export: false,
					customers: [],
					workers: [],
					workSteps: [],
					selectedItem : [],
					page: 1,
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
			}
			$scope.init();
		}
    
		$scope.addSelectedItem = function(item) {
			if($scope.filterData.selectedItem.indexOf(item) > -1) {
				$scope.filterData.selectedItem.splice($scope.filterData.selectedItem.indexOf(item), 1);
			}else {
				$scope.filterData.selectedItem.push(item);
			}
		}

		$scope.toggleSelection = function toggleSelection(event) {
	        if(event.target.checked) {
	         	$scope.filterData.selectedItem = [];
	         	for (var i = 0; i < $scope.data.orders.data.length ; i++) {
	         		$scope.filterData.selectedItem.push($scope.data.orders.data[i].id);
	         	}
	        }else{
				$scope.filterData.selectedItem = [];
			}
        };
  }
]);
