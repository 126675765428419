teleskop.controller('CustomerCardController', ['$scope', 'CustomerCard', '$location', 'FileUploader', '$localStorage',
  function ($scope, CustomerCard, $location, FileUploader, $localStorage) {
    $scope.loading = false;

    $scope.get = function () {
      $scope.loading = true;
      CustomerCard.get().success(function (data, status, headers, config) {
        $scope.loading = false;
        $scope.cards = data.cards.data;
        $scope.stcustomer = data.customer;
        $scope.card = {
          name: '',
          number: '',
          cnumber: '',
          expiry: '',
          cvc: '',
        }
      });
    };

    $scope.getMaskedCardNumber = function () {
      if ($scope.card && $scope.card.cnumber) {
        return $scope.card.cnumber.match(new RegExp('.{1,4}', 'g')).join(" ");
      }
    }

    $scope.add = function () {
      $scope.loading = true;
      $scope.loadingCard = true;
      CustomerCard.add($scope.card).success(function (data, status, headers, config) {
        $scope.cards.push(data);
        Messenger().post('Card added succesfull!');
        $('#newCardModal').modal('hide');
        $scope.loading = false;
        $scope.loadingCard = false;
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
        $scope.loading = false;
        $scope.loadingCard = false;
      });
    };

    $scope.delete = function (id) {
      if (confirm('Are you sure you want to delete this card ?')) {
        $scope.loading = true;
        CustomerCard.delete(id).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $scope.get();
        }).error(function (data) {
          $scope.loading = false;
          Messenger().post({
            message: data.message,
            type: "error"
          });
        })
      }

    };

    $scope.primary = function (id) {
      if (confirm('Are you sure you want to make primary this card ?')) {
        $scope.loading = true;
        CustomerCard.primary({
          card: id
        }).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $scope.get();
        }).error(function (data) {
          $scope.loading = false;
          Messenger().post({
            message: data.message,
            type: "error"
          });
        })
      }
    };

  }
]);