teleskop.controller('ChatController', ['$scope', 'Chat', '$location', '$routeParams', '$filter', 'ngAudio', '$rootScope', 'FileUploader',
  function ($scope, Chat, $location, $routeParams, $filter, ngAudio, $rootScope, FileUploader) {
    $scope.deleted = [];
    $scope.beepSound = ngAudio.load("sounds/beep.mp3");
    $scope.filterData = {
      searchTerm: '',
      term: null
    }
    $scope.clearFilter = function (page) {
      $scope.filterData = {
        term: null
      }
      $scope.all();
    }

    $rootScope.messageCount = 0;

    $scope.conversation = null
    $scope.newConversation = {}
    $scope.formData = {
      message: null
    };

    $scope.$on('refresh_data', function () {
      console.log('refreshing map...')
      $scope.all();
    }, true);

    $scope.$on('new_data', function () {
      $scope.conversation = {};
      $scope.newConversation = {}
      $scope.new();
      $('#newChat').modal('show');
    }, true);

    $scope.$on('new_message', function (event, data, nextParams, fromState) {
      $scope.beepSound.play();
      var coversation = $scope.data.conversations.find(function (item) {
        return item.id == data.message.conversation_id;
      });
      if (coversation) {
        if (coversation.new_message) {
          coversation.new_message += 1;
        } else {
          coversation.new_message = 1;
        }

        if ($scope.conversation && $scope.conversation.id == coversation.id) {
          $scope.conversation.messages.push(data.message)
          window.scrollTo(0, document.querySelector(".messages").scrollHeight);
          // $(".messages").animate({ scrollTop: $(document).height() }, 1000);
          $scope.conversation.last_message = data.message;
        } else {
          coversation.last_message = data.message;
        }

        setTimeout(() => {
          $scope.$apply();
        }, 100);
      } else {
        $scope.all();
      }
      // $scope.refreshOrders();
    });

    $scope.getPage = function (page) {
      $scope.filterData.page = page;
      $scope.all();
    }

    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
    console.log('interval_id: ', interval_id);
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
    liveInterval = setInterval(() => {
      $scope.all();
    }, 10000);
    localStorage.setItem('liveIntervalChat', liveInterval);

    $scope.all = function () {
      Chat.all($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;
        if ($scope.selectedConversation) {
          $scope.get($scope.selectedConversation.conversation.id, $scope.selectedConversation)
        }
        if ($scope.data.conversations && $scope.data.conversations.length > 0) {
          // $scope.get($scope.data.conversations[$scope.data.conversations.length - 1].id);
        }
        // $scope.data.conversations.forEach(element => {
        //   $rootScope.messageCount = $rootScope.messageCount + element.new_message;
        // });
      });
    }

    $scope.selectedStatus = 'online';
    $scope.getWorkerStatus = function (status, search) {
      var data = [];
      if (typeof $scope.data !== 'undefined' && $scope.data.workers) {

        if (search) {
          data = $scope.data.workers.filter(function (item) { return item.full_name.toUpperCase().includes(search.toUpperCase()) });
        } else {
          if (status == 'online') {
            data = $scope.data.workers.filter(function (item) { return item.status }); //&& item.orders.length > 0
          } else {
            data = $scope.data.workers.filter(function (item) { return !item.status });
          }
        }
      }
      // console.log(data)
      return data;
    }

    $scope.setStatus = function (status) {
      $scope.selectedStatus = status;
    }

    $scope.editChat = function (conversation) {
      $scope.conversation = conversation;
      $('#newChat').modal('show');
      $('#conversationDetail').modal('hide');
      Chat.new().success(function (data, status, headers, config) {
        $scope.stations = data.stations;
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
        jQuery('#start').datetimepicker({
          datepicker: false,
          allowTimes: $scope.allowTimes,
          format: 'H:i'
        });
        jQuery('#end').datetimepicker({
          datepicker: false,
          allowTimes: $scope.allowTimes,
          format: 'H:i'
        })
      });
    }

    $scope.selectedConversation = null;
    $scope.get = function (id, worker) {
      $scope.selectedConversation = worker;
      if (!id) {
        $scope.conversation = {
          worker: worker,
          messages: []
        };
        setTimeout(() => {
          if (document.getElementById("messageInput")) {
            document.getElementById("messageInput").focus();
          }
        }, 600);
      } else {
        Chat.get(id).success(function (data, status, headers, config) {
          $scope.conversation = data.conversation;
          setTimeout(() => {
            if (document.getElementById("messageInput")) {
              document.getElementById("messageInput").focus();
            }
          }, 600);

          // var coversation = $scope.data.conversations.find(function (item) {
          //   return item.id == id;
          // });

          // if (coversation) {
          //   $rootScope.messageCount = 0;
          //   coversation.new_message = 0;
          // }
        });
      }

    }

    $scope.new = function () {
      Chat.new().success(function (data, status, headers, config) {
        $scope.workers = data.workers;
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Chat.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.conversations.data.indexOf($filter('filter')($scope.data.conversations.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.conversations.data.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.sendMessage = function (chatMessage) {
      $scope.formData.conversation_id = $scope.conversation.id;
      $scope.formData.worker_id = $scope.conversation.worker.id;
      Chat.sendMessage($scope.formData).success(function (data, status, headers, config) {
        data.new = true;
        $scope.conversation.messages.push(data)
        $scope.formData = {
          message: null,
          file: null,
          conversation_id: $scope.conversation.id
        }
        // $(".messages").animate({ scrollTop: $('.messages').height() }, 1000);
        window.scrollTo(0, document.querySelector(".messages").scrollHeight);

        $scope.conversation.new_message = 0;

      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.save = function () {
      if ($scope.conversation.id) {
        $scope.update();
      } else {
        Chat.save($scope.newConversation).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post(data.message);
          $('#newChat').modal('hide');
          $scope.all();
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $rootScope.chatFile = new FileUploader({
      url: '/api/upload-chat-file',
      headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
      autoUpload: true
    });

    $rootScope.chatFile.filters.push({
      name: 'formatFilter',
      fn: function (item /*{File|FileLikeObject}*/, options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|pdf|docx|doc|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
      }
    });

    $rootScope.chatFile.onAfterAddingFile = function (fileItem) {
      $rootScope.proccessProfile = true;
      $scope.pending = true;
    };

    $rootScope.chatFile.onCompleteItem = function (item, response, status, headers) {
      $rootScope.proccessProfile = false;
      $scope.formData.file = response.file;
      if (response.status == 'success')
        $scope.pending = false;
    }
  }
]);