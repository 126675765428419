teleskop.controller('DashboardListController', ['$scope', 'Order', '$location','$filter',
	'ngAudio','$routeParams','$rootScope','$window','$http',
	function ($scope, Order, $location, $filter, ngAudio, $routeParams, $rootScope, $window, $http) {

		$scope.statuses = {work:1, worker:1};
		$scope.workListDay = moment().format('MM/DD/YYYY');
		$scope.filterPanel = false;

		$scope.filterData = {
			workers: [],
			workSteps: [],
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: moment(), 
				endDate: moment() 
			}
		}

		$scope.getWorkerOrders = function(item) {
			return item.worker_id === $scope.sWorker.id;
		}

		$scope.$on('teamChange', function (event,data,nextParams,fromState) {
			$scope.filterData.team = data !== null ? data.id : null;
			$scope.init();
		});

		$scope.init = function (type) {
			if(typeof type !== 'undefined' && type == 'clear') {
				$scope.filterData = {
					workers: [],
					workSteps: [],
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
			}
			Order.dashboard($scope.filterData).success(function(data, status, headers, config) {
				$scope.data = data;
			});
		}

		$scope.getCheckbox = function(value) {
			return JSON.parse(value);
		}

		$scope.$on('new_order', function (event,data,nextParams,fromState) {
			data.orders.forEach(function(element) {
				var date = moment(element.scheduled_time).format('MM/DD/YYYY');
				if(date == $scope.workListDay) {
					var order = find($scope.data.orders, element.id);
					if(typeof order !== 'undefined')
						angular.merge(order, element);
					else
						$scope.data.orders.unshift(element);
					
				}
			}, this);
		});

		$scope.$on('updateOrder', function (event,data,nextParams,fromState) {
			var order = find($scope.data.orders, data.id);
			angular.merge(order, data);
		});

		$scope.$on('deleteOrder', function (event,data,nextParams,fromState) {
			var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {id: data.id})[0]);
			if (index > -1) 
				$scope.data.orders.splice(index, 1);
		});

		$scope.disableEnter = function(e) {
			if(e.keyCode == 13) {
				return false;
			}
		}
		
		$scope.getWorkerStatus = function (status) {
			if(typeof $scope.data !== 'undefined')
				return $filter('filter')($scope.data.workers, {status: status}).length;
		}

		$scope.setStatus = function (status,type) {
			$scope.statuses[type] = status;
		}

		$scope.detailsWorker = function (worker) {
			$scope.sWorker = worker;
			$scope.filterWork($scope.data.orders, 'work', worker.id);
		}

		$scope.clickWork = function (work) {
			$scope.selectedOrderId = work.id;
		}

		$scope.closeWorker = function () {
			delete $scope.sWorker;
		}

		$scope.filterWorker = function (worker) {
			if($scope.statuses['worker'] == 0)
				return worker.status === 0;
			else if($scope.statuses['worker'] == 1)
				return worker.status === 1;
			else
				return true;
		}

		$scope.getWorkStatus = function (status) {
			if(typeof $scope.data !== 'undefined' && typeof $scope.data.orders !== 'undefined') {
				if(status == 'assign'){
					return $filter('filter')($scope.data.orders, function(value){
						return value.worker_id != 0 && (value.status.id != $scope.data.complete && value.status.id != $scope.data.fail);
					}).length;
				}
				else if(status == 'not-assign') {
					return $filter('filter')($scope.data.orders, function(value){
						return value.worker_id == 0;
					}).length;
				}
				else if(status == 'end') {
					return $filter('filter')($scope.data.orders, function(value) {
						return value.status.id == $scope.data.complete || value.status.id == $scope.data.fail;
					}).length;
				}
			}
		}

		$scope.filterWork = function (work) {
			if($scope.statuses['work'] == 1) {
				if(typeof $scope.sWorker != 'undefined' && typeof $scope.sWorker.id != 'undefined') {
					return work.worker_id == $scope.sWorker.id;
				}
				
				return work;
			}
			else if($scope.statuses['work'] == 2) {

				if(typeof $scope.sWorker !== 'undefined' && typeof $scope.sWorker.id !== 'undefined') {
					return work.worker_id == $scope.sWorker.id && (work.status.id == $scope.data.complete || work.status.id == $scope.data.fail);
				}

				if( work.status ) {
					return work.status.id == $scope.data.complete || work.status.id == $scope.data.fail;
				}else {
					return work;
				}
				
			}
			else if($scope.statuses['work'] == 3) {
				if(typeof $scope.sWorker !== 'undefined' && typeof $scope.sWorker.id !== 'undefined') {
					return work.worker_id == $scope.sWorker.id && (work.status.id != $scope.data.complete && work.status.id != $scope.data.fail);
				}
				
				if( work.status ) {
					return work.status.id != $scope.data.complete && work.status.id != $scope.data.fail;
				}else {
					return work;
				}
			}
		}

		$scope.clearSWorker = function() {
			$scope.sWorker = [];
		}

		$scope.refreshOrders = function () {
			Order.refreshOrders().success(function(data, status, headers, config) {
				$scope.data.orders = data.orders;
			});
		};

		$scope.$on('worker_answer', function (event,data,nextParams,fromState) {
			var order = find($scope.data.orders,data.order.id);
			angular.merge(order, data.order);
			Messenger().post(data.order.id + ' Nolu işin durumu "'+ order.status.name +'" olarak değiştirildi.', 'İş Durumu!');
		});

		$scope.$on('delete_order', function (event,data,nextParams,fromState) {
			$scope.refreshOrders();
		});

		$scope.$on('update_order', function (event,data,nextParams,fromState) {
			$scope.refreshOrders();
		});

		$scope.$on('worker_status', function (event,data,nextParams,fromState) {
			if($scope.selectedTeamName.id != null && $scope.selectedTeamName.id != data.worker.worker.team_id) return false;
			
			if(data.status == 1) {
				var message = data.worker.full_name + ' İş Alımını Açtı!';
				Messenger().post(message);
			} else if(data.status == 0) {
				var message = data.worker.full_name + ' İş Alımını Kapattı!';
				Messenger().post({
					message: message,
					type: 'error',
					showCloseButton: true
				});
			} else if(data.status == 3) {
				if(data.gps) {
					var message = data.worker.full_name + ' GPS\'ni Açtı!';
					Messenger().post(message);
				} else {
					var message = data.worker.full_name + ' GPS\'ni Kapattı!';
					Messenger().post({
						message: message,
						type: 'error',
						showCloseButton: true
					});
				}
			} else if(data.status == 'note') {
				var order = find($scope.data.orders, data.log.order_id);
				if(typeof order !== 'undefined') {
					if(typeof order.notes === 'undefined') order.notes = [];
					order.notes.push(data.log);
				}
				Messenger().post({
					message: data.worker.full_name +',  #'+ data.log.order_id +' nolu işe "'+ data.log.reason +'" notunu ekledi!',
					type: 'success',
					showCloseButton: true
				});
			}
			if(data.status != 'note') {
				var worker = find($scope.data.workers, data.worker.worker.id);
				worker.status = data.status;
				worker.location_info = data.worker.worker.location_info;	
			}		
		});

		function find(data,find) {
			var index = data.indexOf($filter('filter')(data, {id: find})[0]);
			if (index > -1)
				return data[index];
		}

		$scope.updateOrder = function (e) {
			Order.updateOrder($scope.editOrderData).success(function(data, status, headers, config) {
				var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {id: $scope.editOrderData.id})[0]);
				if (index > -1) {
					$scope.data.orders[index] = data;
					$('#orderEdit').modal('hide');
				}
			}).error(function() {
				alert('Şuan bir hata oluştu lütfen daha sonra yeniden deneyiniz.');
			});
		};

		$scope.filterWorkerFromTeam = function(item) {
			if($scope.newOrderData.team_id !== null && typeof $scope.newOrderData.team_id !== 'undefined') {
				return item.team_id === $scope.newOrderData.team_id;
			}else{
				return item;
			}
		}

		$scope.calculateDiff = function (start,end) {
			return moment(end).diff(moment(start), 'minute');
		}

		$scope.getBatteryInfo = function(worker) {
			if(worker.location_info.length != 0) {
				var extra = angular.fromJson(worker.location_info);
				if(typeof extra.battery !== 'undefined')
					return parseFloat(extra.battery * 100).toFixed(0);
			}else {
				return 'N/A';
			}
		}

		$scope.getNumber = function(num) {
			return new Array(num);
		}

		$scope.filterPanelStatus = function() {
			$scope.filterPanel = !$scope.filterPanel;
		}

	}
]);