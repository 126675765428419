teleskop.factory('CustomerOrder', ['$http', function ($http) {
    return {
        customerOrder: function (id) {
            return $http.get('api/customer-order/' + id);
        },
        customerOrders: function (data) {
            return $http.post('api/customer-orders', data, { ignoreLoadingBar: true });
        },
        newCustomerOrder: function (data) {
            return $http.post('api/new-customer-order', data);
        },
        dashboard: function (data) {
            return $http.post('api/customer-dashboard', data);
        },
        donwloadSampleExcel: function () {
            return $http.get('api/download-sample-customer-order-excel');
        },
        updateCustomerOrder: function (data) {
            return $http.post('api/update-customer-order', data);
        },
        newOrderData: function (data) {
            return $http.post('api/initiate-customer-order', data);
        },
        getWorker: function (data) {
            return $http.post('api/get-worker', data);
        },
        checkOrder: function (data) {
            return $http.post('api/check-order', data);
        },
        removeNotDelivery: function (data) {
            return $http.post('api/remove-not-delivery');
        },
        getReaminingTime: function (id) {
            return $http.get('api/get-remaining-time-customer/' + id);
        },
        printZpl: function (data) {
            return $http.get('api/order-to-zpl/' + data);
        },
        updatePrintStatus: function (data) {
            return $http.post('api/update-order-print-status', { ids: data });
        },
        closeCancelledOrders: function (data) {
            return $http.post('api/close-cancelled-orders');
        },
    };
}]);