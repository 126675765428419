teleskop.controller('OrderListController', ['$scope', 'Order', '$location','$filter','$routeParams','$rootScope',
	function ($scope, Order, $location, $filter, $routeParams, $rootScope) {

		$scope.filterProp = [];
		$scope.selectedItem = [];
		
		$scope.calendarView = 'month';
		
		$scope.addSelectedItem = function(item) {
			
			if($scope.selectedItem.indexOf(item) > -1) {
				$scope.selectedItem.splice($scope.selectedItem.indexOf(item), 1);
			}else {
				$scope.selectedItem.push(item);
			}
		}
		
		$scope.selectAll = function (select) {
			if (select) {
			  $scope.selectedItem = [];
			  for (let i = 0; i < $scope.data.orders.data.length; i++) {
				$scope.selectedItem.push($scope.data.orders.data[i].id);
			  }
			} else {
			  $scope.selectedItem = [];
			}
		};
	  
 
		$scope.filterData = {
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: null, 
				endDate: null 
			}
		}

		$scope.addFilterProps = function (item) {
			if($scope.filterProp.indexOf(item) === -1)
				$scope.filterProp.push(item);
			else {
				var index = $scope.filterProp.indexOf(item);
				$scope.filterProp.splice(index, 1);
				if(item != 'term') {
					$scope.filterData[item] = [];
				}else {
					$scope.filterData[item] = "";
				}
				if(item == 'date') {
					delete $scope.filterData.startDate;
					delete $scope.filterData.endDate;
				}
				if($scope.filterProp.length == 0) {
					$scope.filter('clear');
				}
			}
		}

		$scope.getPage = function(page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.sort = function(field,sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if(type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
				$scope.filterProp = [];
			}else {
				$scope.filterData.filterProp = $scope.filterProp;
				if(typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
					$scope.filterData.filterProp.created_at = 'created_at';
				}
				if(typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
					$scope.filterData.filterProp.scheduled_time = 'scheduled_time';
				}
			}
			$scope.orders();
		}

		$scope.$on('refresh_data', function() {
			console.log('refreshing list...')
			$scope.orders();
		}, true);

		$scope.searchOrder = function(term) {
			$scope.filterData.term = term;
			$scope.orders(); 
		}

		$scope.clearFilter = function(page) {
			$scope.filterData = {
				term: '',
				workers: [],
				workSteps: [],
				page: 1,
				created_at : { 
					startDate: null, 
					endDate: null 
				},
				scheduled_time : { 
					startDate: null, 
					endDate: null
				}
			}
			$scope.filterProp = [];
			$scope.orders();
		}

		$scope.orders = function () {
			if ($routeParams.invoice_id) {
                $scope.filterData.invoice_id = $routeParams.invoice_id;
			}
            if ($routeParams.route_optimization_id) {
                $scope.filterData.route_optimization_id = $routeParams.route_optimization_id;
			}
			
			if ($routeParams.order_id) {
                $scope.getOrderDetails($routeParams.order_id);
			}
			Order.orders($scope.filterData).success(function(data, status, headers, config) {
				$scope.data = data;
				$scope.data.steps.unshift({
					id : 0,
					name : 'Created'
				});
				angular.merge($scope.filterData, $routeParams);
				if(typeof $scope.filterData.filterProp !== 'undefined') {
					$scope.filterProp = [];
					if(angular.isArray($scope.filterData.filterProp)) {
						for (var i = $scope.filterData.filterProp.length - 1; i >= 0; i--) {
							$scope.filterProp.push($scope.filterData.filterProp[i])
						}
					}else {
						$scope.filterProp.push($scope.filterData.filterProp);
					}
				}
			});
		};

		$scope.downloadZip = function() {
			
			Order.generateTotalOrdersReportInZip({orders:$scope.selectedItem}).success(function(data, status, headers, config) {
				window.location.href = data['url'];
				setTimeout(function(){
					Order.removeDownloadFile({path: data['path']}).success(function(response, status, headers, config) {	
						$scope.selectedItem = [];
					});
				},2000)
			});	
			
		};

		$scope.downloadExcel = function() {
			data = $scope.filterData;
			data.orders = $scope.selectedItem;
			Order.generateExcelReport(data).success(function(data, status, headers, config) {
				window.location.href = data['url'];
				setTimeout(function(){
					Order.removeDownloadFile({path: data['path']}).success(function(response, status, headers, config) {	
						$scope.selectedItem = [];
					});
				},2000)
			});
		};

		$scope.toggleSelection = function toggleSelection(event) {
          
	        if(event.target.checked) {

	         	$scope.selectedItem = [];
	         	
	         	for (var i = 0; i < $scope.data.orders.data.length ; i++) {
	         		
	         		$scope.selectedItem.push($scope.data.orders.data[i].id);
	         	}
	        }else{

				$scope.selectedItem = [];
				
			}
        };

        $scope.deleteOrders = function(send_cancel_sms) {
        	Order.deleteOrders({orders: $scope.selectedItem, send_cancel_sms : send_cancel_sms}).success(function(response, status, headers, config) {	
				$scope.orders();
				$scope.selectedItem = [];
				$('#deleteOrdersModal').modal('toggle');
			});
		}
        $scope.sendOffer = function(send_cancel_sms) {
        	Order.sendOffer({orders: $scope.selectedItem}).success(function(response, status, headers, config) {	
				$scope.orders();
				$scope.selectedItem = [];
				Messenger().post('Selected orders changed as offer!');
			});
		}
		$scope.toggleSelectionCustomer = function(event) {
			if(event.target.checked) {
				$scope.selectedItem = [];
				for (var i = 0; i < $scope.data.orders.data.length ; i++) {
					// if($scope.data.orders.data[i].status) {
					// 	if($scope.data.orders.data[i].status.id != $scope.data.success.id && $scope.data.orders.data[i].status.id != $scope.data.fail.id) {
					// 		$scope.selectedItem.push($scope.data.orders.data[i].id);
					// 	}
					// }else {
						$scope.selectedItem.push($scope.data.orders.data[i].id);
					// }
					
				}
			}else {
				$scope.selectedItem = [];
			}
		};
	}
]);