teleskop.
factory('User', ['$http', function($http) {
    return {
      login: function(data) {
        return $http.post('login',data);
      },
      register: function(data) {
        return $http.post('register',data);
      },
      registerMerchant: function(data) {
        return $http.post('register-merchant',data);
      },
      registerSales: function(data) {
        return $http.post('register-sales',data);
      },
      forgot: function(data) {
        return $http.post('forgot',data);
      },
      password: function(data) {
        return $http.post('password',data);
      },
      getUser: function() {
        return $http.get('api/user');
      },
      firstLook: function() {
        return $http.post('api/first-look');
      },
      updates: function() {
        return $http.post('api/updates');
      },
      w9Form: function(data) {
        return $http.post('w9-form', data);
      },
      getTempWorker: function(data) {
        return $http.get('temp-worker', { params: data });
      },
      setTempWorker: function(data) {
        return $http.post('temp-worker', data);
      },
      
    };
}]);